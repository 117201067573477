import { App } from 'vue';

interface EnvConfig {
  apiUrl?: string;

}

declare global {
  interface Window {
    ENV_CONFIG: EnvConfig;
  }
}

function install (Vue: App): void {
  const ENV_CONFIG = {
    apiUrl: process.env.VUE_APP_API_URL,
    ...window.ENV_CONFIG
  };
  Vue.config.globalProperties.$env = ENV_CONFIG;
}

function createEnv (): {install: (T: App) => void} {
  return {
    install: (app: App) => install(app)
  };
}

export const env = createEnv();

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $env: EnvConfig;
  }
}
