import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")
  const _component_DmCheckbox = _resolveComponent("DmCheckbox")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmModalBody = _resolveComponent("DmModalBody")
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")
  const _component_DmModal = _resolveComponent("DmModal")

  return (_openBlock(), _createBlock(_component_DmModal, {
    modelValue: _ctx.isModalShown,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalShown = $event)),
    class: _ctx.$bem({}),
    position: "fullscreen",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmModalBody, {
        class: _ctx.$bem({ e: 'content' })
      }, {
        default: _withCtx(() => [
          _createVNode("h2", {
            class: _ctx.$bem({ e: 'title' })
          }, _toDisplayString(_ctx.$t('app.regulations.title')), 3),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'checkboxes' })
          }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.checkboxes, (checkbox) => {
              return (_openBlock(), _createBlock(_component_DmCheckbox, {
                id: checkbox.id,
                key: checkbox.id,
                modelValue: checkbox.accepted,
                "onUpdate:modelValue": ($event: any) => (checkbox.accepted = $event),
                rounded: "",
                light: "",
                error: _ctx.getError(checkbox),
                class: _ctx.$bem({ e: 'checkbox' }),
                onChange: ($event: any) => (checkbox.validated = true)
              }, {
                default: _withCtx(() => [
                  _createVNode("span", {
                    class: _ctx.$bem({ e: 'checkbox-asterisk' })
                  }, _toDisplayString(checkbox.required ? '*' : ''), 3),
                  _createVNode(_component_Markdown, {
                    class: _ctx.$bem({ e: 'checkbox-label' }),
                    source: checkbox.name
                  }, null, 8, ["class", "source"])
                ]),
                _: 2
              }, 1032, ["id", "modelValue", "onUpdate:modelValue", "error", "class", "onChange"]))
            }), 128))
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'buttons' })
          }, [
            _createVNode(_component_DmButton, {
              hoverable: "",
              bordered: "",
              rounded: "",
              class: _ctx.$bem({ e: 'button' }),
              onClick: _ctx.logout
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('app.regulations.logout')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"]),
            _createVNode(_component_DmButton, {
              dark: "",
              rounded: "",
              color: "primary",
              class: _ctx.$bem({ e: 'button' }),
              onClick: _ctx.submit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('app.regulations.save')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"])
          ], 2)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["modelValue", "class"]))
}