import { App } from 'vue';
import mitt, { Emitter } from 'mitt';

function install (Vue: App): void {
  const emitter = mitt();
  Vue.config.globalProperties.$emitter = emitter;
}

function createEmitter (): {install: (T: App) => void} {
  return {
    install: (app: App) => install(app)
  };
}

export const emitter = createEmitter();

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $emitter: Emitter;
  }
}
