import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmModalHeader = _resolveComponent("DmModalHeader")

  return (_openBlock(), _createBlock(_component_DmModalHeader, {
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({e: 'header'})
      }, [
        (_ctx.withGoBackButton)
          ? (_openBlock(), _createBlock(_component_DmIconButton, {
              key: 0,
              color: "primary",
              plain: "",
              rounded: "",
              bordered: "",
              class: "is-hoverable",
              icon: "chevron-left",
              "data-test": "modal-go-back-button",
              onClick: _ctx.goBack
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: _ctx.$bem({e: 'title'})
        }, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _createVNode("h3", null, _toDisplayString(_ctx.title), 1)
          ])
        ], 2),
        (_ctx.withCloseButton)
          ? (_openBlock(), _createBlock(_component_DmIconButton, {
              key: 1,
              color: "primary",
              plain: "",
              rounded: "",
              bordered: "",
              class: "is-hoverable",
              icon: "close",
              "data-test": "modal-close-button",
              onClick: _ctx.close
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}