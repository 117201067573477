export const jsonToURLSearchParams = (obj: {[key in string]: string | null | number | boolean }): URLSearchParams => {
  const data = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    const parsedValue =
      typeof value === 'string' ? value
        : typeof value === 'number' ? value.toString()
          : typeof value === 'boolean' ? value ? 'true' : 'false'
            : '';
    data.append(key, parsedValue);
  }
  return data;
}
;
