import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmSpinner = _resolveComponent("DmSpinner")
  const _component_DmModal = _resolveComponent("DmModal")

  return (_openBlock(), _createBlock(_component_DmModal, {
    "model-value": _ctx.modelValue,
    position: _ctx.position,
    persistent: _ctx.persistent,
    elevated: "",
    size: _ctx.size,
    width: _ctx.width || 360,
    "data-test": "modal",
    transition: "slide-right",
    class: _ctx.$bem({}),
    "close-on-esc": "",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: _ctx.$bem({ e: 'loader-blend' })
          }, [
            _createVNode(_component_DmSpinner)
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["model-value", "position", "persistent", "size", "width", "class", "onClose"]))
}