
import { defineComponent, PropType } from 'vue';
import {
  DmModalHeader,
  DmIconButton
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppModalHeader',
  components: {
    DmModalHeader,
    DmIconButton
  },
  props: {
    withCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    withGoBackButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {
      type: String as PropType<string | null>,
      default: null
    }
  },
  emits: ['close', 'go-back'],
  methods: {
    close (): void {
      this.$emit('close');
    },
    goBack (): void {
      this.$emit('go-back');
    }
  }
});
