
import { defineComponent, PropType } from 'vue';
import { DmButton } from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppModalButton',
  components: {
    DmButton
  },
  props: {
    color: {
      type: String as PropType<string | null>,
      default: null
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    plain: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: {
    click: null
  },
  methods: {
    click (): void {
      this.$emit('click');
    }
  }
});
