import { WorkspaceWorkingHours, WorkspaceSlot } from '@/models/workspaces';
import dayjs from 'dayjs';

function stringTimeFormatToUnix (stringTime: string): number {
  // stringTime 'hh:mm:ss' format
  const splittedStartTime: string[] = stringTime.split(':');
  const newDate = new Date();
  newDate.setHours(parseInt(splittedStartTime[0]));
  newDate.setMinutes(parseInt(splittedStartTime[1]));
  newDate.setSeconds(parseInt(splittedStartTime[2]));
  return newDate.getTime();
}

export function createWorkingHoursSlots (
  startTime: string,
  endTime: string,
  slot: WorkspaceSlot
): WorkspaceWorkingHours[] {
  const startDateUnix: number = stringTimeFormatToUnix(startTime);
  const endDateUnix: number = stringTimeFormatToUnix(endTime);

  let currentDateUnix = 0;
  const slotsArray = [startDateUnix];

  while (endDateUnix > currentDateUnix) {
    const currentDate = new Date(slotsArray[slotsArray.length - 1]);
    currentDate.setMinutes(currentDate.getMinutes() + slot);
    currentDateUnix = currentDate.getTime();
    slotsArray.push(currentDateUnix);
  };

  return slotsArray.map((el) => new Date(el)).map(el => {
    const hour = el.getHours().toString().length === 1 ? `0${el.getHours().toString()}` : el.getHours();
    const minutes = el.getMinutes().toString().length === 1 ? `0${el.getMinutes().toString()}` : el.getMinutes();
    const seconds = el.getSeconds().toString().length === 1 ? `0${el.getSeconds().toString()}` : el.getSeconds();
    return {
      key: `${hour}:${minutes}:${seconds}`,
      text: `${hour}:${minutes}`
    };
  });
}

export function endTimeIsNotLessThanStartTime (startTime: string, endTime: string): boolean {
  const startDateUnix: number = stringTimeFormatToUnix(startTime);
  const endDateUnix: number = stringTimeFormatToUnix(endTime);

  return endDateUnix >= startDateUnix;
}

export function getYearAndMonth (date: string | null): string {
  if (!date) return '';
  return dayjs(date).format('YYYY.MM');
}

export function getManuDateRange (from: string, to: string) {
  return `${getYearAndMonth(from)} - ${getYearAndMonth(to)}`;
}

export function splitTextByDot (text: string) {
  return text.split('.');
}
