import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmNotificator = _resolveComponent("DmNotificator")

  return (_openBlock(), _createBlock(_component_DmNotificator, {
    items: _ctx.notifications,
    rounded: "",
    class: _ctx.$bem({}),
    onRemoveNotification: _ctx.removeNotification
  }, null, 8, ["items", "class", "onRemoveNotification"]))
}