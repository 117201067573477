import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmPhoneInput = _resolveComponent("DmPhoneInput")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_DmSwitch = _resolveComponent("DmSwitch")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_DmForm = _resolveComponent("DmForm")

  return (_openBlock(), _createBlock(_component_DmForm, {
    "initial-values": { ..._ctx.initialValues },
    "validation-schema": _ctx.validationSchema,
    class: _ctx.$bem({}),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(({ errors, setFieldValue, values, submitCount }) => [
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode(_component_DmField, { name: "phone" }, {
            default: _withCtx(({ validate }) => [
              _createVNode(_component_DmPhoneInput, {
                modelValue: values.phone,
                "onUpdate:modelValue": ($event: any) => (values.phone = $event),
                valid: _ctx.isPhoneValid,
                "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPhoneValid = $event)),
                light: "",
                rounded: "",
                required: "",
                lazy: submitCount === 0,
                hint: _ctx.$t('clients.hints.phone'),
                "default-country-code": "PL",
                "error-text": _ctx.$t('clients.rules.phone'),
                "data-test": "field-phone",
                label: `${_ctx.$t('clients.fields.phone')} *`,
                onValidate: validate,
                onBlur: ($event: any) => {
            _ctx.asyncFieldCheck({ name: 'phone', value: values.phone }, setFieldValue);
            validate();
            _ctx.isPhoneTouched = true;
          }
              }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "lazy", "hint", "error-text", "label", "onValidate", "onBlur"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_DmField, { name: "name" }, {
            default: _withCtx(({ field }) => [
              _createVNode(_component_DmInput, _mergeProps({
                modelValue: field.value,
                "onUpdate:modelValue": ($event: any) => (field.value = $event)
              }, field, {
                light: "",
                rounded: "",
                required: "",
                maxlength: 128,
                error: errors.name,
                type: "text",
                "data-test": "field-name",
                label: `${_ctx.$t('clients.fields.name')} *`
              }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_DmField, { name: "email" }, {
            default: _withCtx(({ field }) => [
              _createVNode(_component_DmInput, _mergeProps({
                modelValue: field.value,
                "onUpdate:modelValue": ($event: any) => (field.value = $event)
              }, field, {
                light: "",
                rounded: "",
                maxlength: 128,
                type: "email",
                error: errors.email,
                "data-test": "field-email",
                label: _ctx.$t('clients.fields.email'),
                onBlur: ($event: any) => (_ctx.asyncFieldCheck(field, setFieldValue))
              }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label", "onBlur"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_DmField, { name: "marketingConsent" }, {
            default: _withCtx(({ validate }) => [
              _createVNode(_component_DmSwitch, {
                id: "checkboxMarketingConsent",
                modelValue: values.marketingConsent,
                "onUpdate:modelValue": ($event: any) => (values.marketingConsent = $event),
                light: "",
                rounded: "",
                elevated: "",
                error: errors.marketingConsent,
                "data-test": "field-marketing-consent",
                label: _ctx.$t('clients.fields.marketingConsent', { garage: _ctx.garageName }),
                onChange: validate
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "label", "onChange"])
            ]),
            _: 2
          }, 1024),
          _renderSlot(_ctx.$slots, "bottom", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModal, {
        modelValue: _ctx.isAsyncCheckModalShown,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isAsyncCheckModalShown = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppModalHeader, {
            title: _ctx.$t('common.attention')
          }, null, 8, ["title"]),
          _createVNode(_component_AppModalBody, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('clients.alreadyExists')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppModalFooter, null, {
            buttons: _withCtx(() => [
              _createVNode(_component_AppModalButton, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAsyncCheckModalShown = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema", "class", "onSubmit"]))
}