import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "has-margin-bottom-lg"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_DmCheckbox = _resolveComponent("DmCheckbox")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({}),
    "data-test": "event-cancellation-modal"
  }, [
    _createVNode(_component_AppModalBody, null, {
      default: _withCtx(() => [
        _createVNode(_component_DmAsyncContent, {
          loading: _ctx.reasonsLoading,
          error: _ctx.reasonsError,
          reloadable: "",
          onReload: _ctx.reloadReasons
        }, {
          default: _withCtx(() => [
            (_ctx.isExternal)
              ? (_openBlock(), _createBlock("h4", _hoisted_1, " Pamiętaj, że każda odwołana wizyta wpływa na to jak wysoko jesteś promowany na DobryMechanik.pl "))
              : _createCommentVNode("", true),
            _createVNode("div", {
              class: _ctx.$bem({ e: 'reasons' })
            }, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filteredReasons, (reason) => {
                return (_openBlock(), _createBlock(_component_DmButton, {
                  key: reason.id,
                  dark: _ctx.reasonId === reason.id,
                  color: _ctx.reasonId === reason.id ? 'primary' : null,
                  bordered: "",
                  rounded: "",
                  hoverable: "",
                  block: "",
                  class: _ctx.$bem({ e: 'reason' }),
                  "data-test": "event-cancellation-reason-button",
                  onClick: ($event: any) => (_ctx.setReason(reason.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(reason.name), 1)
                  ]),
                  _: 2
                }, 1032, ["dark", "color", "class", "onClick"]))
              }), 128))
            ], 2)
          ]),
          _: 1
        }, 8, ["loading", "error", "onReload"]),
        (_ctx.isExternal)
          ? (_openBlock(), _createBlock("p", _hoisted_2, " Kierowca otrzyma SMSem informację o odwołaniu wizyty "))
          : (_openBlock(), _createBlock(_component_DmCheckbox, {
              key: 1,
              id: "cancel-repair-send-message",
              modelValue: _ctx.sendConfirmation,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendConfirmation = $event)),
              rounded: "",
              class: _ctx.$bem({ e: 'send-message' }),
              "data-test": "sms-confirmation"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('repair.cancellation.sendSms')), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "class"]))
      ]),
      _: 1
    }),
    _createVNode(_component_AppModalFooter, null, {
      buttons: _withCtx(() => [
        _createVNode(_component_AppModalButton, { onClick: _ctx.close }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_AppModalButton, {
          color: "primary",
          dark: "",
          rounded: "",
          disabled: _ctx.reasonId === null,
          class: _ctx.$bem({ e: 'cancel' }),
          "data-test": "event-cancellation-submit-button",
          onClick: _ctx.cancelEvent
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('repair.cancellation.cancel')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "class", "onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"])
  ], 2))
}