// garage
export const URI_GARAGE_BASIC = 'garage/basic';
export const URI_GARAGE = 'garage';
export const URI_GARAGE_LOGO = 'garage/logo';
export const URI_GARAGE_CONSENTS = 'garage/consents';

// workspaces
export const URI_WORKSPACES = 'workspaces';
export const URI_WORKSPACE = (id: string) => `workspaces/${id}`;
export const URI_REORDER_EVENTS = (id: string) => `workspaces/${id}/repairs/reorder`;

// slot-finder
export const URI_AVAILABLE_SLOTS = (garageId: string, workspaceId: string) => `slot-finder/garages/${garageId}/workspaces/${workspaceId}`;
export const URI_SLOT_FINDER_REPAIR_REASONS = 'slot-finder/repair-services';
export const URI_SLOT_FINDER_REPAIR_AUTOBOOKABLE_CATEGORIES = 'slot-finder/repair-categories';
export const URI_SLOT_FINDER_REPAIR_REASONS_GET = (garageId: string) => `garages/${garageId}/external-config/autobookable`;
export const URI_SLOT_FINDER_REPAIR_REASONS_EDIT = (garageId: string) => `garages/${garageId}/external-config/autobookable`;
export const URI_CHECK_EVENTS_QUANTITY = (garageId: string, workspaceId: string) => `/slot-finder/garages/${garageId}/workspaces/${workspaceId}/slot-spare-capacity`;

// pickup
export const URI_READY_FOR_PICKUP = 'ready_for_pickup/data';
export const URI_READY_FOR_PICKUP_COUNTER = 'ready_for_pickup/counter';
export const URI_PICKUP = 'ready-for-pickup/pickup';

// cacellation
export const URI_CANCELLATION_REASONS = 'cancellation_reasons/';

// calendar
export const URI_CALENDAR_RESOURCES = 'calendar/resources';
export const URI_CALENDAR_EVENTS = 'calendar/events';

// auth
export const URI_LOGIN = 'login_check';

// waiting room
export const URI_WAITING_ROOM = 'waiting_room';
export const URI_WAITING_ROOM_REORDER = 'waiting_room/reorder';

// repairs
export const URI_REPAIR = (id: string) => `repairs/${id}`;
export const URI_REPAIR_CHANGE_STATUS = 'repairs/change_status';
export const URI_REPAIR_PDF = (id: string) => `repairs/${id}.pdf`;
export const URI_REPAIR_CANCEL = 'repairs/cancel';
export const URI_REPAIR_CREATE = 'repairs/create';
export const URI_REPAIR_EDIT = (id: string) => `repairs/${id}/edit`;
export const URI_REPAIR_CREATE_RETURNING = 'repairs/create_returning';
export const URI_REPAIR_CHANGE_STATUS_NEW = (id: string) => `repairs/${id}/status`;

// client
export const URI_CLIENTS = 'clients';
export const URI_CLIENT = (id: string) => `clients/${id}`;
export const URI_CLIENT_BY_PHONE = (phone: string) => `client-by-phone-exists/${phone}`;
export const URI_CLIENT_BY_EMAIL = (email: string) => `client-by-email-exists/${email}`;

// car
export const URI_CARS = 'cars';
export const URI_CAR = (id: string) => `cars/${id}`;
export const URI_CAR_BY_LICENSE_PLATE_NUMBER = (licensePlateNumber: string) => `car-by-license-plate-number-exists/${licensePlateNumber}`;
export const URI_CAR_BY_LICENSE_PLATE_NUMBER_AND_ID = (licensePlateNumber: string, id: string) => `car-by-license-plate-number-exists/${licensePlateNumber}/${id}`;
export const URI_CAR_BROWSER = '/brands-and-models';

// search
export const URI_SEARCH = (query: string) => `search/${query}`;

// repair reasons
export const URI_REPAIR_REASONS = '/repair-reasons';

// statistics
export const URI_STATISTICS = (periodType: string, analyzedPeriod: string, comparativePeriod: string) => `statistics/${periodType}/${analyzedPeriod}/${comparativePeriod}`;
export const URI_STATISTICS_PER_WORKSPACE = (periodType: string, analyzedPeriod: string, comparativePeriod: string, workspaceId: string) => `statistics/${periodType}/${analyzedPeriod}/${comparativePeriod}/workspace/${workspaceId}`;

// cost-estimation
export const URI_COST_ESTIMATION_DOCUMENTS = 'quotes';
export const URI_COST_ESTIMATION_DOCUMENTS_ID = (id: string) => `cost-estimation/documents/${id}`;
export const URI_COST_ESTIMATION_DOCUMENTS_ID_JSON = (id: string) => `quote/${id}`;
export const URI_COST_ESTIMATION_PDF = (garageId: string, quoteId: string) => `cost-estimation/${garageId}/${quoteId}.pdf`;
export const URI_COST_CHANGE_STATUS = (id: string) => `quote/${id}/status`;
export const URI_COST_ESTIMATION_SEND = (id: string) => `${id}/approval-request`;

// repair-block-placeholder
export const URI_REPAIR_BLOCK_PLACEHOLDER_CREATE = 'repair-block-placeholder/create';
export const URI_REPAIR_BLOCK_PLACEHOLDER_UPDATE = (placeholderId: string) => `repair-block-placeholder/${placeholderId}`;

// reset-password
export const URI_RESET_PASSWORD = 'garage/reset-password/request';
export const URI_SET_PASSWORD = 'garage/reset-password/change';

// comission
export const URI_COMISSION = (year: string, month: string) => `commissions/${year}/${month}`;
export const URI_COMISSION_SUMMARY = (year: string, month: string) => `commissions/${year}/${month}/summary`;
export const URI_COMISSION_OVERDUE_INVOICES = 'commissions/overdue-invoices';
export const URI_COMISSION_ISSUED_INVOICES = 'commissions/issued-invoices';
