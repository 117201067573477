
import { defineComponent } from 'vue';
import {
  DmToolbarMenu,
  DmToolbarMenuDropdown,
  DmIcon,
  DmTile,
  DmButton,
  DmIconButton
} from '@dobrymechanik/vue-ui';
import { RouteName } from '@/router/models';
import { locales } from '@/locales';

export default defineComponent({
  name: 'LayoutAppHeaderControls',
  components: {
    DmToolbarMenu,
    DmToolbarMenuDropdown,
    DmIcon,
    DmTile,
    DmButton,
    DmIconButton
  },
  emits: [
    'go-to-stats-page',
    'go-to-settings-page',
    'go-to-workspaces-page',
    'set-locale',
    'close',
    'logout',
    'go-to-bok-page'
  ],
  data () {
    return {
      isUserDropdownShown: false,
      statsPage: {
        name: RouteName.STATS
      },
      settingsPage: {
        name: RouteName.SETTINGS
      },
      languages: locales
    };
  },
  computed: {
    appVersion () {
      return process.env.PACKAGE_VERSION;
    }
  },
  methods: {
    setLocale (locale: string): void {
      this.$emit('set-locale', locale);
    },
    goToStatsPage (): void {
      this.$emit('go-to-stats-page');
    },
    goToSettingsPage (): void {
      this.$emit('go-to-settings-page');
    },
    goToWorkspacesPage (): void {
      this.$emit('go-to-workspaces-page');
    },
    goToBokPage (): void {
      this.$emit('go-to-bok-page');
    },
    logout (): void {
      this.$emit('logout');
    }
  }
});
