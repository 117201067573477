import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-event-form__row" }
const _hoisted_2 = { class: "app-event-form__row" }
const _hoisted_3 = { class: "app-event-form__row" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "app-event-form__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmTextarea = _resolveComponent("DmTextarea")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_DmAutocomplete = _resolveComponent("DmAutocomplete")
  const _component_DmSelect = _resolveComponent("DmSelect")
  const _component_DmPhoneInput = _resolveComponent("DmPhoneInput")
  const _component_DmSwitch = _resolveComponent("DmSwitch")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_DmForm = _resolveComponent("DmForm")

  return (_openBlock(), _createBlock(_component_DmForm, {
    "initial-values": _ctx.initialValues,
    "validation-schema": _ctx.validationSchema,
    class: _ctx.$bem({}),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(({ errors, setFieldValue, values, submitCount }) => [
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('repair.sectionTitle')), 3),
            _createVNode(_component_DmField, { name: "reason" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmTextarea, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  rows: 3,
                  error: errors.reason,
                  "data-test": "field-notes",
                  label: `${_ctx.$t('repair.fields.reason')} *`,
                  required: ""
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_DmField, { name: "price" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    type: "number",
                    step: 10,
                    min: 0,
                    max: 100000,
                    class: "app-event-form__row-item",
                    error: errors.price,
                    "data-test": "field-price",
                    label: _ctx.$t('repair.fields.price')
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "notes" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmTextarea, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    rows: 1,
                    class: "app-event-form__row-item--flex-2",
                    error: errors.notes,
                    "data-test": "field-notes",
                    label: _ctx.$t('repair.fields.notes')
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024)
            ])
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('cars.sectionTitle')), 3),
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_DmField, { name: "brand" }, {
                default: _withCtx(({ field, validate }) => [
                  _createVNode(_component_DmAutocomplete, {
                    "model-value": field.value ? {
                key: field.value.id,
                text: field.value.name
              } : null,
                    items: _ctx.brands,
                    "items-loading": _ctx.carBrowserLoading,
                    "items-error": _ctx.carBrowserError,
                    "items-reloadable": "",
                    light: "",
                    rounded: "",
                    required: "",
                    maxlength: 128,
                    error: errors.brand,
                    class: "app-event-form__row-item",
                    "data-test": "field-brand",
                    label: `${_ctx.$t('cars.fields.brand')} *`,
                    onUpdate: ($event: any) => (_ctx.updateSelectedBrand($event, setFieldValue, validate)),
                    onBlur: ($event: any) => (_ctx.call(validate)),
                    onReload: _ctx.reloadCarBrowser
                  }, null, 8, ["model-value", "items", "items-loading", "items-error", "error", "label", "onUpdate", "onBlur", "onReload"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "model" }, {
                default: _withCtx(({ field, validate }) => [
                  _createVNode(_component_DmAutocomplete, {
                    "model-value": field.value ? {
                key: field.value.id,
                text: field.value.name
              } : null,
                    items: _ctx.models,
                    light: "",
                    rounded: "",
                    required: "",
                    maxlength: 128,
                    class: "app-event-form__row-item",
                    error: errors.model,
                    "data-test": "field-model",
                    label: `${_ctx.$t('cars.fields.model')} *`,
                    onUpdate: ($event: any) => (_ctx.updateSelectedModel($event, setFieldValue, validate)),
                    onBlur: ($event: any) => (_ctx.call(validate))
                  }, null, 8, ["model-value", "items", "error", "label", "onUpdate", "onBlur"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "carYear" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    min: 1900,
                    class: "app-event-form__row-item",
                    max: 2023,
                    maxlength: 4,
                    error: errors.carYear,
                    label: `${_ctx.$t('cars.fields.year')}`,
                    onBlur: ($event: any) => (_ctx.asyncFieldCheck(field, setFieldValue))
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label", "onBlur"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createVNode("div", _hoisted_3, [
              _createVNode(_component_DmField, { name: "licensePlateNumber" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    required: "",
                    class: "app-event-form__row-item",
                    maxlength: 16,
                    error: errors.licensePlateNumber,
                    "data-test": "field-license-plate-number",
                    label: `${_ctx.$t('cars.fields.licensePlateNumber')} *`
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, {
                name: "vin",
                class: "app-event-form__vin"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    class: "app-event-form__row-item--flex-2",
                    rounded: "",
                    maxlength: 20,
                    error: errors.vin,
                    "data-test": "field-vin",
                    label: _ctx.$t('cars.fields.vin')
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024)
            ])
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            (_ctx.hourToSelect)
              ? (_openBlock(), _createBlock("div", _hoisted_4, [
                  _createVNode("h3", {
                    class: _ctx.$bem({ e: 'section-header' })
                  }, _toDisplayString(_ctx.$t('newRepair.repairHour')), 3),
                  _createVNode(_component_DmSelect, {
                    "model-value": _ctx.start,
                    class: "has-margin-bottom-lg",
                    items: _ctx.workingHoursSlots,
                    rounded: "",
                    light: "",
                    onChange: _ctx.change
                  }, null, 8, ["model-value", "items", "onChange"])
                ]))
              : _createCommentVNode("", true),
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('clients.sectionTitle')), 3),
            _createVNode(_component_DmField, { name: "name" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmInput, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  required: "",
                  maxlength: 128,
                  error: errors.name,
                  type: "text",
                  "data-test": "field-name",
                  label: `${_ctx.$t('clients.fields.name')} *`
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode("div", _hoisted_5, [
              _createVNode(_component_DmField, { name: "phone" }, {
                default: _withCtx(({ validate }) => [
                  _createVNode(_component_DmPhoneInput, {
                    modelValue: values.phone,
                    "onUpdate:modelValue": ($event: any) => (values.phone = $event),
                    valid: _ctx.isPhoneValid,
                    "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPhoneValid = $event)),
                    light: "",
                    rounded: "",
                    required: "",
                    lazy: submitCount === 0,
                    hint: _ctx.$t('clients.hints.phone'),
                    "default-country-code": "PL",
                    class: "app-event-form__row-item",
                    "error-text": _ctx.$t('clients.rules.phone'),
                    "data-test": "field-phone",
                    label: `${_ctx.$t('clients.fields.phone')} *`,
                    onValidate: validate,
                    onBlur: ($event: any) => {
                _ctx.asyncFieldCheck({ name: 'phone', value: values.phone }, setFieldValue);
                validate();
                _ctx.isPhoneTouched = true;
              }
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "lazy", "hint", "error-text", "label", "onValidate", "onBlur"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "email" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    maxlength: 128,
                    type: "email",
                    class: "app-event-form__row-item",
                    error: errors.email,
                    "data-test": "field-email",
                    label: _ctx.$t('clients.fields.email'),
                    onBlur: ($event: any) => (_ctx.asyncFieldCheck(field, setFieldValue))
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label", "onBlur"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createVNode(_component_DmField, { name: "marketingConsent" }, {
              default: _withCtx(({ validate }) => [
                _createVNode(_component_DmSwitch, {
                  id: "checkboxMarketingConsent",
                  modelValue: values.marketingConsent,
                  "onUpdate:modelValue": ($event: any) => (values.marketingConsent = $event),
                  light: "",
                  rounded: "",
                  elevated: "",
                  error: errors.marketingConsent,
                  "data-test": "field-marketing-consent",
                  label: _ctx.$t('clients.fields.marketingConsent', { garage: _ctx.garageName }),
                  onChange: validate
                }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "label", "onChange"])
              ]),
              _: 2
            }, 1024)
          ], 2),
          _renderSlot(_ctx.$slots, "bottom", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer", {
            phone: values.phone,
            reason: values.reason
          })
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema", "class", "onSubmit"]))
}