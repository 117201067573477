import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import {
  cookies,
  emitter,
  env,
  filters,
  http,
  i18n,
  ui,
  gtm,
  createSentry
} from '@/plugins';
import router from './router';
import store from './store';

(async () => {
  const app = createApp(App);
  app
    .use(http)
    .use(cookies)
    .use(emitter)
    .use(env)
    .use(filters)
    .use(i18n)
    .use(ui)
    .use(gtm)
    .use(store)
    .use(router)
    .mount('#app');

  createSentry(app);
})();
