import { LocaleDictionary } from '../models';

export const localePl: LocaleDictionary = {
  common: {
    back: 'Wstecz',
    cancel: 'Anuluj',
    confirm: 'Potwierdzam',
    save: 'Zapisz',
    restore: 'Przywróć',
    copyToClipboard: 'Skopiuj do schowka',
    attention: 'Uwaga!',
    tryAgain: 'Spróbuj ponownie',
    search: 'Wyszukaj',
    select: 'Wybierz',
    checkout: 'Sprawdź',
    edit: 'Edytuj',
    details: 'Szczegóły',
    yes: 'Tak',
    no: 'Nie',
    notKnown: 'Nie wiadomo',
    download: 'Pobierz',
    next: 'Dalej'
  },
  notifications: {
    somethingWentWrong: 'Coś poszło nie tak',
    changeSuccess: 'Dane zostały zmienione',
    copiedToClipboard: 'Skopiowano do schowka'
  },
  changelog: {
    tip: {
      title: 'Zmiany w Zilo',
      description: 'W Zilo pojawiły się nowe funkcje',
      goToChangelog: 'Przejdź do historii zmian'
    },
    title: 'Historia zmian'
  },
  home: {
    goToApp: 'Przejdź do aplikacji',
    login: 'Przejdź do logowania'
  },
  login: {
    goToHomePage: 'Powrót do strony głównej',
    title: 'Logowanie',
    email: 'Email',
    password: 'Hasło',
    submit: 'Zaloguj',
    noEmail: 'Podaj swój adres email',
    wrongEmail: 'Podaj poprawny adres email',
    noPassword: 'Podaj hasło',
    loginFailed: 'Próba logowania nie powiodła się',
    passwordHint: 'Nie znasz swojego hasła? Skontaktuj się ze swoim opiekunem w {site} {phone} lub {email}'
  },
  resetPassword: {
    title: 'Zapomniałeś hasła?',
    description: 'Wystarczy, że podasz swój e-mail, a my pomożemy Ci ustawić nowe hasło'
  },
  setPassword: {
    title: 'Wpisz nowe hasło',
    error: 'Hasło powinno zawierać minimum 8 znaków'
  },
  bok: {
    contact: 'Kontakt {phone} lub {email}'
  },
  app: {
    loadingGarageDetails: 'Ładowanie danych warsztatu',
    pageNotFound: 'Być może strona, której szukasz została przeniesiona lub usunięta.',
    header: {
      calendar: 'Stanowiska',
      clients: 'Klienci',
      forPickup: 'Do wydania',
      settings: 'Ustawienia',
      statistics: 'Statystyki',
      workspaces: 'Stanowiska',
      logout: 'Wyloguj',
      services: 'Usługi',
      bok: 'Kontakt',
      parts: 'Katalog części',
      quote: 'Wyceny',
      comission: 'Rozliczenia'
    },
    regulations: {
      title: 'Regulamin',
      logout: 'Wyloguj',
      save: 'Zapisz ustawienia i przejdź do aplikacji',
      consentRequired: 'Wyrażenie zgody jest wymagane'
    }
  },
  pickup: {
    noItems: 'Brak pojazdów do wydania',
    pickup: 'Wydaj',
    confirmationDescription: 'Potwierdź odbiór samochodu przez klienta',
    failure: 'Wydanie pojazdu nie powiodło się'
  },
  settings: {
    title: 'Ustawienia',
    garage: {
      title: 'Dane warsztatu',
      name: 'Nazwa',
      email: 'Email',
      phone: 'Telefon',
      address: 'Adres',
      zipCode: 'Kod pocztowy',
      city: 'Miejscowość',
      validation: {
        name: 'Podaj nazwę',
        email: 'Podaj poprawny adres e-mail',
        phone: 'Podaj poprawny nr telefonu',
        address: 'Podaj adres',
        zipCode: 'Podaj poprawny kod pocztowy',
        city: 'Podaj miejscowość'
      }
    },
    company: {
      title: 'Dane firmy (do faktury)',
      name: 'Nazwa',
      email: 'Email',
      phone: 'Telefon',
      nip: 'NIP',
      address: 'Adres',
      zipCode: 'Kod pocztowy',
      city: 'Miejscowość',
      validation: {
        name: 'Podaj nazwę',
        email: 'Podaj poprawny adres e-mail',
        phone: 'Podaj poprawny nr telefonu',
        nip: 'Podaj poprawny nr NIP',
        address: 'Podaj adres',
        zipCode: 'Podaj poprawny kod pocztowy',
        city: 'Podaj miejscowość'
      }
    },
    logo: {
      title: 'Logo',
      edit: 'Zmień',
      uploadTitle: 'Wybierz logo',
      preview: 'Podgląd',
      change: 'Wybierz inny plik'
    },
    workspaces: {
      title: 'Stanowiska',
      visibility: 'Widoczne w DM\njako wymiana opon',
      businessHours: 'Godziny robocze',
      workspace: 'Nazwa stanowiska',
      new: 'Nowe stanowisko',
      addFailed: 'Dodanie stanowiska nie powiodło się',
      editFailed: 'Edycja stanowiska nie powiodła się',
      statusChangeFailed: 'Zmiana statusu nie powiodła się',
      showInactive: 'Pokaż nieaktywne',
      scheduledWorkspace: 'Stanowisko czasowe',
      quantityWorkspace: 'Stanowisko ilościowe',
      visibleOutside: 'Widoczne w DM',
      visibleOutsideTires: 'Widoczne w DM',
      invisibleOutside: 'Niewidoczne w DM',
      add: 'Dodaj stanowisko',
      edit: 'Edycja stanowiska'
    },
    files: {
      title: 'Pliki do pobrania',
      gdpr: 'Klauzula RODO'
    }
  },
  statistics: {
    controls: {
      weeks: 'tygodnie',
      months: 'miesiące',
      quarters: 'kwartały',
      years: 'lata',
      weekFrom: 'tydz. od: {week}',
      compareWith: 'porównaj z'
    },
    boxes: {
      allRepairs: 'Wszystkie naprawy',
      repairsValue: 'Suma wartości napraw',
      averageRepairValue: 'Średnia wartość naprawy',
      numberOfCancellations: 'Liczba odwołań',
      numberOfClients: 'Liczba klientów',
      numberOfNewClients: 'Liczba nowych klientów',
      numberOfReturningClients: 'Liczba powracających klientów'
    },
    chart: {
      analyzedPeriod: 'okres analizowany',
      comparativePeriod: 'okres porównawczy'
    },
    topRepairs: {
      title: 'Ranking wizyt'
    },
    repairsSources: {
      title: 'Źródło napraw'
    }
  },
  clients: {
    results: '{n} klient | {n} klienci | {n} klientów',
    add: 'Dodaj nowego klienta',
    name: 'Imię i nazwisko',
    phone: 'Nr telefonu',
    email: 'Email',
    lastRepair: 'Ostatnie zlecenie',
    alreadyExists: 'Klient już istnieje',
    sectionTitle: 'Dane klienta',
    client: {
      title: 'Dane klienta'
    },
    cars: {
      title: 'Pojazdy',
      noItems: 'Brak pojazdów'
    },
    repairs: {
      title: 'Zlecenia',
      allCars: 'Wszystkie pojazdy',
      showAll: 'Pokaż wszystkie',
      noItems: 'Brak zleceń',
      add: 'Dodaj zlecenie',
      new: 'Nowa wizyta'
    },
    rules: {
      phone: 'Wpisz poprawny numer telefonu',
      name: 'Wpisz imię i nazwisko',
      email: 'Wpisz poprawny adres email',
      marketingConsent: 'Określ, czy klient wyraził zgodę'
    },
    hints: {
      phone: 'W formacie bez spacji i myślników np. 601345789'
    },
    fields: {
      name: 'Imię i nazwisko',
      phone: 'Nr telefonu',
      email: 'Adres e-mail',
      marketingConsent: 'Zgoda na otrzymywanie emaili / SMS-ów/ telefonów z ofertami i treściami marketingowymi'
    },
    new: {
      title: 'Nowy klient',
      submit: 'Dodaj klienta',
      addSuccess: 'Klient został dodany',
      addError: 'Dodanie klienta nie powiodło się'
    },
    edit: {
      title: 'Edycja klienta',
      submit: 'Zapisz nowe dane',
      success: 'Dane klienta zostały zmienione',
      error: 'Zmiana danych klienta nie powiodła się'
    }
  },
  cars: {
    add: 'Dodaj pojazd',
    alreadyExists: 'Pojazd już istnieje',
    popular: 'Popularne',
    sectionTitle: 'Dane pojazdu',
    new: {
      title: 'Nowy pojazd',
      submit: 'Dodaj pojazd',
      addSuccess: 'Pojazd został dodany',
      addError: 'Dopanie pojazdu nie powiodło się'
    },
    edit: {
      title: 'Edycja pojazdu',
      submit: 'Zapisz nowe dane',
      success: 'Dane pojazdu zostały zmienione',
      error: 'Zmiana danych pojazdu nie powiodła się'
    },
    rules: {
      brand: 'Wpisz markę',
      model: 'Wpisz model',
      licensePlateNumber: 'Wpisz poprawny nr',
      vin: 'Wpisz poprawny nr VIN'
    },
    fields: {
      car: 'Pojazd',
      brand: 'Marka',
      model: 'Model',
      licensePlateNumber: 'Rejestracja',
      vin: 'VIN',
      type: 'Typ',
      year: 'Rok produkcji',
      engine: 'Silnik'
    }
  },
  repair: {
    new: 'Nowe',
    loading: 'Ładowanie',
    status: {
      title: 'Status zlecenia',
      fillData: 'Uzupełnij dane, aby zmienić status wizyty',
      new: 'Nowe',
      admitted: 'Przyjęte',
      repaired: 'Naprawione',
      completed: 'Zakończone'
    },
    cancel: 'Odwołaj',
    edit: 'Edytuj dane',
    confirmation: 'Potwierdzenie',
    sectionTitle: 'Szczegóły wizyty',
    fields: {
      reason: 'Powód wizyty',
      price: 'Cena (zł)',
      notes: 'Notatki wewnętrzne'
    },
    rules: {
      reason: 'Wpisz powód wizyty',
      price: 'Podaj cenę z przedziału 0 - 100 000 zł',
      year: 'Podaj poprawny rok produkcji'
    },
    statusChange: {
      confirmAdmission: 'Potwierdź przyjęcie samochodu',
      confirmRepair: 'Potwierdź naprawę samochodu (kierowca otrzyma email oraz wiadomość SMS z potwierdzeniem naprawy).',
      sendAdmissionSms: 'Wyślij także na SMS i e-mail kierowcy potwierdzenie zlecenia',
      sendRepairSms: 'Wyślij także na SMS i e-mail kierowcy potwierdzenie naprawy',
      sendPrice: 'Dodaj informację o kwocie naprawy',
      priceRule: 'Podaj kwotę z przedziału 1 zł - 100 000 zł',
      error: 'Zmiana statusu nie powiodła się'
    },
    cancellation: {
      sendSms: 'Wyślij także na SMS i e-mail kierowcy potwierdzenie odwołania',
      cancel: 'Odwołaj wizytę',
      cancellationSuccess: 'Udało się poprawnie odwołać wizytę'
    },
    selectCar: 'Wybierz samochód',
    carSelected: 'Wybrany',
    newCar: 'Nowy samochód',
    saveNewData: 'Zapisz nowe dane',
    editError: 'Zmiana danych nie powiodła się.',
    clientExists: 'Ten klient już jest w naszej bazie.',
    chooseNewRepairDate: 'Wybierz nową datę wizyty'
  },
  calendar: {
    pending: {
      title: 'Oczekujące',
      add: 'Dodaj zlecenie',
      noItems: 'Brak niezaplanowanych wydarzeń',
      showPendingItems: 'Pokaż oczekujące wizyty',
      hidePendingItems: 'Ukryj oczekujące wizyty'
    },
    loading: 'Ładowanie kalendarza',
    eventsLoading: 'Ładowanie danych',
    event: {
      durationChangeError: 'Zmiana czasu trwania naprawy nie powiodła się.',
      moveError: 'Zmiana terminu/stanowiska nie powiodła się.',
      moveToPendingError: 'Przeniesienie wydarzenia do niezaplanowanych nie powiodło się.',
      moveFromPendingError: 'Dodanie wydarzenia do kalendarza nie powiodło się.',
      sourceDM: 'Źródło zlecenia: Dobry Mechanik',
      reorderError: 'Zmiana kolejności wizyty nie powiodła się.'
    },
    moveToCalendar: {
      confirmChange: 'Potwierdź zmianę terminu',
      sendSms: 'Wyślij także potwierdzenie na SMS i e-mail kierowcy'
    },
    buttons: {
      prev: 'Poprzedni',
      next: 'Następny',
      today: 'Dzisiaj',
      month: 'Miesiąc',
      week: 'Tydzień',
      day: 'Dzień',
      list: 'Lista'
    },
    contact: {
      header: 'Problem z Zilo?',
      content: 'Zadzwoń {phone} lub napisz na {email}'
    }
  },
  newRepair: {
    new: 'Nowy',
    returning: 'Powracający',
    showMore: 'Pokaż więcej',
    showLess: 'Pokaż mniej',
    add: 'Dodaj klienta i zlecenie',
    addError: 'Utworzenie wizyty nie powiodło się.',
    browser: {
      hint: 'Wpisz dowolne dane klienta, aby go wyszukać w bazie.',
      example: 'Na przykład imię, nazwisko, nr telefonu, adres email, nr rejestracyjny, VIN',
      loading: 'Wyszukiwanie w bazie',
      noMatchingItems: 'Brak wyników',
      addClient: 'Dodaj nowego klienta',
      newCar: 'Nowy samochód',
      addRepair: 'Dodaj zlecenie'
    },
    repairHour: 'Godzina podstawienia'
  },
  days: {
    sunday: 'Niedziela',
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota'
  },
  daysShortcuts: {
    sunday: 'Nied.',
    monday: 'Pon.',
    tuesday: 'Wt.',
    wednesday: 'Śr.',
    thursday: 'Czw.',
    friday: 'Pt.',
    saturday: 'Sob.'
  },
  months: {
    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwiec',
    july: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień'
  },
  quote: {
    createPDF: 'Generuj wycenę w PDF',
    pdfName: 'Wycena',
    clientData: {
      header: 'Dane klienta',
      phone: 'Telefon',
      name: 'Imię i nazwisko',
      email: 'E-mail'
    },
    carData: {
      header: 'Dane samochodu',
      name: 'Nazwa',
      licencePlate: 'Numer rejestracyjny'
    },
    parts: {
      header: 'Części',
      name: 'Nazwa',
      quantity: 'Ilość',
      manufacturer: 'Producent',
      priceNet: 'Cena jednostkowa netto (zł)',
      priceGross: 'Cena jednostkowa brutto (zł)'
    },
    services: {
      header: 'Usługi',
      name: 'Nazwa',
      priceNet: 'Cena jednostkowa netto (zł)',
      priceGross: 'Cena jednostkowa brutto (zł)'
    },
    notes: {
      header: 'Notatki dla klienta',
      description: 'Dodatkowy opis dla klienta'
    },
    internal_notes: {
      header: 'Notatki wewnętrzne/notatki dla warsztatu',
      description: 'Dodatkowy wewnętrzny opis dla warsztatu'
    },
    notifications: {
      somethingWentWrong: 'Coś poszło nie tak'
    }
  }
};
