
import { defineComponent } from 'vue';
import {
  DmWrapper
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppWrapper',
  components: {
    DmWrapper
  }
});
