export enum RouteName {
  HOME = 'Home',
  SCHEDULER = 'Scheduler',
  CLIENTS = 'Clients',
  PICKUP = 'Pickup',
  NOT_FOUND = 'NotFound',
  LOGIN = 'Login',
  STATS = 'Stats',
  SETTINGS = 'Settings',
  FORWARD = 'Forward',
  CHANGELOG = 'Changelog',
  WORKSPACES = 'Workspaces',
  CONTACT = 'Contact',
  QUOTE = 'Quote',
  RESET_PASSWORD = 'ResetPassword',
  SET_PASSWORD = 'SetPassword',
  COMISSION = 'Comission'
}
