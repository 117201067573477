export interface EventMetadata {
  start: Date;
  startStr: string;
  workspace: string;
  workspaceId: string;
  externalConfig?: any;
}

export interface EventFormData {
  name: string | null;
  phone: string | null;
  email: string | null;
  price?: number | null;
  notes: string | null;
  reason: string | null;
  brand: {
    id: string | null;
    name: string | null;
  };
  model: {
    id: string | null;
    name: string | null;
  };
  licensePlateNumber: string | null;
  vin: string | null;
  marketingConsent: boolean | null;
  carTecDocBrand?: {
    id: string | number | null;
    name: string | null;
  };
  carTecDocModel?: {
    id: string | number | null;
    name: string | null;
  };
  carTecDocType?: {
    id: string | number | null;
    name: string | null;
  };
  carYear?: number | null | string;
  carId?: string;
}

export enum EventStatus {
  NEW = 1,
  RECEIVED = 2,
  REPAIRED = 3,
  CLOSED = 4
}

export interface RepairCarSpecificationInternal {
  brand: {
    id: string | null;
    text: string;
  };
  model: {
    id: string | null;
    text: string;
  };
}

export interface RepairCarSpecificationTecDoc {
  brand: {
    id: string;
    text: string;
  } | null;
  model: {
    id: string;
    text: string;
  } | null;
  type: {
    id: string;
    text: string;
  } | null;
}

export interface RepairCarSpecification {
  internal: RepairCarSpecificationInternal;
  tecdoc: RepairCarSpecificationTecDoc;
}

export interface RepairCar {
  specification: RepairCarSpecification;
  licensePlateNumber: string;
  vin: string | null;
  year: number | null;
}

export interface RepairClient {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string | null;
  marketingConsent: boolean;
}

export interface RepairData {
  reason: {
    id: string | null;
    text: string;
  };
  notes: string | null;
  price: number | null;
}

export interface RepairDataCreate extends RepairData {
  workspaceId: string | null;
  plannedRepairAt: string | null;
}

export interface EditRepairPayload {
  car: RepairCar;
  client: RepairClient;
  repair: RepairData;
}

export enum CreateRepairType {
  CreateByFullData = 'createByFullData',
  CreateByPartialData = 'createByPartialData',
  CreateByReturningClient = 'createByReturningClient',
  CreateByReturningClientWithNewCar = 'createByReturningClientWithNewCar'
}

export interface CreateRepairFullData {
  client: RepairClient;
  car: RepairCar;
  repair: RepairDataCreate;
}

export interface CreateRepairPartialData {
  client: {
    phoneNumber: string;
  };
  repair: {
    workspaceId: string | null;
    plannedRepairAt: string | null;
    reason: {
      id: string | null;
      text: string;
    };
  };
}

export interface CreateRepairReturningClient {
  client: {
    id: string;
    marketingConsent: boolean;
  };
  car: {
    id: string;
  };
  repair: RepairDataCreate;
}

export interface CreateRepairReturningClientWithNewCar {
  client: {
    id: string;
    marketingConsent: boolean;
  };
  car: {
    specification: RepairCarSpecification;
    licensePlateNumber: string;
    vin: string | null;
    year: number | null;
  };
  repair: RepairDataCreate;
}

export type CreateRepairData = CreateRepairFullData | CreateRepairPartialData | CreateRepairReturningClient | CreateRepairReturningClientWithNewCar;

export interface CreateRepairPayload {
  type: CreateRepairType;
  payload: CreateRepairData;
}
