import { createI18n } from 'vue-i18n';
import { Locale, localeEn, localePl } from '@/locales';
import { LocalStorageKey } from '@/models/storage';

const locale = localStorage.getItem(LocalStorageKey.LOCALE) || Locale.PL;

export const i18n = createI18n({
  locale,
  pluralizationRules: {
    [Locale.PL]: (value: number) => {
      value = Math.abs(value);
      if (value === 1) {
        return 0;
      } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return 1;
      } else {
        return 2;
      }
    },
    [Locale.EN]: (value: number) => {
      value = Math.abs(value);
      if (value === 1) {
        return 0;
      } else {
        return 1;
      }
    }
  },
  messages: {
    [Locale.PL]: localePl,
    [Locale.EN]: localeEn
  }
});
