export enum LocalStorageKey {
  SEND_PRICE_STATUS = 'send-price-status',
  RETURN_PATH = 'return-path',
  IS_LOGGED = 'is-logged',
  LOCALE = 'locale',
  LAST_VERSION = 'last-version'
}

export enum CookiesKey {
  ACCESS_TOKEN = 'access-token'
}
