import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("span", {
    class: _ctx.$bem({})
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.characters, (c, i) => {
      return (_openBlock(), _createBlock("span", {
        key: i,
        class: _ctx.$bem({
        e: 'character',
        m: {
          highlighted: c.isHighlighted
        }
      })
      }, _toDisplayString(c.character), 3))
    }), 128))
  ], 2))
}