
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';

export default defineComponent({
  name: 'BokHeader',
  data () {
    return {
    };
  }
});
