
import { defineComponent, PropType } from 'vue';
import { formatPhone } from '@/helpers/phone';

export default defineComponent({
  name: 'AppPhoneNumber',
  props: {
    value: {
      type: String as PropType<string | null>,
      default: ''
    },
    highlightPattern: {
      type: String as PropType<string | null>,
      default: ''
    }
  },
  computed: {
    formattedPhone (): string {
      return formatPhone(this.value || '');
    },
    characters (): any[] {
      const phone = this.formattedPhone.split(' ').join('');
      const chars = phone.split('');
      const patternLength = this.highlightPattern?.length || 0;

      const characters = chars.map((char, i) => {
        let isHighlighted = false;
        if (this.highlightPattern) {
          const charArea = this.value?.substring(i - patternLength + 1, i + patternLength);
          isHighlighted = charArea?.includes(this.highlightPattern) || false;
        }

        return {
          character: char,
          isHighlighted
        };
      });
      const formattedPhoneChars = this.formattedPhone.split('');
      const formattedPhoneCharacters = [];
      let position = 0;
      for (const char of formattedPhoneChars) {
        if (char === ' ') {
          formattedPhoneCharacters.push({
            character: ' ',
            isHighlighted: false
          });
        } else {
          formattedPhoneCharacters.push(characters[position]);
          position++;
        }
      }
      return formattedPhoneCharacters;
    }
  }
});
