
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppCarForm,
  AppModalButton,
  AppModalBody,
  AppModalFooter
} from '@/components';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { URI_CAR } from '@/api/endpoints';
import {
  Car
} from '@/api/models';
import { CarFormData } from '@/models/cars';
import { getManuDateRange } from '@/helpers/functions';
import {
  DmButton,
  DmAutocomplete
} from '@dobrymechanik/vue-ui';

enum Mode {
  Default,
  TecDoc
}

export default defineComponent({
  name: 'AppCarEditModal',
  components: {
    AppModal,
    AppModalHeader,
    AppCarForm,
    AppModalButton,
    DmAutocomplete,
    AppModalBody,
    AppModalFooter
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    details: {
      type: Object as PropType<Car>,
      required: true
    }
  },
  emits: [
    'close',
    'update:modelValue',
    'edit'
  ],
  data: function () {
    return {
      loading: false as boolean,
      mode: Mode.Default as unknown | string,
      carTypeValue: null as null | any,
      carBrandValue: null as null | any,
      carModelValue: null as null | any,
      brands: [],
      models: [],
      types: [],
      carBrandLoading: false,
      carModelLoading: false,
      carTypeLoading: false,
      year: '' as string | number
    };
  },
  computed: {
    initialValues (): CarFormData {
      return {
        brand: {
          id: this.details.brandId || null,
          name: this.details.brand
        },
        model: {
          id: this.details.modelId || null,
          name: this.details.model
        },
        licensePlateNumber: this.details.licensePlateNumber,
        vin: this.details.vin,
        year: this.details.tecDocMeta.year
      };
    }
  },
  async mounted () {
    this.initTecDocCarData();
    await this.GETbrands();
    if (this.initialValues.brand?.id) {
      this.GETmodels();
    }
    if (this.initialValues.model?.id) {
      this.GETtypes();
    }
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    initTecDocCarData (): void {
      this.carBrandValue = {
        text: this.details.tecDocMeta.brandName,
        key: this.details.tecDocMeta.brandId
      };
      this.carModelValue = {
        text: this.details.tecDocMeta.modelName,
        key: this.details.tecDocMeta.modelId
      };
      this.carTypeValue = {
        text: this.details.tecDocMeta.typeName,
        key: this.details.tecDocMeta.typeId
      };
    },
    close (): void {
      this.$emit('close');
      this.$emit('update:modelValue', false);
    },
    async submit (values: any): Promise<void> {
      this.loading = true;
      const data = jsonToURLSearchParams({
        ...values,
        tecDocBrandId: this.carBrandValue?.key,
        tecDocModelId: this.carModelValue?.key,
        tecDocTypeId: this.carTypeValue?.key,
        tecDocBrandName: this.carBrandValue?.text,
        tecDocModelName: this.carModelValue?.text,
        tecDocTypeName: this.carTypeValue?.text
      });
      try {
        await this.$http.patch(URI_CAR(this.details.id || ''), data);
        this.createNotification({
          message: this.$t('cars.edit.success'),
          type: 'success'
        });
        this.$emit('edit');
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('cars.edit.error'),
          type: 'error'
        });
      }
      this.loading = false;
    },
    convertTypeName (type: any) {
      const name = type.name;
      const power = `${type.power.kw.from}kW / ${type.power.hp.from}KM`;
      const engines = type.engine.codes.join(', ');
      return `${name} (${power}) (${engines})`;
    },
    async GETbrands () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + 'api/brands?page=1';
      const { data } = await this.$http.get<any>(endpoint);

      this.brands = data['hydra:member'].map((el: any) => {
        return {
          text: el.name,
          key: el.id
        };
      });
    },
    async GETmodels () {
      if (!this.carBrandValue.key) return;
      this.carModelLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/brands/${this.carBrandValue.key}/models`;
        const { data } = await this.$http.get<any>(endpoint);

        this.models = data['hydra:member'].map((el: any) => {
          const text = `${el.name} (${getManuDateRange(el.production.from, el.production.to)})`;
          return {
            text: text,
            key: el.id
          };
        });
      } catch {
        //
      } finally {
        this.carModelLoading = false;
      }
    },
    async GETtypes () {
      if (!this.carBrandValue.key || !this.carModelValue.key) return;
      this.carTypeLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/vehicles?brand=${this.carBrandValue.key}&model=${this.carModelValue.key}`;
        const { data } = await this.$http.get<any>(endpoint);

        this.types = data['hydra:member'].map((el: any) => {
          return {
            text: this.convertTypeName(el),
            key: el.id
          };
        });
      } catch {
        //
      } finally {
        this.carTypeLoading = false;
      }
    },
    onBrandUpdate (value: any) {
      this.carBrandValue = value;

      if (value?.key) {
        this.GETmodels();
      } else {
        this.GETbrands();
        this.models = [];
        this.types = [];
        this.carModelValue = null;
        this.carTypeValue = null;
      }
    },
    onModelUpdate (value: any) {
      this.carModelValue = value;

      if (value?.key) {
        this.GETtypes();
      } else {
        this.GETmodels();
        this.types = [];
        this.carTypeValue = null;
      }
    },
    onTypeUpdate (value: any) {
      this.carTypeValue = value;
      if (!value?.key) {
        this.GETtypes();
      }
    },
    clearTecDocDsc () {
      if (!this.carTypeValue?.key) {
        this.carBrandValue = null;
        this.carModelValue = null;
        this.carTypeValue = null;
      }
      this.mode = 0;
    }
  }
});
