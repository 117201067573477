
import { defineComponent, PropType } from 'vue';
import { convertImageToBase64 } from '@/helpers/convertImg';
import {
  DmIcon
} from '@dobrymechanik/vue-ui';

interface ShoppingBasketProductInventory {
  productId: number;
  quantity: number;
  vehicleId: number | null;
  netPrice?: number | null;
  retailPrice?: number | null;
}

interface Product {
  '@id': string;
  id: number;
  name: string;
  manufacturer: any;
  manufacturerProductNo: string;
  resources: any[];
  attributes: any[];
  inventory: any;
}

export default defineComponent({
  name: 'AppEventModalProductInventory',

  components: { DmIcon },

  data () {
    return {
      details: null as null | Product,
      imgBase64: null as string | null
    };
  },

  props: {
    productInventory: {
      type: Object as PropType<ShoppingBasketProductInventory>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  computed: {
    imgSource (): any {
      return this.imgBase64 ? `data:image/png;base64,${this.imgBase64}` : '/no-image.png';
    },

    productImages (): any {
      return this.details ? this.details.resources.filter(resource => resource.extension === 'jpg') : [];
    },

    firstProductImage (): any {
      return this.productImages.length ? this.productImages[0] : null;
    }
  },

  async mounted () {
    await this.fetchDetails();
    this.tryToCatchProductImage();
  },

  methods: {
    async fetchDetails () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/products-without-context/${this.productInventory.productId}`;
      const { data } = await this.$http.get<any>(endpoint);
      this.details = data;
    },

    async fetchImg (imageResource: string) {
      const pathParams = {
        uri: imageResource
      };

      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + imageResource.substring(1);
        const { data } = await this.$http.get<any>(endpoint, { responseType: 'arraybuffer' });

        this.imgBase64 = convertImageToBase64(data);
      } finally { }
    },

    tryToCatchProductImage () {
      if (this.firstProductImage) {
        this.fetchImg(this.firstProductImage['@id']);
      }
    }
  }

});
