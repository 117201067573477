

import { defineComponent, PropType } from 'vue';
import {
  DmModal,
  DmButton,
  DmIcon,
  DmIconButton
} from '@dobrymechanik/vue-ui';
import { RouteName } from '@/router/models';
import { CommisionInvoices } from '@/models';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'AppDebtsModal',
  components: {
    DmModal,
    DmButton,
    DmIcon,
    DmIconButton
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    debts: {
      type: Object as PropType<CommisionInvoices>,
      required: true
    }
  },
  emits: ['update:model-value'],
  data () {
    return {
      loading: false
    };
  },
  computed: {
    deadline (): string {
      return dayjs().subtract(Number(this.debts.debts_issue_days) || 0, 'days').format('DD.MM.YYYY');
    }
  },
  methods: {
    close () {
      this.$emit('update:model-value', false);
    },
    goToCommissions () {
      this.close();
      this.$router.push({ name: RouteName.COMISSION });
    }
  }
});
