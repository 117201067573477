import { MutationTree } from 'vuex';
import { NotificationsState } from './types';
import { Notification } from '@/models/notifications';

export const INCREMENT_LAST_NOTIFITATION_ID = 'INCREMENT_LAST_NOTIFITATION_ID';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const mutations: MutationTree<NotificationsState> = {
  [INCREMENT_LAST_NOTIFITATION_ID]: (state) => {
    state.lastNotificationId++;
  },
  [ADD_NOTIFICATION]: (state, notification: Notification) => {
    state.notifications.push(notification);
  },
  [REMOVE_NOTIFICATION]: (state, id: number) => {
    state.notifications = state.notifications.filter(item => item.id !== id);
  }
};
