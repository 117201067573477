
import { defineComponent } from 'vue';
import {
  DmButton
} from '@dobrymechanik/vue-ui';
import { LocalStorageKey } from '@/models/storage';
import { locales } from '@/locales';

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    DmButton
  },
  data () {
    return {
      languages: locales
    };
  },
  methods: {
    setLocale (locale: string): void {
      this.$i18n.locale = locale;
      this.$ui.locale = locale;
      localStorage.setItem(LocalStorageKey.LOCALE, locale);
    }
  }
});
