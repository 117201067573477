import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")

  return (_openBlock(), _createBlock(_component_DmButton, {
    rounded: "",
    color: _ctx.color,
    dark: _ctx.dark,
    bordered: !_ctx.color || _ctx.plain,
    plain: _ctx.plain,
    hoverable: "",
    class: _ctx.$bem({}),
    onClick: _ctx.click
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["color", "dark", "bordered", "plain", "class", "onClick"]))
}