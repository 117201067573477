import { MutationTree } from 'vuex';
import { RootState } from './types';

export const SET_READY_FOR_PICKUP_AMOUNT = 'SET_READY_FOR_PICKUP_AMOUNT';
export const SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED = 'SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED';

export const mutations: MutationTree<RootState> = {
  [SET_READY_FOR_PICKUP_AMOUNT]: (state, counter: number) => {
    state.readyForPickupAmount = counter;
  },
  [SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED]: (state, isLoaded: boolean) => {
    state.isReadyForPickupAmountLoaded = isLoaded;
  }
};
