import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmToolbarLogo = _resolveComponent("DmToolbarLogo")
  const _component_LayoutAppHeaderMenu = _resolveComponent("LayoutAppHeaderMenu")
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_LayoutAppHeaderMobileMenu = _resolveComponent("LayoutAppHeaderMobileMenu")
  const _component_BOK = _resolveComponent("BOK")
  const _component_LayoutAppHeaderControls = _resolveComponent("LayoutAppHeaderControls")
  const _component_DmToolbar = _resolveComponent("DmToolbar")

  return (_openBlock(), _createBlock(_component_DmToolbar, {
    fixed: "",
    light: "",
    color: "white",
    class: _ctx.$bem({ m: { expanded: _ctx.isMenuExpandedOnMobile } })
  }, {
    left: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'logo-wrapper' })
      }, [
        _createVNode(_component_DmToolbarLogo, {
          src: _ctx.logo,
          to: _ctx.homeRoute,
          class: _ctx.$bem({ e: 'logo' }),
          "data-test": "garage-logo",
          onClick: _ctx.closeMenuOnMobile
        }, null, 8, ["src", "to", "class", "onClick"])
      ], 2)
    ]),
    center: _withCtx(() => [
      _createVNode(_component_LayoutAppHeaderMenu, {
        "is-ready-for-pickup-amount-loaded": _ctx.isReadyForPickupAmountLoaded,
        "ready-for-pickup-amount": _ctx.readyForPickupAmount,
        class: _ctx.$bem({ e: 'menu' }),
        onGoToCalendarPage: _ctx.goToCalendarPage,
        onGoToClientsPage: _ctx.goToClientsPage,
        onGoToPickupPage: _ctx.goToPickupPage,
        onGoToComissionPage: _ctx.goToComissionPage
      }, null, 8, ["is-ready-for-pickup-amount-loaded", "ready-for-pickup-amount", "class", "onGoToCalendarPage", "onGoToClientsPage", "onGoToPickupPage", "onGoToComissionPage"])
    ]),
    right: _withCtx(() => [
      _createVNode(_component_DmIconButton, {
        rounded: "",
        icon: "bars",
        color: "primary-light",
        class: _ctx.$bem({ e: 'mobile-menu-button' }),
        onClick: _ctx.toggleMenuOnMobile
      }, null, 8, ["class", "onClick"]),
      _createVNode(_component_LayoutAppHeaderMobileMenu, {
        modelValue: _ctx.isMenuExpandedOnMobile,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMenuExpandedOnMobile = $event)),
        "is-ready-for-pickup-amount-loaded": _ctx.isReadyForPickupAmountLoaded,
        "ready-for-pickup-amount": _ctx.readyForPickupAmount,
        onGoToCalendarPage: _ctx.goToCalendarPage,
        onGoToClientsPage: _ctx.goToClientsPage,
        onGoToPickupPage: _ctx.goToPickupPage,
        onGoToSettingsPage: _ctx.goToSettingsPage,
        onGoToStatsPage: _ctx.goToStatsPage,
        onGoToBokPage: _ctx.goToBokPage,
        onGoToCommission: _ctx.goToComissionPage,
        onGoToWorkspacesPage: _ctx.goToWorkspacesPage,
        onSetLocale: _ctx.setLocale,
        onLogout: _ctx.logout
      }, null, 8, ["modelValue", "is-ready-for-pickup-amount-loaded", "ready-for-pickup-amount", "onGoToCalendarPage", "onGoToClientsPage", "onGoToPickupPage", "onGoToSettingsPage", "onGoToStatsPage", "onGoToBokPage", "onGoToCommission", "onGoToWorkspacesPage", "onSetLocale", "onLogout"]),
      _createVNode(_component_BOK),
      _createVNode(_component_LayoutAppHeaderControls, {
        class: _ctx.$bem({ e: 'controls' }),
        onGoToSettingsPage: _ctx.goToSettingsPage,
        onGoToStatsPage: _ctx.goToStatsPage,
        onGoToWorkspacesPage: _ctx.goToWorkspacesPage,
        onGoToBokPage: _ctx.goToBokPage,
        onSetLocale: _ctx.setLocale,
        onLogout: _ctx.logout
      }, null, 8, ["class", "onGoToSettingsPage", "onGoToStatsPage", "onGoToWorkspacesPage", "onGoToBokPage", "onSetLocale", "onLogout"])
    ]),
    _: 1
  }, 8, ["class"]))
}