export interface WorkspaceWorkingHours {
  key: string;
  text: string;
}

export enum WorkspaceSlot {
  TwentyMin = 20,
  ThirtyMin = 30,
  SixtyMin = 60
}
