
import { defineComponent, PropType } from 'vue';
import {
  DmModal,
  DmIconButton,
  DmButton,
  DmIcon,
  DmSpinner
} from '@dobrymechanik/vue-ui';
import { locales } from '@/locales';
import { RouteName } from '@/router/models';
import {
  AppZiloPartsModal
} from '@/components';

export default defineComponent({
  name: 'LayoutAppHeaderMobileMenu',
  components: {
    DmModal,
    DmIconButton,
    DmButton,
    DmIcon,
    DmSpinner,
    AppZiloPartsModal
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    isReadyForPickupAmountLoaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    readyForPickupAmount: {
      type: Number as PropType<number>,
      required: true
    }
  },
  emits: [
    'update:modelValue',
    'go-to-calendar-page',
    'go-to-pickup-page',
    'go-to-clients-page',
    'go-to-stats-page',
    'go-to-settings-page',
    'go-to-bok-page',
    'set-locale',
    'logout',
    'go-to-workspaces-page',
    'go-to-quotes',
    'go-to-commission'
  ],
  data () {
    return {
      languages: locales,
      calendarRoute: {
        name: RouteName.SCHEDULER
      },
      clientsRoute: {
        name: RouteName.CLIENTS
      },
      pickupRoute: {
        name: RouteName.PICKUP
      },
      ziloPartsIframe: false as boolean
    };
  },
  computed: {
    appVersion () {
      return process.env.PACKAGE_VERSION;
    }
  },
  methods: {
    close (): void {
      this.$emit('update:modelValue', false);
    },
    setLocale (locale: string): void {
      this.$emit('set-locale', locale);
    },
    goToCalendarPage (): void {
      this.close();
      this.$emit('go-to-calendar-page');
    },
    goToPickupPage (): void {
      this.close();
      this.$emit('go-to-pickup-page');
    },
    goToClientsPage (): void {
      this.close();
      this.$emit('go-to-clients-page');
    },
    goToStatsPage (): void {
      this.close();
      this.$emit('go-to-stats-page');
    },
    goToSettingsPage (): void {
      this.close();
      this.$emit('go-to-settings-page');
    },
    goToWorkspacesPage (): void {
      this.close();
      this.$emit('go-to-workspaces-page');
    },
    goToBokPage (): void {
      this.close();
      this.$emit('go-to-bok-page');
    },
    goToQuotes (): void {
      this.close();
      this.$emit('go-to-quotes');
    },
    goToCommissionPage (): void {
      this.close();
      this.$emit('go-to-commission');
    },
    logout (): void {
      this.$emit('logout');
    }
  }
});
