import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmToolbarMenuItem = _resolveComponent("DmToolbarMenuItem")
  const _component_DmSpinner = _resolveComponent("DmSpinner")
  const _component_DmToolbarMenu = _resolveComponent("DmToolbarMenu")
  const _component_AppZiloPartsModal = _resolveComponent("AppZiloPartsModal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_DmToolbarMenu, {
      class: _ctx.$bem({}),
      "data-test": "menu"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DmToolbarMenuItem, {
          class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.calendarRoute.name } }),
          "data-test": "menu-item-calendar",
          onClick: _ctx.goToCalendarPage
        }, {
          default: _withCtx(() => [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, _toDisplayString(_ctx.$t('app.header.calendar')), 3)
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_component_DmToolbarMenuItem, {
          class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.pickupRoute.name } }),
          "data-test": "menu-item-pickup",
          onClick: _ctx.goToPickupPage
        }, {
          default: _withCtx(() => [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('app.header.forPickup')) + " ", 1),
              _createVNode("span", {
                class: _ctx.$bem({ e: 'item-badge' })
              }, [
                (_ctx.isReadyForPickupAmountLoaded)
                  ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.readyForPickupAmount), 1))
                  : (_openBlock(), _createBlock(_component_DmSpinner, {
                      key: 1,
                      color: "primary",
                      size: "sm"
                    }))
              ], 2)
            ], 2)
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_component_DmToolbarMenuItem, {
          class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.clientsRoute.name } }),
          "data-test": "menu-item-clients",
          onClick: _ctx.goToClientsPage
        }, {
          default: _withCtx(() => [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, _toDisplayString(_ctx.$t('app.header.clients')), 3)
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_component_DmToolbarMenuItem, {
          class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.commissionRoute.name } }),
          onClick: _ctx.goToComissionPage
        }, {
          default: _withCtx(() => [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, _toDisplayString(_ctx.$t('app.header.comission')), 3)
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_AppZiloPartsModal, {
      modelValue: _ctx.ziloPartsIframe,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.ziloPartsIframe = $event))
    }, null, 8, ["modelValue"])
  ]))
}