
import { defineComponent, PropType, inject } from 'vue';
import {
  DmModal,
  DmModalBody,
  DmCheckbox,
  DmLoadingBlend,
  DmButton
} from '@dobrymechanik/vue-ui';
import {
  GarageConsent
} from '@/api/models';
import { CookiesKey, LocalStorageKey } from '@/models/storage';
import { RouteName } from '@/router/models';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { URI_GARAGE_CONSENTS } from '@/api/endpoints';
import Markdown from 'vue3-markdown-it';

interface Checkbox extends GarageConsent {
  validated: boolean;
}

export default defineComponent({
  name: 'LayoutAppRegulations',
  components: {
    DmModal,
    DmModalBody,
    DmButton,
    DmCheckbox,
    DmLoadingBlend,
    Markdown
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    consents: {
      type: Array as PropType<GarageConsent[]>,
      required: true
    },
    consentSetId: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      checkboxes: [] as Checkbox[],
      loading: false as boolean,
      isFormChecked: false as boolean
    };
  },
  computed: {
    isModalShown: {
      get (): boolean {
        return this.modelValue;
      },
      set (value: boolean) {
        this.$emit('update:modelValue', value);
      }
    },
    isFormValid (): boolean {
      return this.checkboxes.filter(c => !c.accepted && c.required).length === 0;
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (val: boolean): void {
        if (val) {
          this.checkboxes = this.consents.map(c => ({
            ...c,
            validated: false
          }));
        }
      }
    }
  },
  methods: {
    getError (checkbox: Checkbox): string {
      const isError = checkbox.required && !checkbox.accepted && (checkbox.validated || this.isFormChecked);
      return isError ? this.$t('app.regulations.consentRequired') : '';
    },
    logout (): void {
      let domain = window.location.hostname;
      const sDomain = domain.split('.');

      if (sDomain.length === 3) {
        sDomain.shift();
        domain = sDomain.join('.');
      }
      this.$cookies.remove(CookiesKey.ACCESS_TOKEN, undefined, domain);
      localStorage.setItem(LocalStorageKey.IS_LOGGED, '0');
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.LOGOUT,
        action: 'Logout button clicked'
      });
      this.$router.push({
        name: RouteName.LOGIN
      });
    },
    async submit (): Promise<void> {
      this.isFormChecked = true;
      if (this.isFormValid) {
        this.loading = true;
        try {
          const data = {
            consentSetId: this.consentSetId,
            consents: this.checkboxes.filter(c => c.accepted).map(c => c.id)
          };
          this.$http.post(URI_GARAGE_CONSENTS, data);
          this.isModalShown = false;
        } catch (e) {

        }
        this.loading = false;
      }
    }
  }
});
