
import { defineComponent, inject } from 'vue';
import {
  DmToolbar,
  DmToolbarLogo,
  DmIconButton
} from '@dobrymechanik/vue-ui';
import LayoutAppHeaderMenu from './Menu';
import LayoutAppHeaderControls from './Controls';
import LayoutAppHeaderMobileMenu from './MobileMenu';
import { mapState, mapActions } from 'vuex';
import { FETCH_READY_FOR_PICKUP_AMOUNT } from '@/store/actions';
import { CookiesKey, LocalStorageKey } from '@/models/storage';
import { RouteName } from '@/router/models';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { locales } from '@/locales';
import BOK from './BOK/index.vue';

export default defineComponent({
  name: 'LayoutAppHeader',
  components: {
    DmToolbar,
    DmToolbarLogo,
    DmIconButton,
    LayoutAppHeaderMenu,
    LayoutAppHeaderControls,
    LayoutAppHeaderMobileMenu,
    BOK
  },
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      homeRoute: {
        name: RouteName.SCHEDULER
      },
      isMenuExpandedOnMobile: false as boolean,
      languages: locales
    };
  },
  computed: {
    ...mapState('garage', ['logo']),
    ...mapState(['readyForPickupAmount', 'isReadyForPickupAmountLoaded'])
  },
  created () {
    this.fetchReadyForPickupAmount(this.$http);
  },
  methods: {
    ...mapActions({
      fetchReadyForPickupAmount: FETCH_READY_FOR_PICKUP_AMOUNT
    }),
    closeMenuOnMobile (): void {
      this.isMenuExpandedOnMobile = false;
    },
    toggleMenuOnMobile (): void {
      this.isMenuExpandedOnMobile = !this.isMenuExpandedOnMobile;
    },
    setLocale (locale: string): void {
      this.$i18n.locale = locale;
      this.$ui.locale = locale;
      localStorage.setItem(LocalStorageKey.LOCALE, locale);
    },
    goToCalendarPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.CALENDAR,
        action: 'Calendar list menu item clicked'
      });
      this.$router.push({
        name: RouteName.SCHEDULER
      });
    },
    goToPickupPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.CALENDAR,
        action: 'Calendar list menu item clicked'
      });
      this.$router.push({
        name: RouteName.PICKUP
      });
    },
    goToClientsPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.CLIENTS,
        action: 'Client list menu item clicked'
      });
      this.$router.push({
        name: RouteName.CLIENTS
      });
    },
    goToStatsPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.STATS,
        action: 'Stats page menu item clicked'
      });
      this.$router.push({
        name: RouteName.STATS
      });
    },
    goToSettingsPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.SETTINGS,
        action: 'Settings page menu item clicked'
      });
      this.$router.push({
        name: RouteName.SETTINGS
      });
    },
    goToWorkspacesPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.SETTINGS,
        action: 'Workspaces page menu item clicked'
      });
      this.$router.push({
        name: RouteName.WORKSPACES
      });
    },
    goToBokPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.SETTINGS,
        action: 'BOK page menu item clicked'
      });
      this.$router.push({
        name: RouteName.CONTACT
      });
    },
    goToComissionPage (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.SETTINGS,
        action: 'Comission menu item clicked'
      });
      this.$router.push({
        name: RouteName.COMISSION
      });
    },
    logout (): void {
      let domain = window.location.hostname;
      const sDomain = domain.split('.');

      if (sDomain.length === 3) {
        sDomain.shift();
        domain = sDomain.join('.');
      }
      this.$cookies.remove(CookiesKey.ACCESS_TOKEN, undefined, domain);
      localStorage.setItem(LocalStorageKey.IS_LOGGED, '0');
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.LOGOUT,
        action: 'Logout button clicked'
      });
      this.$router.push({
        name: RouteName.LOGIN
      });
    }
  }
});
