
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppTextHighlighter',
  props: {
    text: {
      type: String as PropType<string | null>,
      default: ''
    },
    pattern: {
      type: String as PropType<string | null>,
      default: ''
    }
  },
  computed: {
    highlightedText (): string | null {
      if (this.pattern && this.text) {
        const spanStart = `<span class="${this.$bem({ e: 'chunk' }).join(' ')}" >`;
        const spanEnd = '</span>';
        const regex = new RegExp(this.pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');
        return this.text.replace(regex, spanStart + '$&' + spanEnd);
      }
      return this.text;
    }
  }
});
