import { ActionTree } from 'vuex';
import { RootState } from './types';
import { SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED, SET_READY_FOR_PICKUP_AMOUNT } from './mutations';
import { URI_READY_FOR_PICKUP_COUNTER } from '@/api/endpoints';

export const FETCH_READY_FOR_PICKUP_AMOUNT = 'FETCH_READY_FOR_PICKUP_AMOUNT';

export const actions: ActionTree<RootState, RootState> = {
  [FETCH_READY_FOR_PICKUP_AMOUNT]: async ({ commit }, $http) => {
    commit(SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED, false);
    const count = (await $http.get(URI_READY_FOR_PICKUP_COUNTER)).data.count;
    commit(SET_READY_FOR_PICKUP_AMOUNT, count);
    commit(SET_IS_READY_FOR_PICKUP_AMOUNT_LOADED, true);
  }
};
