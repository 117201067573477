import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmModalFooter = _resolveComponent("DmModalFooter")

  return (_openBlock(), _createBlock(_component_DmModalFooter, {
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode("div", {
        class: _ctx.$bem({e: 'buttons'})
      }, [
        _renderSlot(_ctx.$slots, "buttons")
      ], 2)
    ]),
    _: 3
  }, 8, ["class"]))
}