
import { defineComponent } from 'vue';
import {
  DmModal
} from '@dobrymechanik/vue-ui';
import {
  AppModalHeader
} from '@/components';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'AppZiloPartsModal',
  components: {
    DmModal,
    AppModalHeader
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    vin: {
      type: String,
      default: ''
    },
    tecDocBrandId: {
      type: [String, Number],
      default: ''
    },
    tecDocModelId: {
      type: [String, Number],
      default: ''
    },
    tecDocTypeId: {
      type: [String, Number],
      default: ''
    },
    initialCategory: {
      type: String,
      default: ''
    },
    eventId: {
      type: String,
      default: ''
    },
    basket: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  computed: {
    ...mapState('garage', {
      adviser: 'adviser'
    }),
    iFrameURL (): any {
      if (this.basket) {
        const url = new URL(process.env.VUE_APP_ZILO_PARTS + 'dashboard/shop/shopping-basket');
        return url;
      }
      const url = new URL(process.env.VUE_APP_ZILO_PARTS);

      if (this.vin) {
        url.searchParams.set('vin', this.vin);
      } else if (this.tecDocBrandId && this.tecDocModelId && this.tecDocTypeId) {
        url.searchParams.set('brand', this.tecDocBrandId.toString());
        url.searchParams.set('model', this.tecDocModelId.toString());
        url.searchParams.set('type', this.tecDocTypeId.toString());
      }

      if (this.initialCategory && (this.vin || this.tecDocTypeId)) {
        url.searchParams.set('categoryId', this.initialCategory);
      }

      if (this.eventId && this.adviser) {
        url.searchParams.set('eventId', this.eventId);
      }

      return url;
    }
  },
  methods: {
    updateModelValue (value: boolean) {
      this.$emit('update:model-value', value);
    }
  }
});
