export enum Locale {
  PL = 'pl',
  EN = 'en'
}

export type LocaleDictionary = {
  common: {
    back: string;
    cancel: string;
    confirm: string;
    save: string;
    restore: string;
    copyToClipboard: string;
    attention: string;
    tryAgain: string;
    search: string;
    select: string;
    checkout: string;
    edit: string;
    details: string;
    yes: string;
    no: string;
    notKnown: string;
    download: string;
    next: string;
  };
  notifications: {
    somethingWentWrong: string;
    changeSuccess: string;
    copiedToClipboard: string;
  };
  changelog: {
    tip: {
      title: string;
      description: string;
      goToChangelog: string;
    };
    title: string;
  };
  home: {
    goToApp: string;
    login: string;
  };
  login: {
    goToHomePage: string;
    title: string;
    email: string;
    password: string;
    submit: string;
    noEmail: string;
    wrongEmail: string;
    noPassword: string;
    loginFailed: string;
    passwordHint: string;
  };
  resetPassword: {
    title: string;
    description: string;
  };
  setPassword: {
    title: string;
    error: string;
  };
  bok: {
    contact: string;
  };
  app: {
    loadingGarageDetails: string;
    pageNotFound: string;
    header: {
      calendar: string;
      clients: string;
      forPickup: string;
      statistics: string;
      settings: string;
      workspaces: string;
      logout: string;
      services: string;
      bok: string;
      parts: string;
      quote: string;
      comission: string;
    };
    regulations: {
      title: string;
      logout: string;
      save: string;
      consentRequired: string;
    };
  };
  pickup: {
    noItems: string;
    pickup: string;
    confirmationDescription: string;
    failure: string;
  };
  settings: {
    title: string;
    garage: {
      title: string;
      name: string;
      email: string;
      phone: string;
      address: string;
      zipCode: string;
      city: string;
      validation: {
        name: string;
        email: string;
        phone: string;
        address: string;
        zipCode: string;
        city: string;
      };
    };
    company: {
      title: string;
      name: string;
      email: string;
      phone: string;
      nip: string;
      address: string;
      zipCode: string;
      city: string;
      validation: {
        name: string;
        email: string;
        phone: string;
        nip: string;
        address: string;
        zipCode: string;
        city: string;
      };
    };
    logo: {
      title: string;
      edit: string;
      uploadTitle: string;
      preview: string;
      change: string;
    };
    workspaces: {
      title: string;
      visibility: string;
      businessHours: string;
      workspace: string;
      new: string;
      addFailed: string;
      editFailed: string;
      statusChangeFailed: string;
      showInactive: string;
      scheduledWorkspace: string;
      quantityWorkspace: string;
      visibleOutside: string;
      visibleOutsideTires: string;
      invisibleOutside: string;
      add: string;
      edit: string;
    };
    files: {
      title: string;
      gdpr: string;
    };
  };
  statistics: {
    controls: {
      weeks: string;
      months: string;
      quarters: string;
      years: string;
      weekFrom: string;
      compareWith: string;
    };
    boxes: {
      allRepairs: string;
      repairsValue: string;
      averageRepairValue: string;
      numberOfCancellations: string;
      numberOfClients: string;
      numberOfNewClients: string;
      numberOfReturningClients: string;
    };
    chart: {
      analyzedPeriod: string;
      comparativePeriod: string;
    };
    topRepairs: {
      title: string;
    };
    repairsSources: {
      title: string;
    };
  };
  clients: {
    results: string;
    add: string;
    name: string;
    phone: string;
    email: string;
    lastRepair: string;
    alreadyExists: string;
    sectionTitle: string;
    client: {
      title: string;
    };
    cars: {
      title: string;
      noItems: string;
    };
    repairs: {
      title: string;
      allCars: string;
      showAll: string;
      noItems: string;
      add: string;
      new: string;
    };
    fields: {
      name: string;
      email: string;
      phone: string;
      marketingConsent: string;
    };
    rules: {
      name: string;
      email: string;
      phone: string;
      marketingConsent: string;
    };
    hints: {
      phone: string;
    };
    new: {
      title: string;
      submit: string;
      addSuccess: string;
      addError: string;
    };
    edit: {
      title: string;
      submit: string;
      success: string;
      error: string;
    };
  };
  cars: {
    add: string;
    alreadyExists: string;
    popular: string;
    sectionTitle: string;
    new: {
      title: string;
      submit: string;
      addSuccess: string;
      addError: string;
    };
    edit: {
      title: string;
      submit: string;
      success: string;
      error: string;
    };
    rules: {
      brand: string;
      model: string;
      licensePlateNumber: string;
      vin: string;
    };
    fields: {
      car: string;
      brand: string;
      model: string;
      type: string;
      licensePlateNumber: string;
      vin: string;
      year: string;
      engine: string;
    };
  };
  repair: {
    new: string;
    loading: string;
    status: {
      title: string;
      fillData: string;
      new: string;
      admitted: string;
      repaired: string;
      completed: string;
    };
    cancel: string;
    edit: string;
    confirmation: string;
    sectionTitle: string;
    fields: {
      reason: string;
      price: string;
      notes: string;
    };
    rules: {
      reason: string;
      price: string;
      year: string;
    };
    selectCar: string;
    carSelected: string;
    newCar: string;
    saveNewData: string;
    editError: string;
    clientExists: string;
    statusChange: {
      confirmAdmission: string;
      confirmRepair: string;
      sendAdmissionSms: string;
      sendRepairSms: string;
      sendPrice: string;
      priceRule: string;
      error: string;
    };
    cancellation: {
      sendSms: string;
      cancel: string;
      cancellationSuccess: string;
    };
    chooseNewRepairDate: string;
  };
  calendar: {
    pending: {
      title: string;
      add: string;
      noItems: string;
      showPendingItems: string;
      hidePendingItems: string;
    };
    loading: string;
    eventsLoading: string;
    event: {
      durationChangeError: string;
      moveError: string;
      moveToPendingError: string;
      moveFromPendingError: string;
      sourceDM: string;
      reorderError: string;
    };
    moveToCalendar: {
      confirmChange: string;
      sendSms: string;
    };
    buttons: {
      prev: string;
      next: string;
      today: string;
      month: string;
      week: string;
      day: string;
      list: string;
    };
    contact: {
      header: string;
      content: string;
    };
  };
  newRepair: {
    new: string;
    returning: string;
    showMore: string;
    showLess: string;
    add: string;
    addError: string;
    browser: {
      hint: string;
      example: string;
      loading: string;
      noMatchingItems: string;
      addClient: string;
      newCar: string;
      addRepair: string;
    };
    repairHour: string;
  };
  days: {
    sunday: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
  };
  daysShortcuts: {
    sunday: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
  };
  months: {
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;
  };
  quote: {
    createPDF: string;
    pdfName: string;
    clientData: {
      header: string;
      phone: string;
      name: string;
      email: string;
    };
    carData: {
      header: string;
      name: string;
      licencePlate: string;
    };
    parts: {
      header: string;
      name: string;
      quantity: string;
      manufacturer: string;
      priceNet: string;
      priceGross: string;
    };
    services: {
      header: string;
      name: string;
      priceNet: string;
      priceGross: string;
    };
    notes: {
      header: string;
      description: string;
    };
    internal_notes: {
      header: string;
      description: string;
    };
    notifications: {
      somethingWentWrong: string;
    };
  };
};
