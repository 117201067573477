import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIcon = _resolveComponent("DmIcon")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmSpinner = _resolveComponent("DmSpinner")
  const _component_DmModal = _resolveComponent("DmModal")
  const _component_AppZiloPartsModal = _resolveComponent("AppZiloPartsModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DmModal, {
      "model-value": _ctx.modelValue,
      position: "fullscreen"
    }, {
      default: _withCtx(() => [
        _createVNode("div", {
          class: _ctx.$bem({ e: 'header' })
        }, [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'languages' })
          }, [
            _createVNode(_component_DmIcon, {
              name: "globe",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
              return (_openBlock(), _createBlock(_component_DmButton, {
                key: language.key,
                rounded: "",
                hoverable: "",
                dark: _ctx.$i18n.locale === language.key,
                color: _ctx.$i18n.locale === language.key ? 'primary' : 'default',
                class: _ctx.$bem({ e: 'language' }),
                onClick: ($event: any) => (_ctx.setLocale(language.key))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(language.text), 1)
                ]),
                _: 2
              }, 1032, ["dark", "color", "class", "onClick"]))
            }), 128))
          ], 2),
          _createVNode(_component_DmIconButton, {
            color: "primary",
            plain: "",
            rounded: "",
            bordered: "",
            hoverable: "",
            icon: "close",
            onClick: _ctx.close
          }, null, 8, ["onClick"])
        ], 2),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'body' })
        }, [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.calendarRoute.name } }),
            "data-test": "menu-item-calendar",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToCalendarPage && _ctx.goToCalendarPage(...args)))
          }, [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, _toDisplayString(_ctx.$t('app.header.calendar')), 3)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.pickupRoute.name } }),
            "data-test": "menu-item-pickup",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToPickupPage && _ctx.goToPickupPage(...args)))
          }, [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('app.header.forPickup')) + " ", 1),
              _createVNode("span", {
                class: _ctx.$bem({ e: 'item-badge' })
              }, [
                (_ctx.isReadyForPickupAmountLoaded)
                  ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.readyForPickupAmount), 1))
                  : (_openBlock(), _createBlock(_component_DmSpinner, {
                      key: 1,
                      color: "primary",
                      size: "sm"
                    }))
              ], 2)
            ], 2)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'item', m: { active: _ctx.$route.name === _ctx.clientsRoute.name } }),
            "data-test": "menu-item-clients",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToClientsPage && _ctx.goToClientsPage(...args)))
          }, [
            _createVNode("div", {
              class: _ctx.$bem({ e: 'item-content' })
            }, _toDisplayString(_ctx.$t('app.header.clients')), 3)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-stats",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToStatsPage && _ctx.goToStatsPage(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "stats",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.statistics')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-settings",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToSettingsPage && _ctx.goToSettingsPage(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "gear",
              type: "fa",
              prefix: "fa-",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.settings')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-workspaces",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.goToWorkspacesPage && _ctx.goToWorkspacesPage(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "wifi",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.workspaces')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-bok",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.goToBokPage && _ctx.goToBokPage(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "phone",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.bok')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-bok",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.goToCommissionPage && _ctx.goToCommissionPage(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "download",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.comission')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' }),
            "data-test": "menu-item-logout",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
          }, [
            _createVNode(_component_DmIcon, {
              name: "logout",
              class: _ctx.$bem({ e: 'icon' })
            }, null, 8, ["class"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('app.header.logout')), 1)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'secondary-item' })
          }, " v. " + _toDisplayString(_ctx.appVersion), 3)
        ], 2)
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_AppZiloPartsModal, {
      modelValue: _ctx.ziloPartsIframe,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.ziloPartsIframe = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}