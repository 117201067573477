
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppCarForm,
  AppModalButton,
  AppModalFooter,
  AppModalBody
} from '@/components';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { URI_CARS } from '@/api/endpoints';
import { CarFormData } from '@/models/cars';
import {
  DmButton,
  DmAutocomplete
} from '@dobrymechanik/vue-ui';
import { getManuDateRange } from '@/helpers/functions';
import { mapActions } from 'vuex';

const initialValues: CarFormData = {
  brand: {
    id: null,
    name: null
  },
  model: {
    id: null,
    name: null
  },
  licensePlateNumber: null,
  vin: null,
  year: null
};

enum Mode {
  Default,
  TecDoc
}

export default defineComponent({
  name: 'AppCarCreateModal',
  components: {
    AppModal,
    AppModalHeader,
    AppCarForm,
    AppModalButton,
    AppModalFooter,
    AppModalBody,
    DmAutocomplete
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    clientId: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: [
    'close',
    'update:modelValue',
    'create'
  ],
  data: function () {
    return {
      loading: false as boolean,
      initialValues,
      mode: Mode.Default as unknown | string,
      carTypeValue: null as null | any,
      carBrandValue: null as null | any,
      carModelValue: null as null | any,
      brands: [],
      models: [],
      types: [],
      carBrandLoading: false,
      carModelLoading: false,
      carTypeLoading: false
    };
  },
  mounted () {
    this.GETbrands();
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.initialValues = initialValues;
      this.$emit('close');
      this.$emit('update:modelValue', false);
    },
    async submit (values: any): Promise<void> {
      this.loading = true;
      const data = jsonToURLSearchParams({
        ...values,
        clientId: this.clientId,
        tecDocBrandId: this.carBrandValue?.key,
        tecDocModelId: this.carModelValue?.key,
        tecDocTypeId: this.carTypeValue?.key,
        tecDocBrandName: this.carBrandValue?.text,
        tecDocModelName: this.carModelValue?.text,
        tecDocTypeName: this.carTypeValue?.text
      });
      try {
        await this.$http.post(URI_CARS, data);
        this.createNotification({
          message: this.$t('cars.new.addSuccess'),
          type: 'success'
        });
        this.$emit('create');
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('cars.new.addError'),
          type: 'error'
        });
      }
      this.loading = false;
    },
    convertTypeName (type: any) {
      const name = type.name;
      const power = `${type.power.kw.from}kW / ${type.power.hp.from}KM`;
      const engines = type.engine.codes.join(', ');
      return `${name} (${power}) (${engines})`;
    },
    async GETbrands () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + 'api/brands?page=1';
      const { data } = await this.$http.get<any>(endpoint);

      this.brands = data['hydra:member'].map((el: any) => {
        return {
          text: el.name,
          key: el.id
        };
      });
    },
    async GETmodels () {
      if (!this.carBrandValue.key) return;
      this.carModelLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/brands/${this.carBrandValue.key}/models`;
        const { data } = await this.$http.get<any>(endpoint);

        this.models = data['hydra:member'].map((el: any) => {
          const text = `${el.name} (${getManuDateRange(el.production.from, el.production.to)})`;
          return {
            text: text,
            key: el.id
          };
        });
      } catch {

      } finally {
        this.carModelLoading = false;
      }
    },
    async GETtypes () {
      if (!this.carBrandValue.key || !this.carModelValue.key) return;
      this.carTypeLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/vehicles?brand=${this.carBrandValue.key}&model=${this.carModelValue.key}`;
        const { data } = await this.$http.get<any>(endpoint);

        this.types = data['hydra:member'].map((el: any) => {
          return {
            text: this.convertTypeName(el),
            key: el.id
          };
        });
      } catch {

      } finally {
        this.carTypeLoading = false;
      }
    },
    onBrandUpdate (value: any) {
      this.carBrandValue = value;

      if (value?.key) {
        this.GETmodels();
      } else {
        this.models = [];
        this.types = [];
        this.carModelValue = null;
        this.carTypeValue = null;
      }
    },
    onModelUpdate (value: any) {
      this.carModelValue = value;

      if (value?.key) {
        this.GETtypes();
      } else {
        this.types = [];
        this.carTypeValue = null;
      }
    },
    onTypeUpdate (value: any) {
      this.carTypeValue = value;
    },
    clearTecDocDsc () {
      if (!this.carTypeValue?.key) {
        this.carBrandValue = null;
        this.carModelValue = null;
        this.carTypeValue = null;
      }
      this.mode = 0;
    }
  }
});
