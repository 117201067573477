
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter
} from '@/components';
import {
  DmInput,
  DmSwitch,
  DmPhoneInput,
  DmTextarea
} from '@dobrymechanik/vue-ui';
import {
  Form,
  Field
} from 'vee-validate';
import { CalendarEventDetails } from '@/api/models';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'SendQuoteModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    DmSwitch,
    DmForm: Form,
    DmField: Field,
    DmPhoneInput,
    DmInput,
    DmTextarea
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    details: {
      type: Object as PropType<CalendarEventDetails>,
      default: null
    }
  },
  emits: ['confirm', 'close'],
  data () {
    return {
      service: '',
      isPhoneValid: true as boolean,
      isReadyToSubmit: true as boolean,
      sendEmail: true as boolean
    };
  },
  computed: {
    ...mapState('garage', {
      garageName: 'name'
    }),
    validationSchema (): any {
      return {
        name: (v: string) => /\s*([A-zÀ-ž]{2,}\s){1,}([A-zÀ-ž]{2,})\s*/.test(v) || this.$t('clients.rules.name'),
        message: (v: string) => /.{15,}/.test(v) || 'Wpisz co najmniej 15 znaków',
        title: (v: string) => /.{10,}/.test(v) || 'Wpisz co najmniej 10 znaków',
        phone: () => this.isPhoneValid || this.$t('clients.rules.phone'),
        email: (v: string) => !this.sendEmail || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || this.$t('clients.rules.email')
      };
    },
    initialValues (): any {
      return {
        name: this.details.name || '',
        phone: this.details.phone || '',
        email: this.details.email || '',
        sendEmail: true,
        title: this.details.brand && this.details.model ? `Wycena zlecenia dla samochodu ${this.details.brand} ${this.details.model}` : 'Wycena zlecenia',
        message: this.details.brand && this.details.model ? `Warsztat ${this.garageName} przygotował wycenę naprawy samochodu ${this.details.brand} ${this.details.model}. Zobacz szczegóły i potwierdź zakres zlecenia.`
          : `Warsztat ${this.garageName} przygotował wycenę naprawy. Zobacz szczegóły i potwierdź zakres zlecenia.`
      };
    }
  },
  methods: {
    close (): void {
      this.$emit('close');
    },
    submit (fields: any): void {
      const payload = {
        notifications: {
          sms: true,
          email: fields.sendEmail
        },
        client: {
          name: fields.name,
          email: fields.email,
          phone: fields.phone
        },
        notes: {
          title: fields.title,
          content: fields.message
        }
      };

      this.$emit('confirm', payload);
    }
  }
});
