
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppSpelledWord',
  props: {
    text: {
      type: String as PropType<string | null>,
      default: ''
    },
    highlightPattern: {
      type: String as PropType<string | null>,
      default: ''
    }
  },
  computed: {
    characters (): any[] {
      const chars = (this.text || '').split('');
      const patternLength = this.highlightPattern?.length || 0;
      return chars.map((char, i) => {
        let isHighlighted = false;
        if (this.highlightPattern) {
          const charArea = this.text?.substring(i - patternLength + 1, i + patternLength);
          isHighlighted = charArea?.toLowerCase().includes(this.highlightPattern.toLowerCase()) || false;
        }

        return {
          character: char,
          isDigit: '1234567890'.includes(char),
          isHighlighted
        };
      });
    }
  }
});
