import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_DmModal = _resolveComponent("DmModal")

  return (_openBlock(), _createBlock(_component_DmModal, {
    "model-value": _ctx.modelValue,
    position: "fullscreen",
    "close-on-esc": "",
    "onUpdate:modelValue": _ctx.updateModelValue
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        class: "app-modal-header--zilo-parts",
        "with-close-button": "",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateModelValue(false)))
      }),
      _createVNode("iframe", {
        id: "ziloPartsFrame",
        src: _ctx.iFrameURL,
        title: "Zilo Parts",
        class: _ctx.$bem({ e: 'iframe' })
      }, null, 10, ["src"])
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}