import dayjs from 'dayjs';

export function setBaseDate (strDate?: string) {
  return dayjs(strDate).hour(0).minute(0).second(0).millisecond(0);
}

export function isToday (dateStr: string) {
  const format = 'DD-MM-YYYY';
  return dayjs(dateStr).format(format) === dayjs().format(format);
}
