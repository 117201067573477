import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_QuoteForm = _resolveComponent("QuoteForm")
  const _component_DmModal = _resolveComponent("DmModal")

  return (_openBlock(), _createBlock(_component_DmModal, {
    "model-value": _ctx.modelValue,
    "close-on-esc": "",
    loading: _ctx.loading,
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        title: "Wycena wizyty",
        "with-close-button": "",
        onClose: _ctx.close
      }, null, 8, ["onClose"]),
      _createVNode(_component_QuoteForm, {
        details: _ctx.details,
        "quote-id": _ctx.quoteId,
        loading: _ctx.loading,
        "view-mode": _ctx.viewMode,
        onSendQuote: _ctx.sendQuote,
        onSaveQuote: _ctx.saveQuote
      }, null, 8, ["details", "quote-id", "loading", "view-mode", "onSendQuote", "onSaveQuote"])
    ]),
    _: 1
  }, 8, ["model-value", "loading", "class"]))
}