import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("main", {
    class: _ctx.$bem({})
  }, [
    _createVNode("div", {
      class: _ctx.$bem({e: 'languages'})
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createBlock(_component_DmButton, {
          key: language.key,
          hoverable: "",
          dark: _ctx.$i18n.locale === language.key,
          color: _ctx.$i18n.locale === language.key ? 'primary' : 'default',
          class: _ctx.$bem({e: 'language'}),
          onClick: ($event: any) => (_ctx.setLocale(language.key))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(language.text), 1)
          ]),
          _: 2
        }, 1032, ["dark", "color", "class", "onClick"]))
      }), 128))
    ], 2),
    _createVNode(_component_router_view, {
      class: _ctx.$bem({e: 'route'})
    }, null, 8, ["class"])
  ], 2))
}