
import {
  Locale
} from './models';
export * from './en';
export * from './pl';
export {
  Locale
};

export const locales = [
  {
    key: Locale.PL,
    text: 'Polski'
  },
  {
    key: Locale.EN,
    text: 'English'
  }
];
