export const shortcuts = [
  {
    categoryId: '6e940af7-7047-4e95-b630-11ae0b4d3fcb',
    text: 'Amortyzatory',
    icon: 'shock-absorbers'
  },
  {
    categoryId: '5c0594a6-dbef-4f72-97e4-704817daf09a',
    text: 'Filtry kabinowe',
    icon: 'cabin-filter'
  },
  {
    categoryId: '02ee174d-1e32-4145-9dd8-f2f559bf4aab',
    text: 'Filtry oleju',
    icon: 'oil-filter'
  },
  {
    categoryId: 'e9fbe49f-b03b-456d-8139-18f44d49dc8d',
    text: 'Filtry powietrza',
    icon: 'air-filter'
  },
  {
    categoryId: '8c9c9ceb-c4b7-4b51-8626-b831fa6fefd5',
    text: 'Klocki hamulcowe',
    icon: 'brake-pads'
  },
  {
    categoryId: 'c83d3a95-12ac-4f37-8203-75651628f37f',
    text: 'Oleje silnikowe',
    icon: 'oils'
  },
  {
    categoryId: '63e75a36-7223-4028-b973-19b3bfae911e',
    text: 'Rozrządy kompletne',
    icon: 'timming'
  },
  {
    categoryId: 'ba4ae15d-1f92-4e3e-bf48-c0e64f94b31f',
    text: 'Sprzęgła kompletne',
    icon: 'clutch'
  },
  {
    categoryId: '7e87f246-8c83-493e-aecd-9dab8d8d85e6',
    text: 'Tarcze hamulcowe',
    icon: 'brake-disk'
  }
];
