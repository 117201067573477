import { ActionTree } from 'vuex';
import { NotificationsState } from './types';
import {
  INCREMENT_LAST_NOTIFITATION_ID,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from './mutations';
import { RootState } from '@/store/types';
import { Notification, NotificationConstructor } from '@/models/notifications';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';

export const actions: ActionTree<NotificationsState, RootState> = {
  [CREATE_NOTIFICATION] ({ commit, state }, { message, type, isPermanent, time }: NotificationConstructor): Notification {
    commit(INCREMENT_LAST_NOTIFITATION_ID);
    const notification: Notification = {
      id: state.lastNotificationId,
      message,
      type: type || null,
      isPermanent: isPermanent || false
    };
    commit(ADD_NOTIFICATION, notification);
    if (!isPermanent) {
      setTimeout(() => {
        commit(REMOVE_NOTIFICATION, notification.id);
      }, time || 5000);
    }
    return notification;
  }
};
