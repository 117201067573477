import { RouteRecordRaw } from 'vue-router';
import LayoutApp from '@/layouts/App';
import LayoutDefault from '@/layouts/Default';
import { RouteName } from './models';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LayoutDefault,
    children: [
      {
        path: '',
        name: RouteName.HOME,
        component: () => import(/* webpackChunkName: "home" */ '../views/Home/index.vue')
      },
      {
        path: 'login',
        name: RouteName.LOGIN,
        component: () => import(/* webpackChunkName: "login" */ '../views/Login/index.vue')
      },
      {
        path: 'forward/login',
        name: RouteName.FORWARD,
        component: () => import(/* webpackChunkName: "forward" */ '../views/Forward/index.vue')
      },
      {
        path: 'reset-password',
        name: RouteName.RESET_PASSWORD,
        component: () => import(/* webpackChunkName: "forward" */ '../views/ResetPassword/index.vue')
      },
      {
        path: 'reset-password/reset/:token',
        name: RouteName.SET_PASSWORD,
        component: () => import(/* webpackChunkName: "forward" */ '../views/SetNewPassword/index.vue')
      }
    ]
  },
  {
    path: '/app/',
    component: LayoutApp,
    children: [
      {
        path: '',
        redirect: () => '/app/calendar'
      },
      {
        path: 'calendar',
        name: RouteName.SCHEDULER,
        component: () => import(/* webpackChunkName: "scheduler" */ '../views/Scheduler/index.vue')
      },
      {
        path: 'clients',
        name: RouteName.CLIENTS,
        component: () => import(/* webpackChunkName: "clients" */ '../views/Clients/index.vue')
      },
      {
        path: 'ready-for-pickup',
        name: RouteName.PICKUP,
        component: () => import(/* webpackChunkName: "pickup" */ '../views/Pickup/index.vue')
      },
      {
        path: 'stats',
        name: RouteName.STATS,
        component: () => import(/* webpackChunkName: "stats" */ '../views/Stats/index.vue')
      },
      {
        path: 'settings',
        name: RouteName.SETTINGS,
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/index.vue')
      },
      {
        path: 'workspaces',
        name: RouteName.WORKSPACES,
        component: () => import(/* webpackChunkName: "workspaces" */ '../views/Workspaces/index.vue')
      },
      {
        path: 'changelog',
        name: RouteName.CHANGELOG,
        component: () => import(/* webpackChunkName: "changelog" */ '../views/Changelog/index.vue')
      },
      {
        path: 'contact',
        name: RouteName.CONTACT,
        component: () => import(/* webpackChunkName: "contact" */ '../views/BOK/index.vue')
      },
      {
        path: 'get-quote',
        name: RouteName.QUOTE,
        component: () => import(/* webpackChunkName: "quote" */ '../views/Quote/index.vue')
      },
      {
        path: 'comission',
        name: RouteName.COMISSION,
        component: () => import(/* webpackChunkName: "comission" */ '../views/Comission/index.vue')
      },
      {
        path: ':wrongPath(.*)',
        component: () => import(/* webpackChunkName: "app-not-found" */ '../views/NotFound/index.vue')
      }
    ]
  },
  // temporary fix for old garage routes
  {
    path: '/:garageHash([^\\/]{40})',
    redirect: () => '/app/calendar'
  },
  {
    path: '/:garageHash([^\\/]{40})/:pathEnd(.*)',
    redirect: () => '/app/calendar'
  },
  // wrong paths
  {
    path: '/:wrongPath(.*)',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound/index.vue')
  }
];

export default routes;
