
import { defineComponent, PropType } from 'vue';
import {
  DmToolbarMenu,
  DmToolbarMenuItem,
  DmSpinner
} from '@dobrymechanik/vue-ui';
import { RouteName } from '@/router/models';
import {
  AppZiloPartsModal
} from '@/components';

export default defineComponent({
  name: 'LayoutAppHeaderMenu',
  components: {
    DmToolbarMenu,
    DmToolbarMenuItem,
    DmSpinner,
    AppZiloPartsModal
  },
  props: {
    isReadyForPickupAmountLoaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    readyForPickupAmount: {
      type: Number as PropType<number>,
      required: true
    }
  },
  emits: [
    'go-to-calendar-page',
    'go-to-pickup-page',
    'go-to-clients-page',
    'go-to-comission-page'
  ],
  data () {
    return {
      calendarRoute: {
        name: RouteName.SCHEDULER
      },
      clientsRoute: {
        name: RouteName.CLIENTS
      },
      pickupRoute: {
        name: RouteName.PICKUP
      },
      quoteRoute: {
        name: RouteName.QUOTE
      },
      commissionRoute: {
        name: RouteName.COMISSION
      },
      ziloPartsIframe: false as boolean
    };
  },
  methods: {
    goToCalendarPage (): void {
      this.$emit('go-to-calendar-page');
    },
    goToPickupPage (): void {
      this.$emit('go-to-pickup-page');
    },
    goToClientsPage (): void {
      this.$emit('go-to-clients-page');
    },
    goToComissionPage (): void {
      this.$emit('go-to-comission-page');
    }
  }
});
