import { ActionTree } from 'vuex';
import { RepairState } from './types';
import {
  INIT_REPAIR_REASONS_LOADING,
  SET_REPAIR_REASONS,
  SET_REPAIR_REASONS_ERROR,
  INIT_CAR_BROWSER_LOADING,
  SET_CAR_BROWSER,
  SET_CAR_BROWSER_ERROR,
  INIT_CANCELLATION_REASONS_LOADING,
  SET_CANCELLATION_REASONS,
  SET_CANCELLATION_REASONS_ERROR
} from './mutations';
import { RootState } from '@/store/types';
import {
  URI_REPAIR_REASONS,
  URI_CAR_BROWSER,
  URI_CANCELLATION_REASONS
} from '@/api/endpoints';

export const FETCH_REPAIR_REASONS = 'FETCH_REPAIR_REASONS';
export const FETCH_CAR_BROWSER = 'FETCH_CAR_BROWSER';
export const FETCH_CANCELLATION_REASONS = 'FETCH_CANCELLATION_REASONS';

export const actions: ActionTree<RepairState, RootState> = {
  [FETCH_REPAIR_REASONS]: async ({ state, commit }, $http) => {
    if (!state.areRepairReasonsFetched) {
      commit(INIT_REPAIR_REASONS_LOADING);
      try {
        const repairReasons = (await $http.get(URI_REPAIR_REASONS)).data;
        commit(SET_REPAIR_REASONS, repairReasons);
      } catch (e) {
        commit(SET_REPAIR_REASONS_ERROR);
      }
    }
  },
  [FETCH_CAR_BROWSER]: async ({ state, commit }, $http) => {
    if (!state.isCarBrowserFetched) {
      commit(INIT_CAR_BROWSER_LOADING);
      try {
        const carBrowser = (await $http.get(URI_CAR_BROWSER)).data;
        commit(SET_CAR_BROWSER, carBrowser);
      } catch (e) {
        commit(SET_CAR_BROWSER_ERROR);
      }
    }
  },
  [FETCH_CANCELLATION_REASONS]: async ({ state, commit }, $http) => {
    if (!state.areCancellationReasonsFetched) {
      commit(INIT_CANCELLATION_REASONS_LOADING);
      try {
        const cancellationReasons = (await $http.get(URI_CANCELLATION_REASONS)).data;
        commit(SET_CANCELLATION_REASONS, cancellationReasons);
      } catch (e) {
        commit(SET_CANCELLATION_REASONS_ERROR);
      }
    }
  }
};
