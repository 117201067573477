
import { defineComponent, PropType } from 'vue';
import {
  DmModal,
  DmSpinner
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppModal',
  components: {
    DmModal,
    DmSpinner
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg' | null>,
      default: null,
      validator: (val: string) => {
        return !val || [
          'sm',
          'md',
          'lg'
        ].includes(val);
      }
    },
    position: {
      type: String as PropType<string>,
      default: 'right'
    },
    width: {
      type: Number as PropType<number | null>,
      default: null
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    persistent: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    close (): void {
      this.$emit('update:modelValue', false);
    }
  }
});
