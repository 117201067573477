import { App } from 'vue';

interface Filters {
  addLineBreaks: (T?: string | null) => string;
}

function install (Vue: App): void {
  Vue.config.globalProperties.$filters = {
    addLineBreaks: (text?: string | null): string => {
      return text?.replace(/(?:\r\n|\r|\n)/g, '<br>') || '';
    }
  };
}

function createFilters (): {install: (T: App) => void} {
  return {
    install: (app: App) => install(app)
  };
}

export const filters = createFilters();

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: Filters;
  }
}
