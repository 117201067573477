
import { defineComponent } from 'vue';
import { CookiesKey, LocalStorageKey } from '@/models/storage';
import { RouteName } from './router/models';
import { AppNotificator } from '@/components';
import { TrackedEvent, TrackedEventCategory, TrackedEventType } from '@/models/track-event';
import { delegate } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import jwtDecode from 'jwt-decode';

export default defineComponent({
  name: 'App',
  components: {
    AppNotificator
  },
  provide () {
    return {
      trackEvent: this.trackEvent
    };
  },
  created () {
    this.checkToken();
  },
  mounted () {
    this.initTooltips();
  },
  methods: {
    initTooltips (): void {
      delegate('body', {
        target: '[data-tippy-content]',
        onShow (instance) {
          const content = instance.reference.getAttribute('data-tippy-content') || '';
          instance.setContent(content);
        }
      });
    },
    trackEvent (event: TrackedEvent): void {
      const { attr, ...rawEvent } = event;

      this.$gtm.trackEvent({ ...rawEvent, ...attr });
    },
    async checkToken () {
      const accessToken = this.$cookies.get(CookiesKey.ACCESS_TOKEN);
      if (accessToken) {
        const decodedToken: any = jwtDecode(accessToken);
        const userEmail = decodedToken.username;
        const garageId = decodedToken.garage;

        this.$http.defaults.headers.Authorization = `Bearer ${accessToken}`;
        localStorage.setItem(LocalStorageKey.IS_LOGGED, '1');
        this.trackEvent({
          event: TrackedEventType.SUCCESS,
          category: TrackedEventCategory.LOGIN,
          action: 'Login successful',
          label: 'JWT from cookies restored',
          attr: {
            email: userEmail,
            garageId
          }
        });
      } else {
        localStorage.setItem(LocalStorageKey.IS_LOGGED, '0');
      }
      this.$http.interceptors.response.use(response => response, async error => {
        if (error.response.status === 401) {
          let domain = window.location.hostname;
          const sDomain = domain.split('.');

          if (sDomain.length === 3) {
            sDomain.shift();
            domain = sDomain.join('.');
          }
          this.$cookies.remove(CookiesKey.ACCESS_TOKEN, undefined, domain);
          localStorage.setItem(LocalStorageKey.IS_LOGGED, '0');
          delete this.$http.defaults.headers.Authorization;
          await this.$router.push({ name: RouteName.LOGIN, query: this.$route.query });
        };
        return Promise.reject(error);
      });
    }
  }
});
