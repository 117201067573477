
import { defineComponent } from 'vue';
import {
  DmModalBody
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppModalBody',
  components: {
    DmModalBody
  }
});
