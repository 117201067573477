import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_AppNotificator = _resolveComponent("AppNotificator")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({})
  }, [
    _createVNode(_component_router_view),
    _createVNode(_component_AppNotificator, {
      class: _ctx.$bem({ e: 'notificator' })
    }, null, 8, ["class"])
  ], 2))
}