
import { defineComponent, PropType, inject } from 'vue';
import {
  DmButton,
  DmAsyncContent,
  DmLoadingBlend,
  DmCheckbox
} from '@dobrymechanik/vue-ui';
import {
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';
import { URI_REPAIR_CANCEL } from '@/api/endpoints';
import { mapState, mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { FETCH_CANCELLATION_REASONS } from '@/store/repair/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { CalendarCancellationReason } from '@/api/models';

export default defineComponent({
  name: 'AppEventModalCancellation',
  components: {
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    DmButton,
    DmAsyncContent,
    DmLoadingBlend,
    DmCheckbox
  },
  props: {
    repairId: {
      type: String as PropType<string>,
      required: true
    },
    isExternal: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    isFutureEvent: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['cancel', 'close'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      loading: false as boolean,
      sendConfirmation: true as boolean,
      reasonId: null as string | null
    };
  },
  computed: {
    ...mapState('repair', {
      reasons: 'cancellationReasons',
      areReasonsFetched: 'areCancellationReasonsFetched',
      reasonsLoading: 'cancellationReasonsLoading',
      reasonsError: 'cancellationReasonsError'
    }),
    filteredReasons (): CalendarCancellationReason[] {
      const reasonArr = this.reasons as CalendarCancellationReason[];
      return reasonArr.filter((reason: CalendarCancellationReason) => {
        return reason.displayAfterRepairPlanedDate !== this.isFutureEvent;
      });
    }
  },
  mounted () {
    this.fetchReasons(this.$http);
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    ...mapActions('repair', {
      fetchReasons: FETCH_CANCELLATION_REASONS
    }),
    setReason (id: string): void {
      this.reasonId = id;
    },
    async cancelEvent (): Promise<void> {
      const gtmAction = 'Cancel visit';
      const gtmLabel = this.reasonId ? this.reasons.find((r: { name: string; id: string }) => r.id === this.reasonId).name : null;
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.VISIT,
        action: gtmAction,
        label: gtmLabel
      });
      if (this.reasonId) {
        const data = jsonToURLSearchParams({
          repairId: this.repairId,
          cancellationReasonId: this.reasonId,
          sendConfirmation: (this.sendConfirmation ? 1 : 0)
        });
        try {
          this.loading = true;
          await this.$http.post(URI_REPAIR_CANCEL, data);
          this.trackEvent({
            event: TrackedEventType.SUCCESS,
            category: TrackedEventCategory.VISIT,
            action: gtmAction,
            label: gtmLabel
          });
          if (this.sendConfirmation) {
            this.trackEvent({
              event: TrackedEventType.SMS,
              category: TrackedEventCategory.VISIT,
              action: gtmAction,
              label: 'Notification sent'
            });
          }
          this.createNotification({
            message: this.$t('repair.cancellation.cancellationSuccess'),
            type: 'success'
          });
          this.$emit('cancel');
          this.close();
        } catch (e) {
          this.createNotification({
            message: this.$t('notifications.somethingWentWrong'),
            type: 'error'
          });
          this.trackEvent({
            event: TrackedEventType.ERROR,
            category: TrackedEventCategory.VISIT,
            action: gtmAction,
            label: gtmLabel
          });
        }
        this.loading = false;
      }
    },
    close (): void {
      this.$emit('close');
    },
    reloadReasons (): void {
      this.fetchReasons(this.$http);
    }
  }
});
