import { createStore } from 'vuex';
import { state } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import notifications from './notifications';
import repair from './repair';
import garage from './garage';

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    notifications,
    repair,
    garage
  }
});
