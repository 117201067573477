import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

export const createSentry = (Vue: any) => {
  const env = process.env.VUE_APP_SENTRY_ENV;
  if (env) {
    Sentry.init({
      dsn: 'https://480c08b283f14f3189876e9aa9730762@o237563.ingest.sentry.io/5579763',
      autoSessionTracking: true,
      integrations: [new VueIntegration({ Vue })],
      tracesSampleRate: 1.0,
      environment: env
    });
  }
};
