
import { defineComponent, PropType, inject } from 'vue';
import {
  DmInput,
  DmAutocomplete
} from '@dobrymechanik/vue-ui';
import {
  Form,
  Field
} from 'vee-validate';
import {
  AppModalBody,
  AppModalFooter,
  AppModal,
  AppModalHeader,
  AppModalButton
} from '@/components';
import { CarFormData } from '@/models/cars';
import {
  URI_CAR_BY_LICENSE_PLATE_NUMBER
} from '@/api/endpoints';
import {
  CarBrowser,
  CarBrand
} from '@/api/models';
import { mapState, mapActions } from 'vuex';
import {
  TrackEvent,
  TrackedEventType,
  TrackedEventCategory
} from '@/models/track-event';
import {
  FETCH_CAR_BROWSER
} from '@/store/repair/actions';
import { getLicensePlateNumber } from '@/helpers/events';

export default defineComponent({
  name: 'AppCarForm',
  components: {
    DmInput,
    DmForm: Form,
    DmField: Field,
    AppModalBody,
    AppModalFooter,
    AppModal,
    AppModalHeader,
    AppModalButton,
    DmAutocomplete
  },
  props: {
    initialValues: {
      type: Object as PropType<CarFormData>,
      required: true
    }
  },
  emits: ['submit', 'value-exists'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      isReadyToSubmit: true as boolean,
      selectedBrand: null as CarBrand | null,
      submitWaitingObject: null as CarFormData | null,
      isAsyncCheckModalShown: false as boolean
    };
  },
  computed: {
    ...mapState('repair', [
      'carBrowser',
      'carBrowserLoading',
      'carBrowserError'
    ]),
    validationSchema () {
      return {
        brand: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.brand'),
        model: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.model'),
        licensePlateNumber: (v: string) => /^\s*([A-z0-9]\s*){4,8}$/.test(v) || this.$t('cars.rules.licensePlateNumber'),
        vin: (v: string) => !v || /^\s*([A-z0-9]\s*){12,17}$/.test(v) || this.$t('cars.rules.vin'),
        year: (v: string) => !v || /^\d{4}$/.test(v) || this.$t('cars.rules.vin')
      };
    },
    brands (): { key?: string; text?: string; divider?: boolean; header?: string; metadata?: CarBrand }[] {
      const carBrowser = this.carBrowser as CarBrowser;
      const popularBrands = carBrowser.popularBrands.map(b => {
        return {
          key: `p:${b.id}`,
          text: b.name,
          metadata: b
        };
      }) || [];
      const brands = carBrowser.brands.map(b => {
        return {
          key: b.id,
          text: b.name,
          metadata: b
        };
      }) || [];
      return [
        {
          header: this.$t('cars.popular')
        },
        ...popularBrands,
        {
          divider: true
        },
        ...brands
      ];
    },
    models (): any[] {
      return this.selectedBrand?.models.map(m => {
        return {
          key: m.id,
          text: m.name,
          metadata: m
        };
      }) || [];
    }
  },
  mounted () {
    this.setInitialBrand();
    this.fetchCarBrowser(this.$http);
  },
  methods: {
    ...mapActions('repair', {
      fetchCarBrowser: FETCH_CAR_BROWSER
    }),
    setInitialBrand (): void {
      const brandId = this.initialValues.brand?.id;
      if (brandId) {
        const brand = (this.carBrowser as CarBrowser).brands.find(b => b.id === brandId);
        if (brand) {
          this.selectedBrand = brand;
        }
      }
    },
    updateSelectedBrand (item: any, setFieldValue: any, validate: any): void {
      this.selectedBrand = item?.metadata || null;
      setFieldValue('brand', {
        id: item?.key || null,
        name: item?.text || ''
      });
      if (!item || !item.text || item.key) {
        setFieldValue('model', null);
      }
      validate();
    },
    updateSelectedModel (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('model', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    call (fn: any): void {
      fn();
    },
    submit (values: CarFormData): void {
      if (this.isReadyToSubmit) {
        this.submitWaitingObject = null;
        const brandId = values.brand.id;
        const sanitizedBrandId = brandId?.split(':')[0] === 'p' ? brandId?.split(':')[1] : brandId;
        const licensePlateNumber = getLicensePlateNumber(values.licensePlateNumber);

        const submitPayload = {
          ...values,
          licensePlateNumber,
          model: values.model.name,
          brand: values.brand.name,
          modelId: values.model.id,
          brandId: sanitizedBrandId
        };
        this.$emit('submit', submitPayload);
      } else {
        this.submitWaitingObject = values;
      }
    },
    async asyncFieldCheck (field: { value: any; name: string }, setFieldValue: any): Promise<void> {
      const checkMap: { [key in string]: any } = {
        licensePlateNumber: {
          initialValue: this.initialValues.licensePlateNumber,
          uri: URI_CAR_BY_LICENSE_PLATE_NUMBER
        }
      };
      const checkDetails = checkMap[field.name];
      if (checkDetails && field.value && field.value !== checkDetails.initialValue) {
        this.isReadyToSubmit = false;
        try {
          const valueExists = (await this.$http.get<{ data: boolean }>(checkDetails.uri(field.value))).data.data;
          if (valueExists) {
            setFieldValue(field.name, checkDetails.initialValue);
            this.isAsyncCheckModalShown = true;
          }
        } catch (e) { }
        this.isReadyToSubmit = true;
        if (this.submitWaitingObject) {
          this.submit(this.submitWaitingObject);
        }
      }
    },
    reloadCarBrowser (): void {
      this.fetchCarBrowser(this.$http);
    },
    openAsyncCheckModal (): void {
      this.isAsyncCheckModalShown = true;
      this.trackEvent({
        event: TrackedEventType.SHOW,
        category: TrackedEventCategory.CARS,
        action: 'Duplicated car detected'
      });
    }
  }
});
