
import { defineComponent, PropType } from 'vue';
import {
  DmInput,
  DmTextarea,
  DmButton,
  DmBadge,
  DmIconButton,
  DmAutocomplete,
  DmPhoneInput,
  DmSwitch
} from '@dobrymechanik/vue-ui';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton,
  AppSpelledWord
} from '@/components';
import {
  Form,
  Field
} from 'vee-validate';
import { EventFormData } from '@/models/events';
import {
  URI_CAR_BY_LICENSE_PLATE_NUMBER
} from '@/api/endpoints';
import {
  Client,
  ClientCar,
  CarBrowser,
  CarBrand,
  RepairReason,
  RepairReasonGroup
} from '@/api/models';
import { mapState, mapActions } from 'vuex';
import {
  FETCH_REPAIR_REASONS,
  FETCH_CAR_BROWSER
} from '@/store/repair/actions';
import { getLicensePlateNumber } from '@/helpers/events';

export default defineComponent({
  name: 'AppNewEventForm',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    DmInput,
    DmTextarea,
    DmSwitch,
    DmForm: Form,
    DmField: Field,
    DmButton,
    AppSpelledWord,
    DmBadge,
    DmIconButton,
    DmAutocomplete,
    DmPhoneInput
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    car: {
      type: Object as PropType<ClientCar | null>,
      default: null
    },
    hasGoBackButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    initialReason: {
      type: String as PropType<string>,
      default: ''
    }
  },
  emits: [
    'submit',
    'value-exists',
    'cancel',
    'click-submit'
  ],
  data () {
    return {
      isAsyncCheckModalShown: false as boolean,
      isReadyToSubmit: true as boolean,
      selectedCarId: null as string | null,
      isCarFormShown: true as boolean,
      selectedBrand: null as CarBrand | null,
      submitWaitingObject: null as EventFormData | null
    };
  },
  computed: {
    ...mapState('repair', {
      repairReasons: 'repairReasons',
      carBrowser: 'carBrowser',
      carBrowserLoading: 'carBrowserLoading',
      carBrowserError: 'carBrowserError'
    }),
    ...mapState('garage', {
      garageName: 'name'
    }),
    validationSchema () {
      return {
        marketingConsent: (v: boolean | null) => (v !== null && v !== undefined) || this.$t('clients.rules.marketingConsent'),
        reason: (v: any) => (v && v.length) || this.$t('repair.rules.reason'),
        brand: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.brand'),
        model: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.model'),
        licensePlateNumber: (v: string) => /^\s*([A-z0-9]\s*){4,8}$/.test(v) || this.$t('cars.rules.licensePlateNumber'),
        vin: (v: string) => !v || /^\s*([A-z0-9]\s*){12,17}$/.test(v) || this.$t('cars.rules.vin'),
        price: (v: number) => !v || (v >= 0 && v <= 100000) || this.$t('repair.rules.price')
      };
    },
    reasons (): any {
      const flattenedRepairReasons = (this.repairReasons as RepairReasonGroup[]).map(g => {
        return [
          {
            header: g.text
          },
          ...g.items.map(r => {
            return {
              key: r.id,
              text: r.text
            };
          })
        ];
      });

      const reasons = ([] as any[]).concat(...flattenedRepairReasons);
      return reasons;
    },
    brands (): { key?: string; text?: string; divider?: boolean; header?: string; metadata?: CarBrand }[] {
      const carBrowser = this.carBrowser as CarBrowser;
      const popularBrands = carBrowser.popularBrands.map(b => {
        return {
          key: `p:${b.id}`,
          text: b.name,
          metadata: b
        };
      }) || [];
      const brands = carBrowser.brands.map(b => {
        return {
          key: b.id,
          text: b.name,
          metadata: b
        };
      }) || [];
      return [
        {
          header: this.$t('cars.popular')
        },
        ...popularBrands,
        {
          divider: true
        },
        ...brands
      ];
    },
    models (): any[] {
      return this.selectedBrand?.models.map(m => {
        return {
          key: m.id,
          text: m.name,
          metadata: m
        };
      }) || [];
    },
    initialCar (): any {
      if (this.car) {
        const { brandId, brand, modelId, model, licensePlateNumber, vin } = this.car;
        return {
          brand: {
            id: brandId || null,
            name: brand
          },
          model: {
            id: modelId || null,
            name: model
          },
          licensePlateNumber: licensePlateNumber || 'xxxxx',
          vin
        };
      } else {
        return {
          brand: { id: null, name: null },
          model: { id: null, name: null },
          licensePlateNumber: null,
          vin: null
        };
      }
    },
    initialValues (): any {
      return {
        marketingConsent: this.client.marketingConsent || false,
        reason: this.initialReason,
        ...this.initialCar
      };
    }
  },
  created (): void {
    if (this.car) {
      this.selectedCarId = this.car.id;
      this.isCarFormShown = false;
    }
  },
  mounted (): void {
    this.fetchCarBrowser(this.$http);
    this.fetchRepairReasons(this.$http);
  },
  methods: {
    ...mapActions('repair', {
      fetchRepairReasons: FETCH_REPAIR_REASONS,
      fetchCarBrowser: FETCH_CAR_BROWSER
    }),
    isCarSelected (id: string): boolean {
      return id === this.selectedCarId;
    },
    updateSelectedBrand (item: any, setFieldValue: any, validate: any): void {
      this.selectedBrand = item?.metadata || null;
      setFieldValue('brand', {
        id: item?.key || null,
        name: item?.text || ''
      });
      if (!item || !item.text || item.key) {
        setFieldValue('model', null);
      }
      validate();
    },
    updateSelectedModel (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('model', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    updateSelectedReason (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('reason', {
        id: item?.key || null,
        text: item?.text || ''
      });
      validate();
    },
    call (fn: any): void {
      fn();
    },
    selectCar ({ id, brand, brandId, model, modelId, licensePlateNumber, vin }: ClientCar, setValues: any): void {
      setValues({
        brand: {
          id: brandId,
          name: brand
        },
        model: {
          id: modelId,
          name: model
        },
        licensePlateNumber,
        vin
      });
      this.isCarFormShown = false;
      this.selectedCarId = id;
    },
    addCar (setValues: any, setErrors: any): void {
      setValues({
        brand: null,
        model: null,
        licensePlateNumber: null,
        vin: null
      });
      setErrors({
        brand: null,
        model: null,
        licensePlateNumber: null,
        vin: null
      });
      this.isCarFormShown = true;
      this.selectedCarId = null;
    },
    cancelForm (): void {
      this.$emit('cancel');
    },
    onSubmitClick (): void {
      this.$emit('click-submit');
    },
    submit (values: EventFormData): void {
      if (this.isReadyToSubmit) {
        this.submitWaitingObject = null;
        const brandId = values.brand.id;
        const sanitizedBrandId = brandId?.split(':')[0] === 'p' ? brandId?.split(':')[1] : brandId;
        const licensePlateNumber = getLicensePlateNumber(values.licensePlateNumber);
        const carDetails = this.selectedCarId ? {
          carId: this.selectedCarId
        } : {
          brand: values.brand.name,
          brandId: sanitizedBrandId,
          model: values.model.name,
          modelId: values.model.id,
          vin: values.vin
        };
        const data = {
          clientId: this.client.id,
          marketingConsent: values.marketingConsent,
          reason: values.reason,
          licensePlateNumber,
          notes: values.notes,
          price: values.price,
          ...carDetails
        };
        this.$emit('submit', data);
      } else {
        this.submitWaitingObject = values;
      }
    },
    async asyncFieldCheck (field: { value: any; name: string }, setFieldValue: any): Promise<void> {
      const checkMap: { [key in string]: any } = {
        licensePlateNumber: {
          uri: URI_CAR_BY_LICENSE_PLATE_NUMBER
        }
      };
      const checkDetails = checkMap[field.name];
      if (checkDetails && field.value) {
        this.isReadyToSubmit = false;
        try {
          const valueExists = (await this.$http.get<{ data: boolean }>(checkDetails.uri(field.value))).data.data;
          if (valueExists) {
            setFieldValue(field.name, '');
            this.isAsyncCheckModalShown = true;
          }
        } catch (e) { }
        this.isReadyToSubmit = true;
        if (this.submitWaitingObject) {
          this.submit(this.submitWaitingObject);
        }
      }
    },
    reloadCarBrowser (): void {
      this.fetchCarBrowser(this.$http);
    }
  }
});
