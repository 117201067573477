import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmIcon = _resolveComponent("DmIcon")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_DmToolbarMenuDropdown = _resolveComponent("DmToolbarMenuDropdown")
  const _component_DmToolbarMenu = _resolveComponent("DmToolbarMenu")

  return (_openBlock(), _createBlock(_component_DmToolbarMenu, {
    class: _ctx.$bem({}),
    "data-test": "toolbar-controls",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmToolbarMenuDropdown, {
        modelValue: _ctx.isUserDropdownShown,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isUserDropdownShown = $event)),
        persistent: "",
        position: "right",
        class: _ctx.$bem({ e: 'user' }),
        onOutside: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isUserDropdownShown = false))
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_DmTile, {
            color: "white",
            elevated: "",
            class: _ctx.$bem({ e: 'user-dropdown' }),
            width: "200px"
          }, {
            default: _withCtx(() => [
              _createVNode("div", {
                class: _ctx.$bem({ e: 'languages' })
              }, [
                _createVNode(_component_DmIcon, {
                  name: "globe",
                  class: _ctx.$bem({ e: 'languages-icon' })
                }, null, 8, ["class"]),
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                  return (_openBlock(), _createBlock(_component_DmButton, {
                    key: language.key,
                    hoverable: "",
                    dark: _ctx.$i18n.locale === language.key,
                    color: _ctx.$i18n.locale === language.key ? 'primary' : 'default',
                    class: _ctx.$bem({ e: 'language' }),
                    onClick: ($event: any) => (_ctx.setLocale(language.key))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(language.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["dark", "color", "class", "onClick"]))
                }), 128))
              ], 2),
              _createVNode(_component_DmButton, {
                hoverable: "",
                "left-icon": "stats",
                "left-icon-color": "primary",
                "data-test": "menu-item-stats",
                onClick: _ctx.goToStatsPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.header.statistics')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_DmButton, {
                hoverable: "",
                "left-icon": "wrench",
                "data-test": "menu-item-settings",
                onClick: _ctx.goToSettingsPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.header.settings')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_DmButton, {
                hoverable: "",
                "left-icon": "wifi",
                "data-test": "menu-item-workspaces",
                onClick: _ctx.goToWorkspacesPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.header.workspaces')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_DmButton, {
                hoverable: "",
                "left-icon": "phone",
                "data-test": "menu-item-bok",
                onClick: _ctx.goToBokPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.header.bok')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_DmButton, {
                hoverable: "",
                "left-icon": "logout",
                "data-test": "menu-item-logout",
                onClick: _ctx.logout
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('app.header.logout')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'version' })
              }, " v. " + _toDisplayString(_ctx.appVersion), 3)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DmIconButton, {
            icon: "user-circle",
            rounded: "",
            color: "primary-light",
            class: _ctx.$bem({ e: 'user-button' }),
            "data-test": "user-dropdown"
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["modelValue", "class"])
    ]),
    _: 1
  }, 8, ["class"]))
}