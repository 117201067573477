import { RepairState } from './types';

export const state = (): RepairState => ({
  repairReasons: [],
  areRepairReasonsFetched: false,
  repairReasonsLoading: false,
  repairReasonsError: false,
  carBrowser: {
    popularBrands: [],
    brands: []
  },
  isCarBrowserFetched: false,
  carBrowserLoading: false,
  carBrowserError: false,
  cancellationReasons: [],
  areCancellationReasonsFetched: false,
  cancellationReasonsLoading: false,
  cancellationReasonsError: false
});
