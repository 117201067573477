
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';

export default defineComponent({
  name: 'DateChangeConfirmationModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['confirm', 'close'],
  methods: {
    confirm (): void {
      this.$emit('confirm', true);
    },
    close (): void {
      this.$emit('close');
    }
  }
});
