
import { defineComponent, PropType, inject } from 'vue';
import {
  DmButton,
  DmAsyncContent,
  DmIcon,
  DmIconButton,
  DmTable,
  DmSelect
} from '@dobrymechanik/vue-ui';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppSpelledWord
} from '@/components';
import AppZiloPartsModal from '@/components/AppZiloPartsModal/Component.vue';
import AppZiloGetQuoteModal from '@/components/AppZiloGetQuoteModal/Component.vue';
import {
  URI_REPAIR,
  URI_REPAIR_PDF,
  URI_AVAILABLE_SLOTS,
  URI_COST_ESTIMATION_DOCUMENTS,
  URI_COST_ESTIMATION_DOCUMENTS_ID,
  URI_COST_CHANGE_STATUS
} from '@/api/endpoints';
import {
  CalendarEventDetails,
  CalendarEventDetailsStatus
} from '@/api/models';
import {
  borderColorClass,
  bgColorClass,
  CssClass
} from '@/helpers/css-classes';
import AppEventModalStatusChangeConfirmation from './StatusChangeConfirmation';
import AppEventModalEditForm from './EditForm';
import AppEventModalCancellation from './Cancellation';
import { mapState, mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { useStatus } from '@/composition-functions/status';
import { useI18n } from 'vue-i18n';
import { formatPhone } from '@/helpers/phone';
import dayjs from 'dayjs';
import { setBaseDate } from '@/helpers/dayjs.ts';
import ProductInventory from './ProductInventory/ProductInventory.vue';
import { shortcuts } from '@/models/categoryShortcuts';
import ChangeEventDateModal, { ChangeDateAndWorkspacePayload } from '@/components/AppEventModal/ChangeEventDateModal/Component.vue';

enum QuoteStatus {
  InProgress = 'in_progress',
  Sent = 'sent',
  Approved = 'approved',
  Rejected = 'rejected'
}

interface StatusItem {
  key: string;
  text: string;
  disabled: boolean;
  metadata: CalendarEventDetailsStatus;
}

interface QuoteListElementHistory {
  changedAt: string;
  status: QuoteStatus;
  whoChangeStatus: string;
}

interface QuoteListElementService {
  id: string;
  labors: any[];
  parts: any[];
  name: string;
  totalPriceGross: number;
  totalPriceNet: number;
  totalTax: number;
}

export interface QuoteListElement {
  client: {
    clientId: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  correction: string | null;
  garageId: string;
  historyReference: {
    createdAt: string;
    updatedAt: string | null;
    statusHistory: QuoteListElementHistory[];
  };
  id: string;
  notes: {
    clientNotes: string | null;
    internalNotes: string | null;
  };
  repairId: string;
  services: QuoteListElementService[];
  signature: string;
  status: QuoteStatus;
  totalPriceGross: number;
  totalPriceNet: number;
  totalTax: number;
  vehicle: {
    brand: string | null;
    carId: string | null;
    licensePlate: string | null;
    model: string | null;
    vin: string | null;
  };
}

export default defineComponent({
  name: 'AppEventModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppSpelledWord,
    DmButton,
    DmSelect,
    DmAsyncContent,
    DmIcon,
    DmIconButton,
    AppEventModalStatusChangeConfirmation,
    AppEventModalEditForm,
    AppEventModalCancellation,
    AppZiloPartsModal,
    AppZiloGetQuoteModal,
    DmTable,
    ProductInventory,
    ChangeEventDateModal
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    id: {
      type: String as PropType<string>,
      default: ''
    }
  },
  emits: [
    'close',
    'status-change',
    'event-cancel',
    'edit',
    'reload-cancellation-reasons',
    'event-fetched',
    'repair-date-changed'
  ],
  setup () {
    const { t } = useI18n({ useScope: 'global' });
    const { getStatusByOrder } = useStatus(t);
    return {
      getStatusByOrder,
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      details: null as CalendarEventDetails | null,
      loading: false as boolean,
      isLoadingError: false as boolean,
      isStatusChangeModalOpen: false as boolean,
      statusChangeId: null as string | null,
      statusChangeOrder: null as number | null,
      mode: 'details' as string,
      isCopied: false as boolean,
      newRepairDate: '' as string,
      editDateMode: false as boolean,
      repairDates: {},
      ziloPartsIframe: false as boolean,
      getQuoteModal: false as boolean,
      tabs: [{ text: 'Szczegóły wizyty', key: 'details' }, { text: 'Wyceny', key: 'quotes' }],
      quotes: [] as QuoteListElement[],
      expandedQuoteId: '' as string,
      quoteAsDuplcate: false as boolean,
      partsInitialCategory: '' as string,
      shortcuts: shortcuts,
      eventBasket: null as any,
      basket: null as any,
      showZiloPartsBasket: false as boolean,
      basketLoading: false as boolean,
      changeDateEventModal: false as boolean,
      quoteModalViewModel: false as boolean,
      quoteStatuses: [
        { text: 'Zaakceptowana', key: QuoteStatus.Approved },
        { text: 'Odrzucona', key: QuoteStatus.Rejected },
        { text: 'Wysłana', key: QuoteStatus.Sent, disabled: true }
      ]
    };
  },
  computed: {
    computedRepairDates (): any {
      const obj: any = this.repairDates;
      return Object.keys(obj)
        .map(key => {
          return {
            date: key,
            slots: obj[key]
          };
        })
        .map(el => {
          const arr = el.slots.map((slot: any) => {
            return new Date(dayjs(el.date + slot.date).valueOf());
          });
          return [{ header: arr[0] }, ...arr];
        })
        .flat(Infinity)
        .map((e: any) => {
          if (e.header) {
            return {
              header: this.formatDate(e.header)
            };
          } else {
            return {
              key: dayjs(e).format(),
              text: this.getHour(e)
            };
          }
        });
    },
    ...mapState('garage', {
      garageName: 'name',
      garageId: 'id',
      adviser: 'adviser'
    }),
    title (): string {
      return this.loading ? '' : this.details?.workspace || this.$t('repair.new');
    },
    repairDatetime (): string {
      const startStr = this.details?.plannedRepairAt;
      if (startStr) {
        const start = new Date(startStr);
        const startDatetime = start.toLocaleTimeString(this.$i18n.locale, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        return `${startDatetime}`;
      }
      return '';
    },
    statuses (): StatusItem[] {
      return this.details?.availableStatuses.map(s => ({
        key: s.statusId,
        text: this.getStatusByOrder(s.order),
        disabled: s.disabled,
        metadata: s
      })) || [];
    },
    editFormMetadata (): any {
      return (this.details?.plannedRepairAt) ? {
        hour: this.getHour(this.details.plannedRepairAt),
        workspace: this.details.workspace,
        date: this.formatDate(this.details.plannedRepairAt)
      } : null;
    },
    readablePrice (): string {
      return this.details?.price ? ((this.details.price / 100).toFixed(2)).split('.').join(',') : '';
    },
    formattedPhone (): string {
      return formatPhone(this.details?.phone || '');
    },
    headers (): any[] {
      return [
        {
          text: 'Sygnatura',
          name: 'signature'
        },
        {
          text: 'Data utworzenia',
          name: 'createdAt'
        },
        {
          text: 'Status wyceny',
          name: 'status'
        },
        {
          text: 'Kwota (zł)',
          name: 'totalCost'
        },
        {
          text: '',
          name: 'actions'
        }
      ];
    },
    tableItems (): any[] {
      return this.quotes
        .sort((a, b) => {
          const aDate = dayjs(a.historyReference.createdAt);
          const bDate = dayjs(b.historyReference.createdAt);
          return bDate.diff(aDate);
        })
        .map(i => ({
          signature: i.signature,
          createdAt: dayjs(i.historyReference.createdAt).format('DD-MM-YYYY, HH:mm'),
          status: {
            status: i.status,
            id: i.id
          },
          totalCost: i.totalPriceGross.toFixed(2),
          actions: {
            status: i.status, id: i.id
          },
          id: i.id
        }));
    },
    finalQuote (): any {
      const quotesArr = this.quotes
        .filter((quote: any) => {
          return quote.status === 'approved';
        })
        .sort((a, b) => {
          return Date.parse(b.historyReference.createdAt) - Date.parse(a.historyReference.createdAt);
        });

      return quotesArr[0] || null;
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (val) {
        if (val) {
          this.initDetails();
        }
      }
    },
    ziloPartsIframe (value) {
      if (!value) {
        this.fetchEventBasket();
      }
    }
  },
  methods: {
    closeQuoteModal () {
      this.getQuoteModal = false;
      this.quoteAsDuplcate = false;
      this.quoteModalViewModel = false;
      this.expandedQuoteId = '';
    },
    closeAndGetQuotes () {
      this.closeQuoteModal();
      this.fetchQuotes();
    },
    parseQuoteStatus (status: QuoteStatus) {
      switch (status) {
      case QuoteStatus.Approved:
        return 'Zaakceptowana';
      case QuoteStatus.InProgress:
        return 'W trakcie';
      case QuoteStatus.Rejected:
        return 'Odrzucona';
      case QuoteStatus.Sent:
        return 'Wysłana';
      }
    },
    parseQuoteStatusClass (status: QuoteStatus) {
      switch (status) {
      case QuoteStatus.Approved:
        return 'status--approved';
      case QuoteStatus.InProgress:
        return 'status--in-progress';
      case QuoteStatus.Rejected:
        return 'status--rejected';
      default:
        return '';
      }
    },
    openZiloPartsModalWithCategory (categoryId: string): void {
      this.partsInitialCategory = categoryId;
      this.showZiloPartsBasket = false;
      this.ziloPartsIframe = true;
    },
    openZiloPartsModal (): void {
      this.partsInitialCategory = '';
      this.showZiloPartsBasket = false;
      this.ziloPartsIframe = true;
    },
    openZiloPartsModalWithBasket (): void {
      this.partsInitialCategory = '';
      this.showZiloPartsBasket = true;
      this.ziloPartsIframe = true;
    },
    openQuoteModal (): void {
      this.quoteAsDuplcate = false;
      this.getQuoteModal = true;
    },
    openDuplicate (id: string): void {
      this.expandedQuoteId = id;
      this.quoteAsDuplcate = true;
      this.getQuoteModal = true;
    },
    openQuoteAsPreview (id: string): void {
      this.expandedQuoteId = id;
      this.quoteModalViewModel = true;
      this.getQuoteModal = true;
    },
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    async initDetails (): Promise<void> {
      this.details = null;
      this.mode = 'details';
      await this.fetchEventDetails();
      this.setAvailableSlots();
      this.fetchQuotes();
      // await this.fetchEventBasket();
      // await this.fetchBasket();
    },
    isFutureEvent (): boolean {
      const now = dayjs();
      const plannedRepairAt = dayjs(this.details?.plannedRepairAt);
      return plannedRepairAt.diff(now) > 0;
    },
    isLessThanTwoWeeks (): boolean {
      const now = dayjs().startOf('day');
      const plannedRepairAt = dayjs(this.details?.plannedRepairAt);
      return plannedRepairAt.diff(now, 'days') < 12;
    },
    setAvailableSlots (): void {
      this.repairDates = {};
      if (this.isFutureEvent() && this.isLessThanTwoWeeks()) {
        this.fetchAvailableSlots();
      }
    },
    async downloadConfirmation (): Promise<void> {
      try {
        const blob = (await this.$http.get<BlobPart>(URI_REPAIR_PDF(this.id), { responseType: 'blob' })).data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.$t('repair.confirmation')}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.trackEvent({
          event: TrackedEventType.SHOW,
          category: TrackedEventCategory.VISIT,
          action: 'Confirmation seen'
        });
      } catch (e) {
        this.createNotification({
          message: this.$t('notifications.somethingWentWrong'),
          type: 'error'
        });
      }
    },
    getHour (date: string): string {
      return new Date(date).toLocaleTimeString('pl-Pl', {
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    formatDate (date: string): string {
      return new Date(date).toLocaleString('pl-Pl', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    async fetchQuotes (): Promise<void> {
      this.loading = true;
      this.isLoadingError = false;

      const params = {
        repairId: this.id
      };

      try {
        const response = await this.$http.get<QuoteListElement[]>(URI_COST_ESTIMATION_DOCUMENTS, { params });
        this.quotes = response.data;
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    },
    async changeQuoteStatus (status: any, data: any): Promise<void> {
      this.loading = true;
      this.isLoadingError = false;

      try {
        await this.$http.patch<any[]>(URI_COST_CHANGE_STATUS(data.id), {
          status: status.key,
          changeReason: status.key === QuoteStatus.Rejected ? 'Odrzucono przez warsztat' : null
        });
        this.fetchQuotes();
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    },
    async deleteQuote (id: string): Promise<void> {
      this.loading = true;
      this.isLoadingError = false;

      try {
        await this.$http.delete<any[]>(URI_COST_ESTIMATION_DOCUMENTS_ID(id));
        this.fetchQuotes();
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchEventDetails (): Promise<void> {
      this.isLoadingError = false;
      this.loading = true;
      try {
        this.details = (await this.$http.get<CalendarEventDetails>(URI_REPAIR(this.id))).data;
        this.$emit('event-fetched', this.details);
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchAvailableSlots (): Promise<void> {
      this.isLoadingError = false;
      this.loading = true;

      const daysDiff = setBaseDate(this.details?.plannedRepairAt).diff(setBaseDate(), 'day');
      const params = {
        from: dayjs(this.details?.plannedRepairAt).subtract(daysDiff <= 2 ? daysDiff : 2, 'day').format('YYYY-MM-DD'),
        to: dayjs(this.details?.plannedRepairAt).add(2, 'day').format('YYYY-MM-DD')
      };

      try {
        const workspaceId = this.details?.workspaceId || '';
        this.repairDates = (await this.$http.get<any>(URI_AVAILABLE_SLOTS(this.garageId, workspaceId), { params })).data;
      } catch (e) {
        this.isLoadingError = true;
      }
      this.loading = false;
    },
    statusClasses (order: number, disabled: boolean): CssClass[] {
      return [
        ...this.$bem({
          e: 'status',
          m: {
            active: this.details?.activeStatusOrder === order,
            disabled: disabled,
            [order]: true
          }
        }),
        borderColorClass(`status-${order}`)
      ];
    },
    statusCircleClasses (order: number): CssClass[] {
      return [...this.$bem({ e: 'status-circle' }), bgColorClass(`status-${order}`)];
    },
    showStatusChangeConfirmModal (status: CalendarEventDetailsStatus) {
      this.statusChangeId = status.statusId;
      this.statusChangeOrder = status.order;
      this.mode = 'status-change';
    },
    closeStatusChangeConfirmation (): void {
      this.mode = 'details';
      this.statusChangeId = null;
      this.statusChangeOrder = null;
    },
    onStatusChange (status: StatusItem): void {
      if (status.metadata.confirm && !status.metadata.disabled) {
        this.showStatusChangeConfirmModal(status.metadata);
      }
    },
    async onStatusChangeConfirmation (): Promise<void> {
      await this.fetchEventDetails();
      this.fetchQuotes();
      this.$emit('status-change');
    },
    onEdit (): void {
      this.fetchEventDetails();
      this.$emit('edit');
    },
    onEventCancel (): void {
      this.$emit('event-cancel');
      this.close();
    },
    openEditForm (scroll?: boolean): void {
      this.mode = 'edit';

      if (scroll) {
        setTimeout(() => {
          document.getElementsByClassName('dm-modal-body')[0].scrollTo(0, 300);
          (document.querySelector('#app-edit-event-form__tec-doc-brand input') as HTMLInputElement).focus();
        }, 0);
      }
    },
    openDetails (): void {
      this.mode = 'details';
      document.body.style.overflowY = 'hidden';
    },
    openCancellation (): void {
      this.mode = 'cancel';
    },
    close (): void {
      this.eventBasket = null;
      this.expandedQuoteId = '';

      this.newRepairDate = '';
      if (this.editDateMode) {
        this.editDateMode = false;
      }
      this.$emit('close');
      document.body.style.overflowY = 'visible';
    },
    copyId (): void {
      const clipboard = document.createElement('textarea');
      clipboard.innerHTML = this.details?.id || '';
      const parentElement = this.$refs.clipboard as HTMLElement;
      parentElement.appendChild(clipboard);
      clipboard.select();
      clipboard.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand('copy');
      parentElement.removeChild(clipboard);

      this.createNotification({
        message: this.$t('notifications.copiedToClipboard'),
        type: 'success',
        time: 1000
      });
    },
    changeRepairDate (data: ChangeDateAndWorkspacePayload): void {
      this.$emit('repair-date-changed', data);
      this.changeDateEventModal = false;
      this.close();
    },
    getDay (day: number): any {
      switch (day) {
      case 0: return this.$t('days.sunday');
      case 1: return this.$t('days.monday');
      case 2: return this.$t('days.tuesday');
      case 3: return this.$t('days.wednesday');
      case 4: return this.$t('days.thursday');
      case 5: return this.$t('days.friday');
      case 6: return this.$t('days.saturday');
      }
    },
    async fetchEventBasket () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/shop-baskets?status=open&repair_id=${this.id}`;
      this.basketLoading = true;

      try {
        const { data } = await this.$http.get<any>(endpoint);
        this.eventBasket = data['hydra:member'][0];
      } catch {
        this.isLoadingError = true;
      } finally {
        this.basketLoading = false;
      }
    },
    async fetchBasket () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + 'api/shop-baskets?status=open';
      this.basketLoading = true;

      try {
        const { data } = await this.$http.get<any>(endpoint);
        this.basket = data['hydra:member'][0];
      } catch {
        this.isLoadingError = true;
      } finally {
        this.basketLoading = false;
      }
    },
    async clearEventBasket () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/shop-baskets/${this.eventBasket.id}`;
      const data = {
        products: []
      };
      try {
        await this.$http.put<any>(endpoint, data);
        this.eventBasket = null;
      } catch (e) {
        this.isLoadingError = true;
      }
    },
    async mergeBaskets () {
      if (this.basket.id) {
        await this.PUTaddProductsToBasket();
      } else {
        await this.POSTcreateBasket();
      }
      this.openZiloPartsModalWithBasket();
    },

    async PUTaddProductsToBasket () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/shop-baskets/${this.basket.id}`;
      this.loading = true;

      const data = {
        products: [...this.basket.products, ...this.eventBasket.products].map(el => {
          return {
            productId: el.productId,
            quantity: el.quantity,
            vehicleId: el.vehicleId
          };
        })
      };

      try {
        await this.$http.put<any>(endpoint, data);
        await this.clearEventBasket();
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    },

    async POSTcreateBasket () {
      const endpoint = process.env.VUE_APP_ZILO_PARTS_API + 'api/shop-baskets';
      this.loading = true;

      const data = {
        products: [...this.eventBasket.products].map(el => {
          return {
            productId: el.productId,
            quantity: el.quantity,
            vehicleId: el.vehicleId
          };
        })
      };

      try {
        await this.$http.post<any>(endpoint, data);
        await this.clearEventBasket();
      } catch (e) {
        this.isLoadingError = true;
      } finally {
        this.loading = false;
      }
    }
  }
});
