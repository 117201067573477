
import { defineComponent, PropType } from 'vue';
import {
  DmSelect
} from '@dobrymechanik/vue-ui';

import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';
import { CalendarResource } from '@/api/models';
import { URI_CALENDAR_RESOURCES, URI_CHECK_EVENTS_QUANTITY } from '@/api/endpoints';
import { SelectItem } from '@dobrymechanik/vue-ui/dist/types/components/DmSelect/models';
import dayjs from 'dayjs';
import DateChangeConfirmationModal from './Confirm';
import { mapState, mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';

export interface ChangeDateAndWorkspacePayload {
  workspace: string;
  date: string;
}

interface CheckEventsQuantityResponse {
  availableWorkspaces: any[];
  date: string;
  duration: number;
  status: string;
}

export default defineComponent({
  name: 'ChangeEventDateModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    DmSelect,
    DateChangeConfirmationModal
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    title: {
      type: String as PropType<string>,
      default: 'Zmiana daty wizyty'
    },
    eventId: {
      type: String as PropType<string>,
      required: true
    },
    duration: {
      type: Number as PropType<number>,
      required: true
    }
  },
  emits: [
    'close',
    'update:modelValue',
    'repair-date-changed'
  ],
  data: function () {
    return {
      date: null,
      loading: false,
      hour: '' as string,
      workspaces: [] as CalendarResource[],
      workspace: '' as string,
      isConfirmModal: false as boolean
    };
  },
  computed: {
    ...mapState('garage', {
      garageId: 'id'
    }),
    hours () {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
          arr.push(`${i}:${j === 0 ? '00' : 15 * j}`);
        }
      }
      return arr.map(h => {
        return {
          key: h,
          text: h
        };
      });
    },
    mWorkspaces (): SelectItem[] {
      return this.workspaces.map(h => {
        return {
          key: h.id,
          text: h.title
        };
      });
    },
    isAbleToSubmit (): boolean {
      return !!this.workspace && !!this.date && !!this.hour;
    },
    formatDate (): string {
      const date = dayjs(`${this.date} ${this.hour}`);
      return date.format('YYYY-MM-DD HH:mm');
    }
  },
  watch: {
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.$emit('close');
      this.$emit('update:modelValue', false);
    },
    async fetchResources (): Promise<void> {
      const res = (await this.$http.get<CalendarResource[]>(URI_CALENDAR_RESOURCES)).data;
      this.workspaces = res;
    },
    async onSubmit () {
      await this.checkWorkspaceUsage();
    },
    async checkWorkspaceUsage (): Promise<void> {
      this.loading = true;
      const params = {
        from: this.formatDate,
        duration: this.duration
      };
      try {
        const res = (await this.$http.get<CheckEventsQuantityResponse>(URI_CHECK_EVENTS_QUANTITY(this.garageId, this.workspace), { params })).data;

        if (res.status === 'reserved') {
          this.isConfirmModal = true;
        } else {
          this.tryToChangeEventDateAndWorkspace();
        }
      } catch (e) {
        this.createNotification({
          message: e.response?.data?.error === 'Parameter from contains date out of range' ? 'Nie można przenieść wizyty na przeszły termin' : this.$t('notifications.somethingWentWrong'),
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },
    onConfirm (bool: boolean) {
      if (bool) {
        this.tryToChangeEventDateAndWorkspace();
        this.isConfirmModal = false;
      }
    },
    tryToChangeEventDateAndWorkspace () {
      const payload: ChangeDateAndWorkspacePayload = {
        date: this.formatDate,
        workspace: this.workspace
      };

      this.$emit('repair-date-changed', payload);
    }
  },

  async mounted () {
    await this.fetchResources();
  }
});
