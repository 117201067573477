import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutAppHeader = _resolveComponent("LayoutAppHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_LayoutAppContent = _resolveComponent("LayoutAppContent")
  const _component_LayoutAppRegulations = _resolveComponent("LayoutAppRegulations")
  const _component_AppDebtsModal = _resolveComponent("AppDebtsModal")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")

  return (_openBlock(), _createBlock(_component_DmAsyncContent, {
    class: _ctx.$bem({}),
    loading: _ctx.loading,
    error: _ctx.error,
    "loading-text": _ctx.$t('app.loadingGarageDetails')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutAppHeader, {
        class: _ctx.$bem({ e: 'header' })
      }, null, 8, ["class"]),
      _createVNode(_component_LayoutAppContent, {
        class: _ctx.$bem({ e: 'content' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_LayoutAppRegulations, {
        modelValue: _ctx.isRegulationsShown,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isRegulationsShown = $event)),
        consents: _ctx.consents,
        "consent-set-id": _ctx.consentSetId
      }, null, 8, ["modelValue", "consents", "consent-set-id"]),
      (_ctx.debtsModal && _ctx.debts)
        ? (_openBlock(), _createBlock(_component_AppDebtsModal, {
            key: 0,
            modelValue: _ctx.debtsModal,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.debtsModal = $event)),
            debts: _ctx.debts
          }, null, 8, ["modelValue", "debts"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "loading", "error", "loading-text"]))
}