
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';
import {
  DmInput
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AddServiceModal',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    DmInput
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['confirm', 'close'],
  data () {
    return {
      service: ''
    };
  },
  methods: {
    confirm (): void {
      this.$emit('confirm', this.service);
    },
    close (): void {
      this.$emit('close');
    }
  }
});
