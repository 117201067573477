
export function useStatus (t: any) {
  return {
    getStatusByOrder (order: number): string {
      const statusLabels = [
        {
          order: 1,
          label: t('repair.status.new')
        },
        {
          order: 2,
          label: t('repair.status.admitted')
        },
        {
          order: 3,
          label: t('repair.status.repaired')
        },
        {
          order: 4,
          label: t('repair.status.completed')
        }
      ];
      return statusLabels.find(i => i.order === order)?.label || '';
    }
  };
};
