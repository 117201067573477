import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmPhoneInput = _resolveComponent("DmPhoneInput")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_DmSwitch = _resolveComponent("DmSwitch")
  const _component_DmField = _resolveComponent("DmField")
  const _component_AppSpelledWord = _resolveComponent("AppSpelledWord")
  const _component_DmBadge = _resolveComponent("DmBadge")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmIconButton = _resolveComponent("DmIconButton")
  const _component_DmAutocomplete = _resolveComponent("DmAutocomplete")
  const _component_DmTextarea = _resolveComponent("DmTextarea")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_DmForm = _resolveComponent("DmForm")

  return (_openBlock(), _createBlock(_component_DmForm, {
    "initial-values": _ctx.initialValues,
    "validation-schema": _ctx.validationSchema,
    class: _ctx.$bem({}),
    "data-test": "new-event-returning-client-form",
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(({ errors, setFieldValue, setValues, setErrors, values }) => [
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('clients.sectionTitle')), 3),
            _createVNode(_component_DmPhoneInput, {
              "model-value": _ctx.client.phone,
              valid: "",
              light: "",
              rounded: "",
              disabled: "",
              label: `${_ctx.$t('clients.fields.phone')} *`,
              "data-test": "field-phone"
            }, null, 8, ["model-value", "label"]),
            _createVNode(_component_DmInput, {
              "model-value": _ctx.client.name || `${_ctx.client.firstName || ''} ${_ctx.client.lastName || ''}`,
              light: "",
              rounded: "",
              disabled: "",
              label: `${_ctx.$t('clients.fields.name')} *`,
              "data-test": "field-client-name"
            }, null, 8, ["model-value", "label"]),
            _createVNode(_component_DmInput, {
              "model-value": _ctx.client.email,
              light: "",
              rounded: "",
              disabled: "",
              label: _ctx.$t('clients.fields.email')
            }, null, 8, ["model-value", "label"]),
            _createVNode(_component_DmField, { name: "marketingConsent" }, {
              default: _withCtx(({ validate }) => [
                _createVNode(_component_DmSwitch, {
                  id: "checkboxNewEventMarketingConsent",
                  modelValue: values.marketingConsent,
                  "onUpdate:modelValue": ($event: any) => (values.marketingConsent = $event),
                  light: "",
                  rounded: "",
                  elevated: "",
                  error: errors.marketingConsent,
                  "data-test": "field-marketing-consent",
                  label: _ctx.$t('clients.fields.marketingConsent', { garage: _ctx.garageName }),
                  onChange: validate
                }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "label", "onChange"])
              ]),
              _: 2
            }, 1024)
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('cars.sectionTitle')), 3),
            (!_ctx.selectedCarId && !_ctx.isCarFormShown)
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: _ctx.$bem({ e: 'no-car-selected' })
                }, _toDisplayString(_ctx.$t('repair.selectCar')), 3))
              : _createCommentVNode("", true),
            _createVNode("ul", {
              class: _ctx.$bem({ e: 'cars' })
            }, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.client.cars, (carItem) => {
                return (_openBlock(), _createBlock("li", {
                  key: carItem.id,
                  class: [_ctx.$bem({ e: 'car', m: { selected: _ctx.isCarSelected(carItem.id) } }), "has-padding-sm"],
                  "data-test": "new-event-returning-client-form-car"
                }, [
                  _createVNode("div", {
                    class: _ctx.$bem({ e: 'car-details' })
                  }, [
                    _createVNode("div", {
                      class: _ctx.$bem({ e: 'car-name' })
                    }, _toDisplayString(carItem.brand) + " " + _toDisplayString(carItem.model), 3),
                    _createVNode(_component_AppSpelledWord, {
                      text: carItem.licensePlateNumber,
                      class: _ctx.$bem({ e: 'car-license-plate-number' })
                    }, null, 8, ["text", "class"])
                  ], 2),
                  (_ctx.isCarSelected(carItem.id))
                    ? (_openBlock(), _createBlock(_component_DmBadge, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('repair.carSelected')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_DmButton, {
                        key: 1,
                        color: "primary",
                        plain: "",
                        rounded: "",
                        class: _ctx.$bem({ e: 'car-select' }),
                        onClick: ($event: any) => (_ctx.selectCar(carItem, setValues))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('common.select')), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"]))
                ], 2))
              }), 128))
            ], 2),
            _createVNode("div", {
              class: _ctx.$bem({ e: 'car-form-header' })
            }, [
              _createVNode("div", {
                class: _ctx.$bem({ e: 'car-form-title' })
              }, [
                (_ctx.isCarFormShown)
                  ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('repair.newCar')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2),
              (_ctx.isCarFormShown)
                ? (_openBlock(), _createBlock(_component_DmIconButton, {
                    key: 0,
                    icon: "close",
                    hoverable: "",
                    light: "",
                    rounded: "",
                    flat: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCarFormShown = false))
                  }))
                : (_openBlock(), _createBlock(_component_DmButton, {
                    key: 1,
                    plain: "",
                    color: "primary",
                    block: "",
                    hoverable: "",
                    class: _ctx.$bem({ e: 'new-car' }),
                    onClick: ($event: any) => (_ctx.addCar(setValues, setErrors))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('repair.newCar')), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]))
            ], 2),
            _withDirectives(_createVNode("div", null, [
              _createVNode(_component_DmField, { name: "brand" }, {
                default: _withCtx(({ field, validate }) => [
                  _createVNode(_component_DmAutocomplete, {
                    "model-value": field.value ? {
                key: field.value.id,
                text: field.value.name || ''
              } : null,
                    items: _ctx.brands,
                    "items-loading": _ctx.carBrowserLoading,
                    "items-error": _ctx.carBrowserError,
                    "items-reloadable": "",
                    light: "",
                    rounded: "",
                    required: "",
                    maxlength: 128,
                    error: errors.brand,
                    label: `${_ctx.$t('cars.fields.brand')} *`,
                    onUpdate: ($event: any) => (_ctx.updateSelectedBrand($event, setFieldValue, validate)),
                    onBlur: ($event: any) => (_ctx.call(validate)),
                    onReload: _ctx.reloadCarBrowser
                  }, null, 8, ["model-value", "items", "items-loading", "items-error", "error", "label", "onUpdate", "onBlur", "onReload"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "model" }, {
                default: _withCtx(({ field, validate }) => [
                  _createVNode(_component_DmAutocomplete, {
                    "model-value": field.value ? {
                key: field.value.id,
                text: field.value.name
              } : null,
                    items: _ctx.models,
                    light: "",
                    rounded: "",
                    required: "",
                    maxlength: 128,
                    error: errors.model,
                    label: `${_ctx.$t('cars.fields.model')} *`,
                    onUpdate: ($event: any) => (_ctx.updateSelectedModel($event, setFieldValue, validate)),
                    onBlur: ($event: any) => (_ctx.call(validate))
                  }, null, 8, ["model-value", "items", "error", "label", "onUpdate", "onBlur"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "licensePlateNumber" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    required: "",
                    maxlength: 16,
                    error: errors.licensePlateNumber,
                    label: `${_ctx.$t('cars.fields.licensePlateNumber')} *`
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmField, { name: "vin" }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_component_DmInput, _mergeProps({
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => (field.value = $event)
                  }, field, {
                    light: "",
                    rounded: "",
                    maxlength: 34,
                    error: errors.vin,
                    label: _ctx.$t('cars.fields.vin')
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                ]),
                _: 2
              }, 1024)
            ], 512), [
              [_vShow, _ctx.isCarFormShown]
            ])
          ], 2),
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode("h3", {
              class: _ctx.$bem({ e: 'section-header' })
            }, _toDisplayString(_ctx.$t('repair.sectionTitle')), 3),
            _createVNode(_component_DmField, { name: "reason" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmTextarea, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  rows: 3,
                  error: errors.reason,
                  "data-test": "field-notes",
                  label: `${_ctx.$t('repair.fields.reason')} *`,
                  required: ""
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "price" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmInput, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  type: "number",
                  step: 10,
                  min: 0,
                  max: 100000,
                  error: errors.price,
                  "data-test": "field-price",
                  label: _ctx.$t('repair.fields.price')
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "notes" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmTextarea, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  rows: 3,
                  error: errors.notes,
                  label: _ctx.$t('repair.fields.notes')
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024)
          ], 2)
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          (_ctx.hasGoBackButton)
            ? (_openBlock(), _createBlock(_component_AppModalButton, {
                key: 0,
                hoverable: "",
                rounded: "",
                onClick: _ctx.cancelForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.back')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_AppModalButton, {
            color: "primary",
            dark: "",
            type: "submit",
            rounded: "",
            "data-test": "new-event-tab-returning-submit",
            class: _ctx.$bem({ e: 'submit-button' }),
            onClick: _ctx.onSubmitClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('newRepair.browser.addRepair')), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_AppModal, {
        modelValue: _ctx.isAsyncCheckModalShown,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isAsyncCheckModalShown = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppModalHeader, {
            title: _ctx.$t('common.attention')
          }, null, 8, ["title"]),
          _createVNode(_component_AppModalBody, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('repair.clientExists')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppModalFooter, null, {
            buttons: _withCtx(() => [
              _createVNode(_component_AppModalButton, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAsyncCheckModalShown = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema", "class", "onSubmit"]))
}