
import { defineComponent } from 'vue';
import {
  DmNotificator
} from '@dobrymechanik/vue-ui';
import { mapState, mapMutations } from 'vuex';
import { REMOVE_NOTIFICATION } from '@/store/notifications/mutations';

export default defineComponent({
  name: 'AppNotificator',
  components: {
    DmNotificator
  },
  computed: {
    ...mapState('notifications', ['notifications']),
    items (): any[] {
      return this.notifications;
    }
  },
  methods: {
    ...mapMutations('notifications', {
      removeNotificationById: REMOVE_NOTIFICATION
    }),
    removeNotification (item: any): void {
      this.removeNotificationById(item.id);
    }
  }
});
