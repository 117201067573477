import { createGtm } from 'vue-gtm';
import router from '@/router';

export const gtm = createGtm({
  id: process.env.VUE_APP_GTM_ID,
  debug: process.env.NODE_ENV !== 'production',
  loadScript: true,
  vueRouter: router,
  enabled: process.env.VUE_APP_GTM_MODE === 'enabled'
});
