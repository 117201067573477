
import { defineComponent, PropType } from 'vue';
import {
  DmTile,
  DmInput,
  DmButton,
  DmIconButton,
  DmTextarea,
  DmSelect,
  DmSpinner
} from '@dobrymechanik/vue-ui';
import { v4 as uuidv4 } from 'uuid';
import {
  CalendarEventDetails
} from '@/api/models';
import { URI_COST_ESTIMATION_DOCUMENTS_ID_JSON } from '@/api/endpoints';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import AddServiceModal from '@/views/Quote/_components/AddServiceModal';
import SendQuoteModal from '@/views/Quote/_components/SendQuoteModal';
import dayjs from 'dayjs'; ;

interface Part {
  name?: string;
  index?: string | null;
  unit?: string;
  quantity?: number;
  priceNet?: number;
  priceGross?: number;
  taxRate?: number;
  id?: string;
  totalPriceGross?: number;
  totalPriceNet?: number;
  totalTax?: number;

}

interface Labor {
  name?: string;
  unit?: string;
  quantity?: number;
  priceNet?: number;
  taxRate?: number;
  priceGross?: number;
  id?: string;
  totalPriceGross?: number;
  totalPriceNet?: number;
  totalTax?: number;
}

interface Client {
  clientId: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string;
  email: string | null;
}

interface Service {
  name: string;
  parts: Part[];
  labors: Labor[];
  id?: string;
}

enum QuoteStatus {
  InProgress = 'in_progress',
  Sent = 'sent',
  Approved = 'approved',
  Rejected = 'rejected'
}

interface QuoteSummary {
  totalPriceNet: number;
  totalPriceGross: number;
  partsCount: number;
  totalFlatRate: number;
  totalTime: number;
}

interface QuoteVehicle {
  carId: string | null;
  brand: string;
  model: string;
  licensePlate: string;
  vin: string | null;
}

interface QuoteNotes {
  clientNotes: string | null;
  internalNotes: string | null;
}

interface QuoteCorrection {
  note: string;
  priceGross: number;
  priceNet: number;
  taxRate: number;
}

export interface QuoteForm {
  repairId?: string;
  client: Client;
  vehicle: QuoteVehicle;
  services: Service[];
  notes: QuoteNotes;
  id?: string;
  status: QuoteStatus | null;
  correction?: QuoteCorrection;
  totalPriceGross?: number;
  totalPriceNet?: number;
  totalTax?: number;
}

export default defineComponent({
  name: 'QuoteForm',
  components: {
    DmTile,
    DmInput,
    DmButton,
    DmIconButton,
    AddServiceModal,
    SendQuoteModal,
    DmSelect,
    DmTextarea,
    DmSpinner
  },
  props: {
    details: {
      type: Object as PropType<CalendarEventDetails>,
      default: null
    },
    quoteId: {
      type: String,
      default: ''
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['send-quote', 'save-quote'],
  data () {
    return {
      servicesQuantityArray: [uuidv4()] as string[],
      partsQuantityArray: [uuidv4()] as string[],
      isPhoneValid: true as boolean,
      defaultCountryCode: 'PL' as string,
      initialValues: null as QuoteForm | null,
      quote: null as any | null,
      addServiceModal: false as boolean,
      sendQuoteModal: false as boolean,
      quoteLoading: false as boolean,
      laborUnits: [
        {
          key: 'flat_rate', text: 'Sztuka'
        },
        {
          key: 'time', text: 'Godzina'
        }
      ],
      partUnits: [
        {
          key: 'piece', text: 'Sztuka'
        },
        {
          key: 'gram', text: 'Gramy'
        },
        {
          key: 'liter', text: 'Litry'
        }
      ],
      vatUnits: [
        {
          key: 0, text: '0%'
        },
        {
          key: 5, text: '5%'
        },
        {
          key: 8, text: '8%'
        },
        {
          key: 23, text: '23%'
        }
      ]

    };
  },
  computed: {
    fullNettoPrice (): number {
      if (!this.initialValues) return 0;

      return this.initialValues.services.reduce((acc, el) => {
        return acc +
          el.labors.reduce((sum, item) => {
            return sum + (Number(item.priceNet || 0) * Number(item.quantity || 0));
          }, 0) +
          el.parts.reduce((sum, item) => {
            return sum + (Number(item.priceNet || 0) * Number(item.quantity || 0));
          }, 0);
      }, 0) + (this.initialValues.correction?.priceNet || 0);
    },
    fullVatPrice (): number {
      if (!this.initialValues) return 0;

      return this.initialValues.services.reduce((acc, el) => {
        return acc +
          el.labors.reduce((sum, item) => {
            return sum + (Number(item.priceNet || 0) * Number(item.quantity || 0) * Number(item.taxRate ? `${Number(item.taxRate / 100)}` : 0));
          }, 0) +
          el.parts.reduce((sum, item) => {
            return sum + (Number(item.priceNet || 0) * Number(item.quantity || 0) * Number(item.taxRate ? `${Number(item.taxRate / 100)}` : 0));
          }, 0);
      }, 0) + ((this.initialValues.correction?.priceGross || 0) - (this.initialValues.correction?.priceNet || 0));
    },
    totalFlatRate (): number {
      if (!this.initialValues) return 0;

      return this.initialValues.services.reduce((acc, el) => {
        return acc +
          el.labors.reduce((sum, item) => {
            if (item.unit === 'flat_rate' && item.quantity) {
              return sum + Number(item.quantity || 0);
            }
            return sum;
          }, 0);
      }, 0);
    },
    totalLaborsTime (): number {
      if (!this.initialValues) return 0;

      return this.initialValues.services.reduce((acc, el) => {
        return acc +
          el.labors.reduce((sum, item) => {
            if (item.unit === 'time' && item.quantity) {
              return sum + Number(item.quantity || 0);
            }
            return sum;
          }, 0);
      }, 0);
    },
    totalParts (): number {
      if (!this.initialValues) return 0;

      return this.initialValues.services.reduce((acc, el) => {
        return acc +
          el.parts.reduce((sum, item) => {
            return sum + Number(item.quantity || 0);
          }, 0);
      }, 0);
    },
    payload (): null | QuoteForm {
      if (!this.initialValues) return null;

      let payload = this.initialValues;

      const services = payload.services.map((service: Service) => {
        return {
          name: service.name,
          parts: service.parts.map((part: Part) => {
            return {
              name: part.name,
              unit: part.unit,
              index: part.index || null,
              priceNet: Number(Number(part.priceNet || 0).toFixed(2)),
              priceGross: Number((Number(part.priceNet || 0) * Number(part.taxRate ? `${1 + Number(part.taxRate / 100)}` : 1)).toFixed(2)),
              quantity: Number(Number(part.quantity || 0).toFixed(2)),
              taxRate: Number(Number(part.taxRate || 0).toFixed(2))
            };
          }),
          labors: service.labors.map((labor: Labor) => {
            return {
              name: labor.name,
              unit: labor.unit,
              priceNet: Number(Number(labor.priceNet || 0).toFixed(2)),
              priceGross: Number((Number(labor.priceNet || 0) * Number(labor.taxRate ? `${1 + Number(labor.taxRate / 100)}` : 1)).toFixed(2)),
              quantity: Number(Number(labor.quantity || 0).toFixed(2)),
              taxRate: Number(Number(labor.taxRate || 0).toFixed(2))
            };
          })
        };
      });

      payload = {
        ...payload,
        services,
        status: QuoteStatus.InProgress
      };

      return payload;
    }
  },
  async beforeMount () {
    if (this.quoteId) {
      this.fetchQuote();
    } else if (this.details) {
      this.parseFromDetails();
    }
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),

    convertDate (date: string) {
      return dayjs(date).format('DD-MM-YYYY, HH:mm');
    },

    toggleAddServiceModal () {
      this.addServiceModal = !this.addServiceModal;
    },

    toggleSendQuoteModal () {
      this.sendQuoteModal = !this.sendQuoteModal;
    },

    onAddService (service: string) {
      this.addService(service);
      this.toggleAddServiceModal();
    },

    addService (service: string): void {
      if (!this.initialValues) return;

      this.initialValues.services.push({
        name: service,
        id: uuidv4(),
        labors: [
          {
            id: uuidv4(),
            quantity: 1,
            unit: 'flat_rate',
            taxRate: 23
          }
        ],
        parts: [
          {
            id: uuidv4(),
            quantity: 1,
            unit: 'piece',
            taxRate: 23
          }
        ]
      });
    },

    grossPriceCalculator (value: any) {
      if (isNaN(value.priceNet) || isNaN(value.taxRate)) return '';
      const grossPrice = value.priceNet * (1 + value.taxRate / 100);

      return grossPrice.toFixed(2);
    },

    async fetchQuote (): Promise<void> {
      this.quoteLoading = true;

      try {
        const { data } = await this.$http.get<QuoteForm>(URI_COST_ESTIMATION_DOCUMENTS_ID_JSON(this.quoteId));
        this.initialValues = {
          ...data,
          services: data.services.map(service => {
            return {
              ...service,
              id: uuidv4(),
              parts: service.parts.map(part => {
                return {
                  ...part,
                  id: uuidv4()
                };
              }),
              labors: service.labors.map(labor => {
                return {
                  ...labor,
                  id: uuidv4()
                };
              })
            };
          }),
          status: this.viewMode ? data.status : QuoteStatus.InProgress
        };
      } catch (e) {

      } finally {
        this.quoteLoading = false;
      }
    },

    parseFromDetails (): void {
      const clientNameSplitted = this.details.name?.split(' ') || '';
      this.initialValues = {
        vehicle: {
          carId: this.details.carId,
          brand: this.details.brand,
          model: this.details.model,
          licensePlate: this.details.licensePlateNumber,
          vin: this.details.vin || null
        },
        client: {
          clientId: this.details.clientId,
          firstName: this.details.name ? clientNameSplitted[0] : null,
          lastName: this.details.name ? clientNameSplitted[1] : null,
          phoneNumber: this.details.phone,
          email: this.details.email
        },
        notes: {
          internalNotes: null,
          clientNotes: null
        },
        services: [],
        repairId: this.details.id,
        status: QuoteStatus.InProgress
      };
    },

    addRowToLaborList (serviceId?: string): void {
      const service = this.initialValues?.services.find(service => service.id === serviceId);
      if (!service) return;
      service.labors.push({ id: uuidv4(), taxRate: 23, quantity: 1, unit: 'flat_rate' });
    },

    removeRowFromLaborList (serviceId?: string, laborId?: string): void {
      const service = this.initialValues?.services.find(service => service.id === serviceId);
      if (!service) return;

      service.labors = service.labors.filter(labor => labor.id !== laborId);
    },

    addRowToPartsList (serviceId?: string): void {
      const service = this.initialValues?.services.find(service => service.id === serviceId);
      if (!service) return;
      service.parts.push({ id: uuidv4(), taxRate: 23, quantity: 1, unit: 'piece' });
    },

    removeRowFromPartsList (serviceId?: string, partId?: string): void {
      const service = this.initialValues?.services.find(service => service.id === serviceId);
      if (!service) return;

      service.parts = service.parts.filter(part => part.id !== partId);
    },

    removeService (serviceId?: string) {
      if (!this.initialValues) return;

      this.initialValues.services = this.initialValues?.services.filter(service => service.id !== serviceId);
    },

    isFormValid () {
      const quoteForm = document.getElementById('quote-form-id') as HTMLFormElement;

      if (!quoteForm) return;

      // const list = quoteForm.querySelectorAll(':required');
      // for (let item of list) {

      //   item.parentElement?.classList.remove('input-invalid');
      // }

      // if (!quoteForm.checkValidity()) {
      //   const listInvalid = quoteForm.querySelectorAll(':invalid:required');
      //   for (let item of listInvalid) {
      //     item.parentElement?.classList.add('input-invalid');

      //   }
      // }

      return quoteForm.checkValidity();
    },

    saveQuote (): void {
      if (this.isFormValid()) {
        this.$emit('save-quote', this.payload);
      } else {
        this.createNotification({
          message: 'Formularz zawiera błędy. Popraw formularz i spróbuj ponownie',
          type: 'error'
        });
      }
    },
    sendQuoteToClient (messagePayload: any) {
      if (this.isFormValid()) {
        this.toggleSendQuoteModal();
        const data = {
          quotePayload: this.payload,
          messagePayload: messagePayload,
          id: this.initialValues?.id && !!this.viewMode ? `/quotes/${this.initialValues.id}` : null
        };
        this.$emit('send-quote', data);
      } else {
        this.createNotification({
          message: 'Formularz zawiera błędy. Popraw formularz i spróbuj ponownie',
          type: 'error'
        });
      }
    }
  }
});
