import { MutationTree } from 'vuex';
import { RepairState } from './types';
import {
  RepairReasonGroup,
  CarBrowser,
  CalendarCancellationReason
} from '@/api/models';

export const INIT_REPAIR_REASONS_LOADING = 'INIT_REPAIR_REASONS_LOADING';
export const SET_REPAIR_REASONS = 'SET_REPAIR_REASONS';
export const SET_REPAIR_REASONS_ERROR = 'SET_REPAIR_REASONS_ERROR';
export const INIT_CAR_BROWSER_LOADING = 'INIT_CAR_BROWSER_LOADING';
export const SET_CAR_BROWSER = 'SET_CAR_BROWSER';
export const SET_CAR_BROWSER_ERROR = 'SET_CAR_BROWSER_ERROR';
export const INIT_CANCELLATION_REASONS_LOADING = 'INIT_CANCELLATION_REASONS_LOADING';
export const SET_CANCELLATION_REASONS = 'SET_CANCELLATION_REASONS';
export const SET_CANCELLATION_REASONS_ERROR = 'SET_CANCELLATION_REASONS_ERROR';

export const mutations: MutationTree<RepairState> = {
  [INIT_REPAIR_REASONS_LOADING]: (state) => {
    state.repairReasonsLoading = true;
    state.repairReasonsError = false;
    state.areRepairReasonsFetched = false;
  },
  [SET_REPAIR_REASONS]: (state, repairReasons: RepairReasonGroup[]) => {
    state.repairReasons = repairReasons;
    state.repairReasonsLoading = false;
    state.areRepairReasonsFetched = true;
  },
  [SET_REPAIR_REASONS_ERROR]: (state) => {
    state.repairReasonsLoading = false;
    state.repairReasonsError = true;
  },
  [INIT_CAR_BROWSER_LOADING]: (state) => {
    state.carBrowserLoading = true;
    state.carBrowserError = false;
    state.isCarBrowserFetched = false;
  },
  [SET_CAR_BROWSER]: (state, carBrowser: CarBrowser) => {
    state.carBrowser = carBrowser;
    state.carBrowserLoading = false;
    state.isCarBrowserFetched = true;
  },
  [SET_CAR_BROWSER_ERROR]: (state) => {
    state.carBrowserLoading = false;
    state.carBrowserError = true;
  },
  [INIT_CANCELLATION_REASONS_LOADING]: (state) => {
    state.cancellationReasonsLoading = true;
    state.cancellationReasonsError = false;
    state.areCancellationReasonsFetched = false;
  },
  [SET_CANCELLATION_REASONS]: (state, cancellationReasons: CalendarCancellationReason[]) => {
    state.cancellationReasons = cancellationReasons;
    state.cancellationReasonsLoading = false;
    state.areCancellationReasonsFetched = true;
  },
  [SET_CANCELLATION_REASONS_ERROR]: (state) => {
    state.cancellationReasonsLoading = false;
    state.cancellationReasonsError = true;
  }
};
