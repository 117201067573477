
import { defineComponent, PropType, inject } from 'vue';
import {
  DmInput,
  DmLoadingBlend
} from '@dobrymechanik/vue-ui';
import {
  AppModalBody,
  AppModalFooter,
  AppModalButton,
  AppSpelledWord
} from '@/components';
import {
  Form,
  Field
} from 'vee-validate';
import {
  URI_REPAIR_CHANGE_STATUS,
  URI_REPAIR_CHANGE_STATUS_NEW
} from '@/api/endpoints';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { EventStatus } from '@/models/events';
import { LocalStorageKey } from '@/models/storage';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { FETCH_READY_FOR_PICKUP_AMOUNT } from '@/store/actions';
import { QuoteForm } from '@/views/Quote/_components/QuoteForm/Component.vue';
import { QuoteListElement } from '../Component.vue';

interface FormSchema {
  price: number;
}

export default defineComponent({
  name: 'AppEventModalStatusChangeConfirmation',
  components: {
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    AppSpelledWord,
    DmInput,
    DmForm: Form,
    DmField: Field,
    DmLoadingBlend
  },
  props: {
    repairId: {
      type: String as PropType<string>,
      required: true
    },
    statusId: {
      type: String as PropType<string>,
      required: true
    },
    statusOrder: {
      type: Number as PropType<number>,
      required: true
    },
    oldStatusOrder: {
      type: Number as PropType<number | null>,
      required: true
    },
    car: {
      type: String as PropType<string>,
      required: true
    },
    licensePlateNumber: {
      type: String as PropType<string>,
      required: true
    },
    price: {
      type: Number as PropType<number | null>,
      default: null
    },
    quote: {
      type: Object as PropType<QuoteListElement | null>,
      default: null
    }
  },
  emits: ['confirm', 'close'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      loading: false as boolean,
      sendConfirmation: true as boolean,
      sendPrice: true as boolean,
      finalPrice: 0 as number
    };
  },
  computed: {
    info (): string {
      const getText = (order: EventStatus): string => {
        switch (order) {
        case EventStatus.RECEIVED:
          return this.$t('repair.statusChange.confirmAdmission');
        case EventStatus.REPAIRED:
          return this.$t('repair.statusChange.confirmRepair');
        default:
          return '';
        }
      };
      return getText(this.statusOrder);
    },
    sendText (): string {
      const getText = (order: EventStatus): string => {
        switch (order) {
        case EventStatus.RECEIVED:
          return this.$t('repair.statusChange.sendAdmissionSms');
        case EventStatus.REPAIRED:
          return this.$t('repair.statusChange.sendRepairSms');
        default:
          return '';
        }
      };
      return getText(this.statusOrder);
    },
    isRepaired (): boolean {
      return this.statusOrder === EventStatus.REPAIRED;
    },
    initialValues (): any {
      return {
        price: Number(Number(this.finalPrice.toFixed(2) || 0))
      };
    },
    validationSchema (): any {
      return {
        price: (v: number) =>
          !this.isRepaired ||
          !this.sendConfirmation ||
          !this.sendPrice ||
          (v >= 0 && v <= 100000) ||
          this.$t('repair.statusChange.priceRule')
      };
    }
  },
  mounted () {
    this.getInitialSendPriceStatus();
    this.setInitialPrice();
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    ...mapActions({
      fetchReadyForPickupAmount: FETCH_READY_FOR_PICKUP_AMOUNT
    }),
    async submit ({ price }: FormSchema): Promise<void> {
      this.loading = true;

      const params: any = {
        statusId: this.statusId,
        sendConfirmation: this.sendConfirmation ? 1 : 0
      };

      if (this.statusId === '7e59ae15-e20b-454b-a428-b6599a4d1a92') {
        params.sendConfirmation = !!this.sendConfirmation;
      } else {
        params.sendConfirmation = this.sendConfirmation ? 1 : 0;
      }

      if (this.statusId === '7e59ae15-e20b-454b-a428-b6599a4d1a92') {
        params.quoteId = this.quote?.id || null;
        params.price = Number((price * 100).toFixed(0));
      }
      if (this.statusId !== '7e59ae15-e20b-454b-a428-b6599a4d1a92') {
        params.repairId = this.repairId;
      }

      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.VISIT,
        action: `Click - Status changed from „${EventStatus[this.oldStatusOrder || 1]}” to „${EventStatus[this.statusOrder]}”`,
        label: `Notifcation sent: ${this.sendConfirmation ? `yes${this.isRepaired ? `; Sent price: ${this.sendPrice ? 'yes' : 'no'}` : ''}` : 'no'}`,
        value: this.isRepaired ? this.price : null
      });

      try {
        if (this.statusId === '7e59ae15-e20b-454b-a428-b6599a4d1a92') {
          await this.$http.patch(URI_REPAIR_CHANGE_STATUS_NEW(this.repairId), params);
        } else {
          await this.$http.post(URI_REPAIR_CHANGE_STATUS, jsonToURLSearchParams(params));
        }

        await this.fetchReadyForPickupAmount(this.$http);
        this.$emit('confirm');
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('repair.statusChange.error'),
          type: 'error'
        });
      }
      this.loading = false;
    },
    close (): void {
      this.$emit('close');
    },
    saveSendPriceStatus (value: boolean): void {
      localStorage.setItem(LocalStorageKey.SEND_PRICE_STATUS, `${value}`);
    },
    getInitialSendPriceStatus (): void {
      this.sendPrice = localStorage.getItem(LocalStorageKey.SEND_PRICE_STATUS) === 'true';
    },
    setInitialPrice (): void {
      this.finalPrice = this.quote?.totalPriceGross || (this.price ? this.price / 100 : 0) || 0;
    }
  }
});
