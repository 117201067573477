
import { defineComponent, PropType, inject } from 'vue';
import {
  DmInput,
  DmPhoneInput,
  DmSwitch
} from '@dobrymechanik/vue-ui';
import {
  Form,
  Field
} from 'vee-validate';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton
} from '@/components';
import { ClientFormData } from '@/models/clients';
import {
  URI_CLIENT_BY_PHONE,
  URI_CLIENT_BY_EMAIL
} from '@/api/endpoints';
import {
  TrackEvent,
  TrackedEventType,
  TrackedEventCategory
} from '@/models/track-event';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'AppEventForm',
  components: {
    DmInput,
    DmPhoneInput,
    DmForm: Form,
    DmField: Field,
    DmSwitch,
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton
  },
  props: {
    initialValues: {
      type: Object as PropType<ClientFormData>,
      required: true
    }
  },
  emits: ['submit', 'value-exists'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      isReadyToSubmit: true as boolean,
      submitWaitingObject: null as ClientFormData | null,
      isPhoneValid: true as boolean,
      isAsyncCheckModalShown: false as boolean
    };
  },
  computed: {
    ...mapState('garage', {
      garageName: 'name'
    }),
    validationSchema () {
      return {
        name: (v: string) => /\s*([A-zÀ-ž]{2,}\s){1,}([A-zÀ-ž]{2,})\s*/.test(v) || this.$t('clients.rules.name'),
        phone: () => this.isPhoneValid || this.$t('clients.rules.phone'),
        email: (v: string) => !v || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || this.$t('clients.rules.email'),
        marketingConsent: (v: boolean | null) => (v !== null && v !== undefined) || this.$t('clients.rules.marketingConsent')
      };
    }
  },
  methods: {
    call (fn: any): void {
      fn();
    },
    submit (values: ClientFormData): void {
      if (this.isReadyToSubmit) {
        this.submitWaitingObject = null;
        this.$emit('submit', values);
      } else {
        this.submitWaitingObject = values;
      }
    },
    async asyncFieldCheck (field: { value: any; name: string }, setFieldValue: any): Promise<void> {
      const checkMap: { [key in string]: any } = {
        phone: {
          initialValue: this.initialValues.phone,
          uri: URI_CLIENT_BY_PHONE
        },
        email: {
          initialValue: this.initialValues.email,
          uri: URI_CLIENT_BY_EMAIL
        }
      };
      const checkDetails = checkMap[field.name];
      if (checkDetails && field.value && field.value !== checkDetails.initialValue) {
        this.isReadyToSubmit = false;
        try {
          const valueExists = (await this.$http.get<{ data: boolean }>(checkDetails.uri(field.value))).data.data;
          if (valueExists) {
            setFieldValue(field.name, checkDetails.initialValue);
            this.isAsyncCheckModalShown = true;
          }
        } catch (e) { }
        this.isReadyToSubmit = true;
        if (this.submitWaitingObject) {
          this.submit(this.submitWaitingObject);
        }
      }
    },
    openAsyncCheckModal (): void {
      this.isAsyncCheckModalShown = true;
      this.trackEvent({
        event: TrackedEventType.SHOW,
        category: TrackedEventCategory.CLIENTS,
        action: 'Returning client detected'
      });
    }
  }
});
