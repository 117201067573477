import { LocaleDictionary } from '../models';

export const localeEn: LocaleDictionary = {
  common: {
    back: 'Back',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    restore: 'Restore',
    copyToClipboard: 'Copy to clipboard',
    attention: 'Attention!',
    tryAgain: 'Try again',
    search: 'Search',
    select: 'Select',
    checkout: 'Check out',
    edit: 'Edit',
    details: 'Details',
    yes: 'Yes',
    no: 'No',
    notKnown: 'It is not known',
    download: 'Download',
    next: 'Next'
  },
  notifications: {
    somethingWentWrong: 'Something went wrong',
    changeSuccess: 'Data has been changed',
    copiedToClipboard: 'Copied to the clipboard'
  },
  changelog: {
    tip: {
      title: 'Changes in Zilo',
      description: 'Zilo has got new features',
      goToChangelog: 'Go to changelog'
    },
    title: 'Changelog'
  },
  home: {
    goToApp: 'Go to application',
    login: 'Go to login'
  },
  login: {
    goToHomePage: 'Return to home page',
    title: 'Login',
    email: 'Email',
    password: 'Password',
    submit: 'Login',
    noEmail: 'Enter your email',
    wrongEmail: 'Enter correct email address',
    noPassword: 'Enter password',
    loginFailed: 'Login has failed',
    passwordHint: 'Don`t know your password? Contact your account manager on {site} {phone} or {email}'
  },
  resetPassword: {
    title: 'Forgot password?',
    description: 'Type your e-mail and we help you to set new password'
  },
  setPassword: {
    title: 'Set new password',
    error: 'Passwords must be at least 8 characters long'
  },
  bok: {
    contact: 'Contact {phone} or {email}'
  },
  app: {
    loadingGarageDetails: 'Loading garage details',
    pageNotFound: 'The page you are looking for may have been moved or deleted.',
    header: {
      calendar: 'Stations',
      clients: 'Clients',
      forPickup: 'For pickup',
      settings: 'Settings',
      workspaces: 'Workspaces',
      statistics: 'Wokspaces settings',
      logout: 'Logout',
      services: 'Services',
      bok: 'Contact',
      parts: 'Parts catalogue',
      quote: 'Get quote',
      comission: 'Comission'
    },
    regulations: {
      title: 'Regulations',
      logout: 'Logout',
      save: 'Save settings and go to the application',
      consentRequired: 'Consent is required'
    }
  },
  pickup: {
    noItems: 'No cars for pickup',
    pickup: 'Pickup',
    confirmationDescription: 'Confirm that car has been picked up by client',
    failure: 'Pickup has failed'
  },
  settings: {
    title: 'Settings',
    garage: {
      title: 'Garage data',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      zipCode: 'Zip code',
      city: 'City',
      validation: {
        name: 'Enter garage name',
        email: 'Enter valid e-mail address',
        phone: 'Enter valid phone number',
        address: 'Enter valid address',
        zipCode: 'Enter valid zip code',
        city: 'Enter valid city'
      }
    },
    company: {
      title: 'Company data',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      nip: 'NIP',
      address: 'Address',
      zipCode: 'Zip code',
      city: 'City',
      validation: {
        name: 'Enter company name',
        email: 'Enter valid e-mail address',
        phone: 'Enter valid phone number',
        nip: 'Enter valid tax number',
        address: 'Enter valid address',
        zipCode: 'Enter valid zip code',
        city: 'Enter valid city'
      }
    },
    logo: {
      title: 'Logo',
      edit: 'Change',
      uploadTitle: 'Upload new logo',
      preview: 'Preview',
      change: 'Select other file'
    },
    workspaces: {
      title: 'Stations',
      visibility: 'Visible on DM\nas tire swap',
      businessHours: 'Working hours',
      workspace: 'Station name',
      new: 'New station',
      addFailed: 'Station addition failed',
      editFailed: 'Station edition failed',
      statusChangeFailed: 'Status change failed',
      showInactive: 'Show inactive',
      scheduledWorkspace: 'Tenable workspace',
      quantityWorkspace: 'Quantity workspace',
      visibleOutside: 'Visible on DM',
      visibleOutsideTires: 'Visible on DM (tyres)',
      invisibleOutside: 'Invisible on DM',
      add: 'Add workspace',
      edit: 'Workspace edition'
    },
    files: {
      title: 'Files to download',
      gdpr: 'GDPR clause'
    }
  },
  statistics: {
    controls: {
      weeks: 'weeks',
      months: 'months',
      quarters: 'quarters',
      years: 'years',
      weekFrom: 'week from: {week}',
      compareWith: 'compare with'
    },
    boxes: {
      allRepairs: 'All repairs',
      repairsValue: 'Repairs value',
      averageRepairValue: 'Average repair value',
      numberOfCancellations: 'Number of cancellations',
      numberOfClients: 'Number of clients',
      numberOfNewClients: 'Number of new clients',
      numberOfReturningClients: 'Number of returning clients'
    },
    chart: {
      analyzedPeriod: 'analyzed period',
      comparativePeriod: 'comparative period'
    },
    topRepairs: {
      title: 'Top repairs'
    },
    repairsSources: {
      title: 'Repairs source'
    }
  },
  clients: {
    results: '{n} client | {n} clients',
    add: 'Add new client',
    name: 'First name and last name',
    phone: 'Phone number',
    email: 'E-mail address',
    lastRepair: 'Last repair',
    alreadyExists: 'Client already exists',
    sectionTitle: 'Client data',
    client: {
      title: 'Client details'
    },
    cars: {
      title: 'Cars',
      noItems: 'No cars'
    },
    repairs: {
      title: 'Repairs',
      allCars: 'All cars',
      showAll: 'Show all',
      noItems: 'No repairs',
      add: 'Add repair',
      new: 'New repair'
    },
    fields: {
      phone: 'Phone number',
      name: 'First and last name',
      email: 'E-mail address',
      marketingConsent: 'Consent to receive emails / SMS / phone calls with special offers, promotions and other marketing content from the garage {garage}'
    },
    hints: {
      phone: 'Without spaces and dashes, eg. 601345789'
    },
    rules: {
      phone: 'Enter valid phone number',
      name: 'Enter first and lastname',
      email: 'Enter valid e-mail address',
      marketingConsent: 'Specify if the client has consented'
    },
    new: {
      title: 'New client',
      submit: 'Add client',
      addSuccess: 'Client has been added',
      addError: 'Adding client has failed'
    },
    edit: {
      title: 'Client edition',
      submit: 'Save new data',
      success: 'Client`s data has been edited',
      error: 'Client`s data edition has failed'
    }
  },
  cars: {
    add: 'Add new car',
    alreadyExists: 'Car already exists',
    popular: 'Popular',
    sectionTitle: 'Car details',
    new: {
      title: 'New car',
      submit: 'Add car',
      addSuccess: 'Car has been added',
      addError: 'Adding car has failed'
    },
    edit: {
      title: 'Car edition',
      submit: 'Save new data',
      success: 'Car`s data has been edited',
      error: 'Car`s data edition has failed'
    },
    rules: {
      brand: 'Enter brand',
      model: 'Enter model',
      licensePlateNumber: 'Enter valid number',
      vin: 'Enter valid VIN'
    },
    fields: {
      car: 'Car',
      brand: 'Brand',
      model: 'Model',
      type: 'Type',
      licensePlateNumber: 'License plate number',
      vin: 'VIN',
      year: 'Year of production',
      engine: 'Engine'
    }
  },
  repair: {
    new: 'New',
    loading: 'Loading',
    status: {
      title: 'Repair status',
      fillData: 'Fill the data to change repair status',
      new: 'New',
      admitted: 'Dropped off',
      repaired: 'Repaired',
      completed: 'Completed'
    },
    cancel: 'Cancel',
    edit: 'Edit',
    confirmation: 'Confirmation',
    sectionTitle: 'Repair details',
    fields: {
      reason: 'Reason',
      price: 'Price',
      notes: 'Notes'
    },
    rules: {
      reason: 'Enter visit reason',
      price: 'Enter a price between 0 - 100,000',
      year: 'Enter the correct year'
    },
    statusChange: {
      confirmAdmission: 'Confirm car admission',
      confirmRepair: 'Confirm car repair (order confirmation via SMS and e-mail will be send to the driver)',
      sendAdmissionSms: 'Also send the order confirmation via SMS and e-mail to the driver',
      sendRepairSms: 'Also send a repair confirmation by SMS and e-mail to the driver',
      sendPrice: 'Add the repair price information',
      priceRule: 'Enter a price between 1 - 100,000',
      error: 'Status change failed'
    },
    cancellation: {
      sendSms: 'Also send an SMS and e-mail confirmation of the cancellation to the driver',
      cancel: 'Cancel the visit',
      cancellationSuccess: 'The visit has been successfully canceled'
    },
    selectCar: 'Select car',
    carSelected: 'Selected',
    newCar: 'New car',
    saveNewData: 'Save new data',
    editError: 'Data edition failed.',
    clientExists: 'This client is already in our database.',
    chooseNewRepairDate: 'Choose new repair date'
  },
  calendar: {
    pending: {
      title: 'Pending',
      add: 'Add visit',
      noItems: 'No pending visits',
      showPendingItems: 'Show pending visits',
      hidePendingItems: 'Hide pending visits'
    },
    loading: 'Calendar loading',
    eventsLoading: 'Visits loading',
    event: {
      durationChangeError: 'Event duration change failed.',
      moveError: 'Event station/term change failed.',
      moveToPendingError: 'Event move to pending failed.',
      moveFromPendingError: 'Adding event to calendar failed.',
      sourceDM: 'Visit source: Dobry Mechanik',
      reorderError: 'Events reorder change failed.'
    },
    moveToCalendar: {
      confirmChange: 'Confirm the change of date',
      sendSms: 'Also send a confirmation via SMS and e-mail to the driver'
    },
    buttons: {
      prev: 'Previous',
      next: 'Next',
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List'
    },
    contact: {
      header: 'Problem with Zilo?',
      content: 'Call {phone} or send email to {email}'
    }
  },
  newRepair: {
    new: 'New',
    returning: 'Returning',
    showMore: 'Show more',
    showLess: 'Show less',
    add: 'Add new client and visit',
    addError: 'Visit adding failed',
    browser: {
      hint: 'Enter any client data to find him/her in the database.',
      example: 'For example first name, last name, phone number, e-mail address, license plate number, VIN',
      loading: 'Searching the database',
      noMatchingItems: 'No matching items',
      addClient: 'Add new client',
      newCar: 'New car',
      addRepair: 'Add repair'
    },
    repairHour: 'Arrival time'
  },
  days: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday'
  },
  daysShortcuts: {
    sunday: 'Sun',
    monday: 'Mon.',
    tuesday: 'Tue.',
    wednesday: 'Wed.',
    thursday: 'Thu.',
    friday: 'Fri.',
    saturday: 'Sat.'
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  quote: {
    createPDF: 'Create quote in PDF',
    pdfName: 'Quote',
    clientData: {
      header: 'Client info',
      phone: 'Phone',
      name: 'Name',
      email: 'E-mail'
    },
    carData: {
      header: 'Car info',
      name: 'Name and surname',
      licencePlate: 'License plate'
    },
    parts: {
      header: 'Parts',
      name: 'Name',
      quantity: 'Quantity',
      manufacturer: 'Manufacturer',
      priceNet: 'Unit price net (zł)',
      priceGross: 'nit price gross (zł)'
    },
    services: {
      header: 'Services',
      name: 'Name',
      priceNet: 'Unit price net (zł)',
      priceGross: 'nit price gross (zł)'
    },
    notes: {
      header: 'Client notes',
      description: 'Additional description for client'
    },
    internal_notes: {
      header: 'Internal notes/notes for garage',
      description: 'Additional internal description for garage'
    },
    notifications: {
      somethingWentWrong: 'Something went wrong'
    }
  }
};
