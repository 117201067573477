
import { defineComponent, PropType } from 'vue';
import {
  DmInput,
  DmTextarea,
  DmAutocomplete,
  DmPhoneInput,
  DmSwitch,
  DmSelect,
  DmButton
} from '@dobrymechanik/vue-ui';
import {
  Form,
  Field
} from 'vee-validate';
import {
  AppModalBody,
  AppModalFooter
} from '@/components';
import { EventFormData } from '@/models/events';
import {
  URI_CLIENT_BY_PHONE,
  URI_CLIENT_BY_EMAIL,
  URI_CAR_BY_LICENSE_PLATE_NUMBER
} from '@/api/endpoints';
import {
  CarBrowser,
  CarBrand,
  RepairReasonGroup
} from '@/api/models';
import { mapState, mapActions } from 'vuex';
import {
  FETCH_REPAIR_REASONS,
  FETCH_CAR_BROWSER
} from '@/store/repair/actions';
import { getLicensePlateNumber } from '@/helpers/events';
import { createWorkingHoursSlots, getManuDateRange } from '@/helpers/functions';
import { WorkspaceWorkingHours, WorkspaceSlot } from '@/models/workspaces';
import { key } from 'localforage';
import { init } from '@sentry/browser';

export default defineComponent({
  name: 'AppEventForm',
  components: {
    DmInput,
    DmTextarea,
    DmPhoneInput,
    DmSwitch,
    DmForm: Form,
    DmField: Field,
    AppModalBody,
    AppModalFooter,
    DmAutocomplete,
    DmSelect
  },
  props: {
    initialValues: {
      type: Object as PropType<EventFormData>,
      required: true
    },
    start: {
      type: String,
      default: ''
    },
    hourToSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'submit',
    'value-exists',
    'update:start'
  ],
  data () {
    return {
      isReadyToSubmit: true as boolean,
      selectedBrand: null as CarBrand | null,
      submitWaitingObject: null as EventFormData | null,
      isPhoneValid: true as boolean,
      tecDocSelectedBrand: null as any,
      tecDocBrands: [],
      tecDocSelectedModel: null,
      tecDocModels: [],
      tecDocSelectedType: null as any,
      tecDocTypes: [],
      tecDocBrandLoading: false,
      tecDocModelLoading: false,
      tecDocTypeLoading: false
    };
  },
  computed: {
    ...mapState('repair', [
      'repairReasons',
      'repairReasonsLoading',
      'repairReasonsError',
      'carBrowser',
      'carBrowserLoading',
      'carBrowserError'
    ]),
    ...mapState('garage', {
      garageName: 'name'
    }),
    validationSchema () {
      return {
        name: (v: string) => /\s*([A-zÀ-ž]{2,}\s){1,}([A-zÀ-ž]{2,})\s*/.test(v) || this.$t('clients.rules.name'),
        phone: () => this.isPhoneValid || this.$t('clients.rules.phone'),
        email: (v: string) => !v || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || this.$t('clients.rules.email'),
        marketingConsent: (v: boolean | null) => (v !== null && v !== undefined) || this.$t('clients.rules.marketingConsent'),
        reason: (v: any) => (v && v.length) || this.$t('repair.rules.reason'),
        brand: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.brand'),
        model: (v: any) => (v && v.name && v.name.length) || this.$t('cars.rules.model'),
        licensePlateNumber: (v: string) => /^\s*([A-z0-9]\s*){4,8}$/.test(v) || this.$t('cars.rules.licensePlateNumber'),
        vin: (v: string) => !v || /^\s*([A-z0-9]\s*){12,17}$/.test(v) || this.$t('cars.rules.vin'),
        price: (v: number) => !v || (v >= 0 && v <= 100000) || this.$t('repair.rules.price'),
        carYear: (v: string) => !v || /^[1-9][0-9]{3}$/.test(v) || this.$t('repair.rules.year')
      };
    },
    reasons (): any {
      const flattenedRepairReasons = (this.repairReasons as RepairReasonGroup[]).map(g => {
        return [
          {
            header: g.text
          },
          ...g.items.map(r => {
            return {
              key: r.id,
              text: r.text
            };
          })
        ];
      });

      const reasons = ([] as any[]).concat(...flattenedRepairReasons);
      return reasons;
    },
    brands (): { key?: string; text?: string; divider?: boolean; header?: string; metadata?: CarBrand }[] {
      const carBrowser = this.carBrowser as CarBrowser;
      const popularBrands = carBrowser.popularBrands.map(b => {
        return {
          key: `p:${b.id}`,
          text: b.name,
          metadata: b
        };
      }) || [];
      const brands = carBrowser.brands.map(b => {
        return {
          key: b.id,
          text: b.name,
          metadata: b
        };
      }) || [];
      return [
        {
          header: this.$t('cars.popular')
        },
        ...popularBrands,
        {
          divider: true
        },
        ...brands
      ];
    },
    models (): any[] {
      return this.selectedBrand?.models.map(m => {
        return {
          key: m.id,
          text: m.name,
          metadata: m
        };
      }) || [];
    },
    workingHoursSlots (): WorkspaceWorkingHours[] {
      const startTime = '07:00:00';
      const endTime = '19:00:00';
      const slot = WorkspaceSlot.ThirtyMin;
      return createWorkingHoursSlots(startTime, endTime, slot);
    }
  },
  mounted () {
    this.setInitialBrand();
    this.GETTecDocBrands();
    if (this.initialValues.carTecDocBrand?.id) {
      this.GETTecDocModels();
    }
    if (this.initialValues.carTecDocModel?.id) {
      this.tecDocSelectedType = this.initialValues.carTecDocType?.id;
      this.GETTecDocTypes();
    }
    this.fetchCarBrowser(this.$http);
    this.fetchRepairReasons(this.$http);
  },
  methods: {
    change (value: any) {
      this.$emit('update:start', value.key);
    },
    ...mapActions('repair', {
      fetchRepairReasons: FETCH_REPAIR_REASONS,
      fetchCarBrowser: FETCH_CAR_BROWSER
    }),
    setInitialBrand (): void {
      const brandId = this.initialValues.brand.id;
      if (brandId) {
        const brand = (this.carBrowser as CarBrowser).brands.find(b => b.id === brandId);
        if (brand) {
          this.selectedBrand = brand;
        }
      }
    },
    async GETTecDocBrands () {
      this.tecDocBrandLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + 'api/brands?page=1';
        const { data } = await this.$http.get<any>(endpoint);

        this.tecDocBrands = data['hydra:member'].map((el: any) => {
          return {
            text: el.name,
            key: el.id
          };
        });
      } catch {
        //
      } finally {
        this.tecDocBrandLoading = false;
      }
    },
    async GETTecDocModels () {
      this.tecDocModelLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/brands/${this.tecDocSelectedBrand || this.initialValues.carTecDocBrand?.id}/models`;
        const { data } = await this.$http.get<any>(endpoint);

        this.tecDocModels = data['hydra:member'].map((el: any) => {
          const text = `${el.name} (${getManuDateRange(el.production.from, el.production.to)})`;
          return {
            text: text,
            key: el.id
          };
        });
      } catch {
        //
      } finally {
        this.tecDocModelLoading = false;
      }
    },
    convertTypeName (type: any) {
      const name = type.name;
      const power = `${type.power.kw.from}kW / ${type.power.hp.from}KM`;
      const engines = type.engine.codes.join(', ');
      return `${name} (${power}) (${engines})`;
    },
    async GETTecDocTypes () {
      this.tecDocTypeLoading = true;
      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/vehicles?brand=${this.tecDocSelectedBrand || this.initialValues.carTecDocBrand?.id}&model=${this.tecDocSelectedModel || this.initialValues.carTecDocModel?.id}`;
        const { data } = await this.$http.get<any>(endpoint);

        this.tecDocTypes = data['hydra:member'].map((el: any) => {
          return {
            text: this.convertTypeName(el),
            key: el.id
          };
        });
      } catch {
        //
      } finally {
        this.tecDocTypeLoading = false;
      }
    },

    async GETvin (setFieldValue: any) {
      if (!this.initialValues.vin) return;

      try {
        const endpoint = process.env.VUE_APP_ZILO_PARTS_API + `api/vin/${this.initialValues.vin}`;
        const { data } = await this.$http.get<any>(endpoint);

        this.tecDocSelectedBrand = data.vehicles[0].brand.id;
        setFieldValue('carTecDocBrand', {
          id: data.vehicles[0].brand.id,
          name: data.vehicles[0].brand.name
        });

        this.GETTecDocModels();

        this.tecDocSelectedModel = data.vehicles[0].model.id;
        setFieldValue('carTecDocModel', {
          id: data.vehicles[0].model.id,
          name: data.vehicles[0].model.name
        });

        this.GETTecDocTypes();

        this.tecDocSelectedType = data.vehicles[0].id;
        setFieldValue('carTecDocType', {
          id: data.vehicles[0].id,
          name: this.convertTypeName(data.vehicles[0])
        });
      } catch (e) {
      }
    },

    updateSelectedTecDocBrand (item: any, setFieldValue: any, validate: any): void {
      this.tecDocSelectedBrand = item?.key || null;
      if (item?.key) {
        this.GETTecDocModels();
      } else {
        setFieldValue('carTecDocModel', {
          id: null,
          name: ''
        });
        setFieldValue('carTecDocType', {
          id: null,
          name: ''
        });
        this.tecDocSelectedType = null;
      }
      setFieldValue('carTecDocBrand', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    updateSelectedTecDocModel (item: any, setFieldValue: any, validate: any): void {
      this.tecDocSelectedModel = item?.key || null;
      if (item?.key) {
        this.GETTecDocTypes();
      } else {
        this.tecDocSelectedType = null;
        setFieldValue('carTecDocType', {
          id: null,
          name: ''
        });
      }
      setFieldValue('carTecDocModel', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    updateSelectedTecDocType (item: any, setFieldValue: any, validate: any): void {
      this.tecDocSelectedType = item?.key || null;
      setFieldValue('carTecDocType', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    updateSelectedBrand (item: any, setFieldValue: any, validate: any): void {
      this.selectedBrand = item?.metadata || null;
      setFieldValue('brand', {
        id: item?.key || null,
        name: item?.text || ''
      });
      if (!item || !item.text || item.key) {
        setFieldValue('model', null);
      }
      validate();
    },
    updateSelectedModel (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('model', {
        id: item?.key || null,
        name: item?.text || ''
      });
      validate();
    },
    updateSelectedReason (item: any, setFieldValue: any, validate: any): void {
      setFieldValue('reason', {
        id: item?.key || null,
        text: item?.text || ''
      });
      validate();
    },
    call (fn: any): void {
      fn();
    },
    submit (values: EventFormData): void {
      if (this.isReadyToSubmit) {
        this.submitWaitingObject = null;
        const brandId = values.brand.id;
        const sanitizedBrandId = brandId?.split(':')[0] === 'p' ? brandId?.split(':')[1] : brandId;
        const carLicensePlateNumber = getLicensePlateNumber(values.licensePlateNumber);

        const data = {
          carBrand: values.brand.name,
          carBrandId: sanitizedBrandId,
          carModel: values.model.name,
          carModelId: values.model.id,
          carTecDocBrandId: values.carTecDocBrand?.id,
          carTecDocBrand: values.carTecDocBrand?.name,
          carTecDocModelId: values.carTecDocModel?.id,
          carTecDocModel: values.carTecDocModel?.name,
          carTecDocTypeId: values.carTecDocType?.id,
          carTecDocType: values.carTecDocType?.name,
          carYear: Number(values.carYear),
          carLicensePlateNumber,
          carVin: values.vin,
          clientName: values.name,
          clientPhone: values.phone,
          clientEmail: values.email,
          clientMarketingConsent: values.marketingConsent,
          repairReason: values.reason,
          repairNotes: values.notes,
          repairPrice: Number(values.price) * 100
        };

        const payload = Object.fromEntries(Object.entries(data).filter(([f, v]) => {
          if (f === 'clientMarketingConsent') {
            return true;
          } else {
            return !!v;
          }
        }));

        this.$emit('submit', payload);
      } else {
        this.submitWaitingObject = values;
      }
    },
    async asyncFieldCheck (field: { value: any; name: string }, setFieldValue: any): Promise<void> {
      const checkMap: { [key in string]: any } = {
        phone: {
          initialValue: this.initialValues.phone,
          uri: URI_CLIENT_BY_PHONE
        },
        email: {
          initialValue: this.initialValues.email,
          uri: URI_CLIENT_BY_EMAIL
        },
        licensePlateNumber: {
          initialValue: this.initialValues.licensePlateNumber,
          uri: URI_CAR_BY_LICENSE_PLATE_NUMBER
        }
      };
      const checkDetails = checkMap[field.name];
      if (checkDetails && field.value && field.value !== checkDetails.initialValue) {
        this.isReadyToSubmit = false;
        try {
          const fieldValue = field.value;
          const valueExists = (await this.$http.get<{ data: boolean }>(checkDetails.uri(fieldValue))).data.data;
          if (valueExists) {
            setFieldValue(field.name, checkDetails.initialValue);
            this.$emit('value-exists', {
              name: field.name,
              value: field.value
            });
          }
        } catch (e) {
          this.isPhoneValid = false;
        }
        this.isReadyToSubmit = true;
        if (this.submitWaitingObject) {
          this.submit(this.submitWaitingObject);
        }
      }
    },
    reloadCarBrowser (): void {
      this.fetchCarBrowser(this.$http);
    },
    reloadRepairReasons (): void {
      this.fetchRepairReasons(this.$http);
    }
  }
});
