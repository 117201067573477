import { MutationTree } from 'vuex';
import { GarageState } from './types';

export const SET_LOGO = 'SET_LOGO';
export const SET_NAME = 'SET_NAME';
export const SET_ID = 'SET_ID';
export const SET_ADVISER_MODE = 'SET_ADVISER_MODE';

export const mutations: MutationTree<GarageState> = {
  [SET_LOGO]: (state, logo: string) => {
    state.logo = logo;
  },
  [SET_NAME]: (state, name: string) => {
    state.name = name;
  },
  [SET_ID]: (state, id: string) => {
    state.id = id;
  },
  [SET_ADVISER_MODE]: (state) => {
    state.adviser = true;
  }
};
