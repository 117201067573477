
import { defineComponent, PropType, inject } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppModalBody,
  AppModalFooter,
  AppModalButton,
  AppEventForm
} from '@/components';
import {
  DmLoadingBlend
} from '@dobrymechanik/vue-ui';
import { EditRepairPayload, EventFormData } from '@/models/events';
import {
  CalendarEventDetails
} from '@/api/models';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';

import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import {
  URI_REPAIR_EDIT
} from '@/api/endpoints';

export default defineComponent({
  name: 'AppEventModalEditForm',
  components: {
    AppModal,
    AppModalHeader,
    AppModalBody,
    AppModalFooter,
    AppModalButton,
    AppEventForm,
    DmLoadingBlend
  },
  props: {
    metadata: {
      type: Object as PropType<{ hour: string; workspace: string; date: string } | null>,
      default: null
    },
    details: {
      type: Object as PropType<CalendarEventDetails>,
      required: true
    },
    id: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['close', 'edit'],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      loading: false as boolean,
      isAsyncCheckModalShown: false as boolean
    };
  },
  computed: {
    initialValues (): EventFormData {
      const {
        name,
        phone,
        email,
        reason,
        reasonId,
        price,
        notes,
        brand,
        brandId,
        model,
        modelId,
        licensePlateNumber,
        vin,
        marketingConsent,
        tecDocMeta
      } = this.details;
      const readablePrice = price ? parseFloat((price / 100).toFixed(2)) : null;
      return {
        name,
        phone,
        email,
        reason,
        price: readablePrice,
        notes,
        brand: {
          id: brandId || null,
          name: brand
        },
        model: {
          id: modelId || null,
          name: model
        },
        licensePlateNumber,
        vin,
        marketingConsent,
        carYear: tecDocMeta?.year,
        carTecDocBrand: {
          id: tecDocMeta?.brandId,
          name: tecDocMeta?.brandName
        },
        carTecDocModel: {
          id: tecDocMeta?.modelId,
          name: tecDocMeta?.modelName
        },
        carTecDocType: {
          id: tecDocMeta?.typeId,
          name: tecDocMeta?.typeName
        }
      };
    }
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.$emit('close');
    },
    onSubmitClick (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.VISIT,
        action: 'Visit details edited'
      });
    },
    async submit (values: any): Promise<void> {
      this.loading = true;
      const data: EditRepairPayload = {
        car: {
          specification: {
            internal: {
              brand: {
                id: values.carBrandId || null,
                text: values.carBrand
              },
              model: {
                id: values.carModelId || null,
                text: values.carModel
              }
            },
            tecdoc: {
              brand: values.carTecDocBrandId ? {
                id: values.carTecDocBrandId,
                text: values.carTecDocBrand
              } : null,
              model: values.carTecDocModelId ? {
                id: values.carTecDocModelId,
                text: values.carTecDocModel
              } : null,
              type: values.carTecDocTypeId ? {
                id: values.carTecDocTypeId,
                text: values.carTecDocType
              } : null
            }
          },
          licensePlateNumber: values.carLicensePlateNumber || null,
          vin: values.carVin || null,
          year: values.carYear || null
        },
        client: {
          firstName: values.clientName ? values.clientName.split(' ')[0] : '',
          lastName: values.clientName ? values.clientName.split(' ')[1] : '',
          phoneNumber: values.clientPhone,
          email: values.clientEmail || null,
          marketingConsent: !!values.clientMarketingConsent
        },
        repair: {
          reason: {
            id: values.repairReasonId || null,
            text: values.repairReason
          },
          notes: values.repairNotes || null,
          price: parseInt(values.repairPrice) || null
        }
      };

      try {
        await this.$http.post(URI_REPAIR_EDIT(this.id), data);
        this.$emit('edit');
        this.close();
        this.trackEvent({
          event: TrackedEventType.SUCCESS,
          category: TrackedEventCategory.VISIT,
          action: 'Visit details edit successfull'
        });
        if (values.vin !== this.initialValues.vin) {
          this.trackEvent({
            event: TrackedEventType.CLICK,
            category: TrackedEventCategory.VISIT,
            action: this.initialValues.vin ? 'VIN changed' : 'VIN filled'
          });
        }
      } catch (e) {
        this.createNotification({
          message: this.$t('repair.editError'),
          type: 'error'
        });
        this.trackEvent({
          event: TrackedEventType.ERROR,
          category: TrackedEventCategory.VISIT,
          action: 'Visit details edit failed'
        });
      }
      this.loading = false;
    }
  }
});
