
import { defineComponent, PropType } from 'vue';
import {
  DmModal
} from '@dobrymechanik/vue-ui';
import {
  AppModalHeader
} from '@/components';
import {
  QuoteForm
} from '@/views/Quote/_components';
import {
  CalendarEventDetails
} from '@/api/models';
import { URI_COST_ESTIMATION_DOCUMENTS, URI_COST_ESTIMATION_SEND } from '@/api/endpoints';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'AppZiloGetQuoteModal',
  components: {
    DmModal,
    AppModalHeader,
    QuoteForm
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object as PropType<CalendarEventDetails>,
      default: null
    },
    quoteId: {
      type: String,
      default: ''
    },
    viewMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'close-and-get-quotes'],
  data () {
    return {
      loading: false
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close () {
      this.$emit('close');
    },

    async saveQuote (payload: any) {
      this.loading = true;
      try {
        await this.POSTsaveQuote(payload);
        this.$emit('close-and-get-quotes');
      } catch {
        //
      } finally {
        this.loading = false;
      }
    },

    async POSTsaveQuote (payload: any) {
      try {
        const { data } = await this.$http.post<any>(URI_COST_ESTIMATION_DOCUMENTS, payload);
        return data.id;
      } catch (e) {
        this.createNotification({
          message: this.$t('quote.notifications.somethingWentWrong'),
          type: 'error'
        });
      }
    },
    async sendQuote (data: any) {
      this.loading = true;

      try {
        let id;

        if (data.id) {
          id = data.id;
        } else {
          id = await this.POSTsaveQuote(data.quotePayload);
        }

        await this.$http.post<any>(URI_COST_ESTIMATION_SEND(id), data.messagePayload);
        this.$emit('close-and-get-quotes');
      } catch (e) {
        this.createNotification({
          message: this.$t('quote.notifications.somethingWentWrong'),
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    }
  }
});
