export interface TrackedEvent {
  event: string;
  category: string;
  action: string;
  label?: any;
  value?: any;
  attr?: object;
}

export enum TrackedEventType {
  CLICK = 'zilo.click',
  SUCCESS = 'zilo.success',
  ERROR = 'zilo.error',
  SHOW = 'zilo.show',
  SMS = 'zilo.sms'
}

export enum TrackedEventCategory {
  LOGIN = 'Login',
  VISIT = 'Visit',
  CALENDAR = 'Calendar',
  CLIENTS = 'Clients',
  READY_FOR_PICKUP = 'Ready for pickup',
  STATS = 'Stats',
  LOGOUT = 'Logout',
  SETTINGS = 'Settings',
  CARS = 'Cars'
}

export type TrackEvent = (T: TrackedEvent) => void;
