import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmAutocomplete = _resolveComponent("DmAutocomplete")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_DmForm = _resolveComponent("DmForm")

  return (_openBlock(), _createBlock(_component_DmForm, {
    "initial-values": _ctx.initialValues,
    "validation-schema": _ctx.validationSchema,
    class: _ctx.$bem({}),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(({ errors, setFieldValue }) => [
      _createVNode(_component_AppModalBody, null, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({ e: 'section' })
          }, [
            _createVNode(_component_DmField, { name: "brand" }, {
              default: _withCtx(({ field, validate }) => [
                _createVNode(_component_DmAutocomplete, {
                  "model-value": field.value ? {
              key: field.value.id,
              text: field.value.name
            } : null,
                  items: _ctx.brands,
                  "items-loading": _ctx.carBrowserLoading,
                  "items-error": _ctx.carBrowserError,
                  "items-reloadable": "",
                  light: "",
                  rounded: "",
                  required: "",
                  maxlength: 128,
                  error: errors.brand,
                  "data-test": "field-brand",
                  label: `${_ctx.$t('cars.fields.brand')} *`,
                  onUpdate: ($event: any) => (_ctx.updateSelectedBrand($event, setFieldValue, validate)),
                  onBlur: ($event: any) => (_ctx.call(validate)),
                  onReload: _ctx.reloadCarBrowser
                }, null, 8, ["model-value", "items", "items-loading", "items-error", "error", "label", "onUpdate", "onBlur", "onReload"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "model" }, {
              default: _withCtx(({ field, validate }) => [
                _createVNode(_component_DmAutocomplete, {
                  "model-value": field.value ? {
              key: field.value.id,
              text: field.value.name
            } : null,
                  items: _ctx.models,
                  light: "",
                  rounded: "",
                  required: "",
                  maxlength: 128,
                  error: errors.model,
                  "data-test": "field-model",
                  label: `${_ctx.$t('cars.fields.model')} *`,
                  onUpdate: ($event: any) => (_ctx.updateSelectedModel($event, setFieldValue, validate)),
                  onBlur: ($event: any) => (_ctx.call(validate))
                }, null, 8, ["model-value", "items", "error", "label", "onUpdate", "onBlur"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "licensePlateNumber" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmInput, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  required: "",
                  maxlength: 16,
                  error: errors.licensePlateNumber,
                  "data-test": "field-license-plate-number",
                  label: `${_ctx.$t('cars.fields.licensePlateNumber')} *`
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "vin" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmInput, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  maxlength: 34,
                  error: errors.vin,
                  "data-test": "field-vin",
                  label: _ctx.$t('cars.fields.vin')
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_DmField, { name: "year" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_DmInput, _mergeProps({
                  modelValue: field.value,
                  "onUpdate:modelValue": ($event: any) => (field.value = $event)
                }, field, {
                  light: "",
                  rounded: "",
                  minlength: 4,
                  maxlength: 4,
                  error: errors.year,
                  "data-test": "field-year",
                  label: _ctx.$t('cars.fields.year')
                }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
              ]),
              _: 2
            }, 1024)
          ], 2)
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_AppModalFooter, null, {
        buttons: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer")
        ]),
        _: 1
      }),
      _createVNode(_component_AppModal, {
        modelValue: _ctx.isAsyncCheckModalShown,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAsyncCheckModalShown = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppModalHeader, {
            title: _ctx.$t('common.attention')
          }, null, 8, ["title"]),
          _createVNode(_component_AppModalBody, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cars.alreadyExists')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppModalFooter, null, {
            buttons: _withCtx(() => [
              _createVNode(_component_AppModalButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAsyncCheckModalShown = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["initial-values", "validation-schema", "class", "onSubmit"]))
}