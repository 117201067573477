
import { defineComponent } from 'vue';
import {
  DmModal,
  DmIcon
} from '@dobrymechanik/vue-ui';
import {
  AppModalHeader
} from '@/components';

export default defineComponent({
  name: 'AppEventForm',
  components: {
    DmModal,
    DmIcon,
    AppModalHeader
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:model-value',
    'create-new-placeholder',
    'create-new-event'
  ],
  data () {
    return {
    };
  }
});
