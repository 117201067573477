
import { defineComponent } from 'vue';
import LayoutAppHeader from './Header';
import LayoutAppContent from './Content';
import LayoutAppChangelogTip from './ChangelogTip';
import LayoutAppRegulations from './Regulations';
import {
  DmAsyncContent
} from '@dobrymechanik/vue-ui';
import { URI_GARAGE_BASIC, URI_COMISSION_OVERDUE_INVOICES } from '@/api/endpoints';
import { GarageBasic, GarageConsent } from '@/api/models';
import { RouteName } from '@/router/models';
import { mapMutations } from 'vuex';
import { SET_LOGO, SET_NAME, SET_ID, SET_ADVISER_MODE } from '@/store/garage/mutations';
import { LocalStorageKey, CookiesKey } from '@/models/storage';
import { TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import jwtDecode from 'jwt-decode';
import { AppDebtsModal } from '@/components/AppDebtsModal';
import { CommisionInvoices } from '@/models';

export default defineComponent({
  name: 'LayoutApp',
  components: {
    LayoutAppHeader,
    LayoutAppContent,
    LayoutAppRegulations,
    DmAsyncContent,
    AppDebtsModal
  },
  beforeRouteEnter (to, _from, next) {
    const isLogged = localStorage.getItem(LocalStorageKey.IS_LOGGED) === '1';
    const accessToken = to.query['access-token'] as string;

    if (accessToken) {
      next(async vm => {
        const decodedToken: any = jwtDecode(accessToken);
        const userEmail = decodedToken.username;
        const garageId = decodedToken.garage;

        const currentToken = vm.$cookies.get(CookiesKey.ACCESS_TOKEN);
        const decodedCurrentToken: any = currentToken ? jwtDecode(currentToken) : undefined;
        const currentGarageId: string | undefined = decodedCurrentToken ? decodedCurrentToken.garage : undefined;

        if (currentGarageId !== garageId) {
          let domain = window.location.hostname;
          const sDomain = domain.split('.');

          if (sDomain.length === 3) {
            sDomain.shift();
            domain = sDomain.join('.');
          }

          vm.$cookies.set(CookiesKey.ACCESS_TOKEN, accessToken, undefined, undefined, domain);
        }

        vm.$gtm.trackEvent({
          event: TrackedEventType.SUCCESS,
          category: TrackedEventCategory.LOGIN,
          action: 'Login automatically',
          attr: {
            email: userEmail,
            garageId
          }
        });
        localStorage.setItem(LocalStorageKey.IS_LOGGED, '1');
        const query = { ...to.query };
        delete query['access-token'];
        await vm.$router.replace({ query });
      });
    } else if (isLogged) {
      next();
    } else {
      localStorage.setItem(LocalStorageKey.RETURN_PATH, to.fullPath);
      next({
        name: RouteName.LOGIN
      });
    }
  },
  data () {
    return {
      loading: true as boolean,
      garage: null as GarageBasic | null,
      error: false as boolean,
      isChangelogTipShown: false as boolean,
      isRegulationsShown: false as boolean,
      consents: [] as GarageConsent[],
      consentSetId: '' as string | null,
      isExternalAdmin: false as boolean,
      debtsModal: false as boolean,
      debts: null as CommisionInvoices | null
    };
  },
  async mounted () {
    this.handleAccessToken();
    this.fetchGarageData();
    this.checkInvoices();

    if (this.$route.query.adviser) {
      this.setAdviserMode();
    }
  },
  methods: {
    ...mapMutations('garage', {
      setLogo: SET_LOGO,
      setGarageName: SET_NAME,
      setGarageId: SET_ID,
      setAdviserMode: SET_ADVISER_MODE
    }),
    handleAccessToken (): void {
      const accessToken = this.$cookies.get(CookiesKey.ACCESS_TOKEN);
      const decodedToken: any = jwtDecode(accessToken);
      this.isExternalAdmin = !!decodedToken.external_admin;
      this.$http.defaults.headers.Authorization = `Bearer ${accessToken}`;
    },
    handleChangelog (): void {
      const lastSeenVersion = localStorage.getItem(LocalStorageKey.LAST_VERSION);
      const currentVersion = require('../../../package.json').version;
      localStorage.setItem(LocalStorageKey.LAST_VERSION, currentVersion);
      if (lastSeenVersion && lastSeenVersion !== currentVersion) {
        this.isChangelogTipShown = true;
      }
    },
    async fetchGarageData (): Promise<void> {
      this.error = false;
      this.loading = true;
      try {
        this.garage = (await this.$http.get<GarageBasic>(URI_GARAGE_BASIC)).data;
        document.title = `Zilo | ${this.garage.name}`;
        this.setLogo(this.garage.logo || '/img/logo-menu.png');
        this.setGarageName(this.garage.name);
        this.setGarageId(this.garage.id);
        this.handleChangelog();
        this.consents = this.garage.consents;
        this.consentSetId = this.garage.consentSetId;
        const isRegulationsAccepted = this.consents.filter(c => !c.accepted && c.required).length === 0;
        if (!isRegulationsAccepted && !this.isExternalAdmin) {
          this.isRegulationsShown = true;
        }
      } catch (e) {
        this.error = true;
      }
      this.loading = false;
    },
    closeChangelogTip (): void {
      this.isChangelogTipShown = false;
    },
    async checkInvoices (): Promise<void> {
      try {
        const data = (await this.$http.get<CommisionInvoices>(URI_COMISSION_OVERDUE_INVOICES)).data;
        if (Number(data.debts_invoices_count) > 0) {
          this.debtsModal = true;
          this.debts = data;
        }
      } catch {
        //
      }
    }
  }
});
