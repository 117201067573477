
import { defineComponent } from 'vue';
import { DmModalFooter } from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'AppModalFooter',
  components: {
    DmModalFooter
  }
});
