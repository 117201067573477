import { createUI } from '@dobrymechanik/vue-ui';
import { LocalStorageKey } from '@/models/storage';
import { Locale } from '@/locales';

const locale = localStorage.getItem(LocalStorageKey.LOCALE) || Locale.PL;

const options = {
  bem: {
    hyphenate: true
  },
  locale,
  icons: {
    type: 'dmi',
    prefix: 'dmi-',
    values: {
      'exclamation-triangle': 'fa fa-exclamation-triangle',
      'file-import': 'fa fa-file-import',
      spinner: 'fa fa-spinner'
    }
  }
};

export const ui = createUI(options);
