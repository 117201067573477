import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppEventForm = _resolveComponent("AppEventForm")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModalFooter = _resolveComponent("AppModalFooter")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_DmLoadingBlend = _resolveComponent("DmLoadingBlend")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({})
  }, [
    _createVNode(_component_AppEventForm, {
      "initial-values": _ctx.initialValues,
      onSubmit: _ctx.submit,
      onValueExists: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAsyncCheckModalShown = true))
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_AppModalButton, { onClick: _ctx.close }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_AppModalButton, {
          color: "primary",
          dark: "",
          type: "submit",
          rounded: "",
          class: _ctx.$bem({ e: 'submit-button' }),
          onClick: _ctx.onSubmitClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('repair.saveNewData')), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ]),
      _: 1
    }, 8, ["initial-values", "onSubmit"]),
    _createVNode(_component_AppModal, {
      modelValue: _ctx.isAsyncCheckModalShown,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isAsyncCheckModalShown = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppModalHeader, {
          title: _ctx.$t('common.attention')
        }, null, 8, ["title"]),
        _createVNode(_component_AppModalBody, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('repair.clientExists')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_AppModalFooter, null, {
          buttons: _withCtx(() => [
            _createVNode(_component_AppModalButton, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAsyncCheckModalShown = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_DmLoadingBlend, { loading: _ctx.loading }, null, 8, ["loading"])
  ], 2))
}